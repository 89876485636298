import React from "react";
import Products from "../components/products";
import InfiniteScroll from "react-infinite-scroll-component";
import ProfileHeader from "../components/profileheader";

export default class User extends React.Component {
  constructor() {
    super();
    this.state = {
      results: [],
      empty: false,
      hasMore: false,
      user: {
        description: "",
        profile: {},
        cover: {},
        rating: 5,
        ratingQty: 1,
      },
    };
    this.loadMore = this.loadMore.bind(this);
    this.getResults = this.getResults.bind(this);
    this.checkCache = this.checkCache.bind(this);
  }

  componentDidMount() {
    this.checkCache();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.user !== prevProps.match.params.user) {
      this.checkCache();
    }
  }

  checkCache() {
    const user = this.props.location.user;
    if (user) {
      this.setState({
        user: { ...user, username: this.props.match.params.user },
      });
    } else {
      fetch(
        process.env.REACT_APP_API_URL +
          "/wp-content/plugins/users-api/query.php?username=" +
          this.props.match.params.user
      )
        .then((response) => response.json())
        .then((result) => {
          const state = { ...result, username: this.props.match.params.user };
          this.setState({ user: state });
        });
    }
    this.getResults();
  }

  loadMore() {
    let url =
      "?user=" +
      this.props.match.params.user +
      "&start=" +
      this.state.results[this.state.results.length - 1].id;
    fetch(
      process.env.REACT_APP_API_URL +
        "/wp-content/plugins/products-api/query.php" +
        url
    )
      .then((response) => response.json())
      .then((results) => {
        this.setState({
          results: [...this.state.results].concat(results),
          empty: results.length === 0,
          hasMore: results.length === 20,
        });
      });
  }

  getResults() {
    this.setState({ loading: true });
    let url = "?user=" + this.props.match.params.user;
    fetch(
      process.env.REACT_APP_API_URL +
        "/wp-content/plugins/products-api/query.php" +
        url
    )
      .then((response) => response.json())
      .then((results) => {
        this.setState({
          results: results,
          loading: false,
          empty: results.length === 0,
          hasMore: results.length === 20,
        });
      })
  }

  render() {
    return (
      <div className="user">
        <ProfileHeader user={this.state.user} />
        <InfiniteScroll
          loader={<Products loading={true} />}
          scrollThreshold="50%"
          dataLength={this.state.results.length}
          next={this.loadMore}
          hasMore={this.state.hasMore}
        >
          <Products
            loading={this.state.loading}
            empty={this.state.empty}
            results={this.state.results}
          />
        </InfiniteScroll>
      </div>
    );
  }
}
