import React from 'react';
import VirtualList from 'react-tiny-virtual-list';
import { components } from 'react-select';
import { customStyles } from '../data';
import ReactSelect, { createFilter } from 'react-select';

const DefaultItemHeight = 40;

class CustomMenuList extends React.Component {
  renderItem = (props) => {
    const { children } = this.props;
    if (Array.isArray(children)) {
      return (
        <li style={props.style} key={props.index}>
          {children[props.index]}
        </li>
      );
    }
    return (
      <li
        key={props.index}
        className="react-virtualized-menu-placeholder"
      >
        {children.props.children}
      </li>
    );
  }

  render() {
    const { options, children, maxHeight, getValue } = this.props;

    const [value]         = getValue();
    const initialOffset   = options.indexOf(value) * DefaultItemHeight;
    const childrenOptions = React.Children.toArray(children);
    const wrapperHeight   = maxHeight < childrenOptions.length * DefaultItemHeight ?
                              maxHeight : childrenOptions.length * DefaultItemHeight;

    return (
      <span className="react-virtualized-list-wrapper">
        <VirtualList
          width="100%"
          height={wrapperHeight + 6}
          scrollOffset={initialOffset}
          itemCount={childrenOptions.length}
          itemSize={DefaultItemHeight}
          renderItem={this.renderItem}
        />
      </span>
    );
  }
}

const CustomOption = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option
      {...newProps}
      className="custom-option"
    >
      {children}
    </components.Option>
  );
};

export default function BrandSelect(props) {
  return (
    <ReactSelect
      onChange={props.onChange}
      isClearable={true}
      isSearchable={true}
      isMulti={props.isMulti}
      filterOption={createFilter({ ignoreAccents: false })}
      options={props.options}
      captureMenuScroll={false}
      classNamePrefix="custom-select"
      components={{ Option: CustomOption, MenuList: CustomMenuList }}
      styles={customStyles}
      value={props.value}
    />
  )
}