import React from "react";
import Select from "react-select";
import { ReactComponent as ClearIcon } from "../svg/clear.svg";
import {
  customStyles,
  orderByOptions,
  conditions,
  sizes,
} from "../data";
import { brands } from "../data/BrandsData";
import BrandSelect from "./brandselect";
import { formatPrice, getFloatVal } from "../functions";

export default class Filters extends React.Component {
  constructor() {
    super();
    this.state = {
      minPrice: "",
      maxPrice: "",
      brand: [],
      condition: null,
      size: [],
      orderBy: null,
      filters: JSON.parse(localStorage.getItem("filters"))
        ? JSON.parse(localStorage.getItem("filters"))
        : [],
    };
    this.addFilterName = this.addFilterName.bind(this);
    this.removeFilterName = this.removeFilterName.bind(this);
    this.filterBrand = this.filterBrand.bind(this);
    this.filterCondition = this.filterCondition.bind(this);
    this.filterSize = this.filterSize.bind(this);
    this.filterPrice = this.filterPrice.bind(this);
    this.orderBy = this.orderBy.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
  }

  componentDidMount() {
    let params = JSON.parse(localStorage.getItem("params"));
    if (params) {
      if (params.min_price) this.setState({ minPrice: params.min_price });
      if (params.max_price) this.setState({ maxPrice: params.max_price });
      if (params.orderby === "price_asc")
        this.setState({
          orderBy: orderByOptions.filter((obj) => {
            return obj.value === "1";
          }),
        });
      if (params.orderby === "price_desc")
        this.setState({
          orderBy: orderByOptions.filter((obj) => {
            return obj.value === "2";
          }),
        });
      if (params.orderby === "popularity")
        this.setState({
          orderBy: orderByOptions.filter((obj) => {
            return obj.value === "3";
          }),
        });
      if (params.brand)
        this.setState({
          brand: params.brand.split(",").map((e) => {
            return brands[e];
          }),
        });
      if (params.condition)
        this.setState({
          condition: params.condition.split(",").map((e) => {
            return conditions[e];
          }),
        });
      if (params.size)
        this.setState({
          size: params.size.split(",").map((e) => {
            return sizes[e];
          }),
        });
    }
  }

  addFilterName(name) {
    if (!this.state.filters.includes(name) || this.state.filters.length === 0) {
      this.setState(({ filters }) => {
        let newFilters = filters.concat(name);
        localStorage.setItem("filters", JSON.stringify(newFilters));
        return { filters: newFilters };
      });
    }
  }

  removeFilterName(name) {
    this.setState(({ filters }) => {
      let oldFilters = [...filters];
      let newFilters = oldFilters.filter((e) => e !== name);
      localStorage.setItem("filters", JSON.stringify(newFilters));
      return { filters: newFilters };
    });
  }

  filterBrand(target) {
    const params = { ...this.props.params.params };
    this.setState({ brand: target });
    if (target) {
      params.brand = target
        .map((item) => {
          return item.value;
        })
        .join(",");
      this.addFilterName("Brand");
    } else {
      params.brand = null;
      this.removeFilterName("Brand");
    }
    this.props.params.changeParams(params);
  }

  filterCondition(target) {
    const params = { ...this.props.params.params };
    this.setState({ condition: target });
    if (target) {
      params.condition = target
        .map((item) => {
          return item.value;
        })
        .join(",");
      this.addFilterName("Condition");
    } else {
      params.condition = null;
      this.removeFilterName("Condition");
    }
    this.props.params.changeParams(params);
  }

  filterSize(target) {
    const params = { ...this.props.params.params };
    this.setState({ size: target });
    if (target) {
      params.size = target
        .map((item) => {
          return item.value;
        })
        .join(",");
      this.addFilterName("Size");
    } else {
      params.size = null;
      this.removeFilterName("Size");
    }
    this.props.params.changeParams(params);
  }

  setPrice = (e) =>
    this.setState({ [e.target.name]: formatPrice(e.target.value) });

  filterPrice(e) {
    e.preventDefault();
    const params = { ...this.props.params.params };
    const min = this.state.minPrice;
    const max = this.state.maxPrice;
    params.min_price = min ? getFloatVal(min) : null;
    params.max_price = max ? getFloatVal(max) : null;
    this.addFilterName("Price");
    if (!max && !min) this.removeFilterName("Price");
    this.props.params.changeParams(params);
  }

  orderBy(target) {
    const params = { ...this.props.params.params };
    this.setState({ orderBy: target });
    if (target) {
      if (target.value === "1") params.orderby = "price_asc";
      if (target.value === "2") params.orderby = "price_desc";
      if (target.value === "3") params.orderby = "popularity";
      this.addFilterName("Order By");
    } else {
      params.orderby = null;
      this.removeFilterName("Order By");
    }
    this.props.params.changeParams(params);
  }

  removeFilter(index) {
    const params = { ...this.props.params.params };
    let name = this.state.filters[index];
    let filters = [...this.state.filters];
    if (index !== -1) {
      filters.splice(index, 1);
      this.setState({ filters }, () => {
        localStorage.setItem("filters", JSON.stringify(this.state.filters));
      });
    }
    if (name === "Brand") {
      params.brand = null;
      this.setState({ brand: null });
    }
    if (name === "Condition") {
      params.condition = null;
      this.setState({ condition: null });
    }
    if (name === "Size") {
      params.size = null;
      this.setState({ size: null });
    }
    if (name === "Order By") {
      params.orderby = null;
      this.setState({ orderBy: null });
    }
    if (name === "Price") {
      params.min_price = params.max_price = null;
      this.setState({ minPrice: "", maxPrice: "" });
    }
    this.props.params.changeParams(params);
  }

  render() {
    const { brand, condition } = this.state;
    return (
      <div id="filters-modal">
        <div className="filters-title">
          <h2 className="title">Filters</h2>
        </div>
        <div style={{ padding: "15px", paddingTop: "0" }}>
          <div className="remove-filters">
            {this.state.filters
              ? this.state.filters.map((filter, index) => (
                  <div
                    className="clear-button"
                    key={index}
                    onClick={() => this.removeFilter(index)}
                  >
                    <div>{filter}</div>
                    <ClearIcon width="16" height="16" className="clear-icon" />
                  </div>
                ))
              : null}
          </div>
          <p className="filter-titles">Brand:</p>
          <BrandSelect
            value={brand}
            options={
              brand
                ? brand.length > 3
                  ? []
                  : Object.values(brands)
                : Object.values(brands)
            }
            onChange={this.filterBrand}
            isMulti={true}
          />
          <p className="filter-titles">Condition:</p>
          <Select
            styles={customStyles}
            value={condition}
            onChange={this.filterCondition}
            options={
              condition
                ? condition.length > 3
                  ? []
                  : Object.values(conditions)
                : Object.values(conditions)
            }
            isClearable={true}
            isSearchable={true}
            isMulti={true}
          />
          <p className="filter-titles">Order By:</p>
          <Select
            styles={customStyles}
            value={this.state.orderBy}
            onChange={this.orderBy}
            options={orderByOptions}
            isClearable={true}
          />
          <p className="filter-titles">Price:</p>
          <form
            className="price-filter"
            onSubmit={this.filterPrice}
            autoComplete="off"
          >
            <input
              type="text"
              name="minPrice"
              value={this.state.minPrice}
              onChange={this.setPrice}
              placeholder="Min"
            />
            <input
              type="text"
              name="maxPrice"
              value={this.state.maxPrice}
              onChange={this.setPrice}
              placeholder="Max"
            />
            <input 
              type="submit" 
              value="Filter Price"
              className="submit-button"
            />
          </form>
        </div>
      </div>
    );
  }
}
