import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as CartIcon } from "../svg/cart.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faQuestion,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { showModal } from "./togglemodals";

export default function HeaderRight(props) {
  return (
    <div className="header-right">
      <div className="header-right-inner">
        <div
          data-tooltip="Help"
          id="help-icon"
          className="header-right-container help"
        >
          <Link to="/help">
            <div className="header-links">
              <FontAwesomeIcon
                style={{ width: "22px", height: "22px" }}
                icon={faQuestion}
              />
            </div>
          </Link>
        </div>
        <div
          data-tooltip="Categories"
          id="categories-icon"
          className="header-right-container categories"
        >
          <div className="header-links" onClick={showModal}>
            <FontAwesomeIcon
              style={{ width: "23px", height: "23px" }}
              icon={faBars}
            />
          </div>
        </div>
        <div
          data-tooltip="Filters"
          id="filters-icon"
          className="header-right-container filters"
        >
          <div className="header-links" onClick={showModal}>
            <FontAwesomeIcon icon={faSortAmountDown} />
            <span className="count">{props.filtersCount}</span>
          </div>
        </div>
        <div
          data-tooltip="Cart"
          id="cart-icon"
          className="header-right-container cart"
        >
          <div className="header-links" onClick={showModal}>
            <CartIcon
              height="31"
              width="31"
              style={{ margin: "0 0 2px 2px" }}
            />
            <span className="count">{props.cartCount}</span>
          </div>
        </div>
        <div
          data-tooltip={props.login.user.loggedIn ? "Profile" : "Login"}
          id={props.login.user.loggedIn ? "profile-icon" : "login-icon"}
          className="header-right-container login"
        >
          <div
            id="login"
            onClick={showModal}
            className={
              props.login.user.loggedIn
                ? "header-links loggedIn"
                : "header-links"
            }
          >
            {props.login.user.loggedIn && props.login.user.profile.small ? (
              <img
                className="vertical"
                src={props.login.user.profile.small}
                alt={props.login.user.id + "-profile"}
              />
            ) : (
              <FontAwesomeIcon
                style={{ width: "23px", height: "23px" }}
                icon={faUser}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
