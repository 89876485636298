import React from "react";
import axios from "axios";
import Categories from "../components/categories";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import { conditions, customStyles } from "../data";
import { brands } from "../data/BrandsData";
import { formatPrice, checkRequiredInputs, getFloatVal } from "../functions";
import { ReactComponent as AppLogo } from "../svg/applogo.svg";
import CreatableInputOnly from "../components/creatableinput";
import BrandSelect from "../components/brandselect";
import CurrencyInput from "../components/currencyinput";

export default class AddProduct extends React.Component {
  constructor() {
    super();
    this.state = {
      displayErrors: "none",
      errorMessage: "",
      success: false,
      errorColor: "#e64c54",
      product: [],
      categories: [],
      tags: [],
      brand: 0,
      condition: 0,
      price: "",
      earnings: "",
      name: "",
      image1: { src: "", class: "", delete: false, id: "" },
      image2: { src: "", class: "", delete: false, id: "" },
      image3: { src: "", class: "", delete: false, id: "" },
      image4: { src: "", class: "", delete: false, id: "" },
      image5: { src: "", class: "", delete: false, id: "" },
      image6: { src: "", class: "", delete: false, id: "" },
      redirect: false,
    };
    this.setCategories = this.setCategories.bind(this);
    this.setTags = this.setTags.bind(this);
    this.setBrand = this.setBrand.bind(this);
    this.setCondition = this.setCondition.bind(this);
    this.setImage = this.setImage.bind(this);
    this.addProduct = this.addProduct.bind(this);
  }

  componentDidMount() {
    if (!this.props.login.user.activated) {
      this.props.alert.error("Please activate your account to add a product.");
      this.setState({ redirect: "/profile/settings?info=true" });
    }
  }

  setImage(event) {
    if (!this.props.login.user.activated) {
      this.props.alert.error(
        "Please activate your account to upload pictures."
      );
      return;
    }
    var home = this;
    var thisImage = event.target.id;
    var obj = this.state[thisImage];
    const token = JSON.parse(localStorage.getItem("user")).token;
    const headers = { Authorization: `Bearer ${token}` };
    if (event.target.value) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        obj.id = "";
        obj.src = image.src;
        obj.delete = false;
        image.onload = function () {
          this.width > this.height
            ? (obj.class = "horizontal")
            : (obj.class = "vertical");
          home.setState({ [thisImage]: obj });
        };
      };
      reader.readAsDataURL(event.target.files[0]);
      const data = new FormData();
      data.append("action", "file_upload");
      data.append("file", event.target.files[0]);
      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/wp-json/wp/v2/media",
        data,
        headers,
      })
        .then(function (response) {
          obj.id = response.data.id;
          home.setState({ [thisImage]: obj });
          if (obj.delete) {
            axios({
              method: "delete",
              url:
                process.env.REACT_APP_API_URL +
                "/wp-json/wp/v2/media/" +
                response.data.id +
                "/?force=1",
              headers: headers,
            }).then(() => {
              obj.src = "";
              obj.class = "";
              obj.id = "";
              obj.delete = false;
              home.setState({ [thisImage]: obj });
            });
          }
        })
        .catch(() => {
          obj.src = "";
          obj.class = "";
          obj.id = "";
          obj.delete = false;
          home.setState({ [thisImage]: obj });
          this.props.alert.error("There was an error uploading this image.");
        });
    } else {
      if (obj.id) {
        obj.src = "";
        obj.class = "";
        this.setState({ [thisImage]: obj });
        axios({
          method: "delete",
          url:
            process.env.REACT_APP_API_URL +
            "/wp-json/wp/v2/media/" +
            obj.id +
            "/?force=1",
          headers: headers,
        })
          .then(function () {
            obj.id = "";
            obj.delete = false;
            home.setState({ [thisImage]: obj });
          })
          .catch(() => {
            obj.src = "";
            obj.class = "";
            obj.id = "";
            obj.delete = false;
            home.setState({ [thisImage]: obj });
          });
      } else {
        obj.src = "";
        obj.class = "";
        obj.id = "";
        obj.delete = true;
        this.setState({ [thisImage]: obj });
      }
    }
  }

  setCategories(target, number) {
    if (target) {
      this.setState((state) => {
        const newCategories = [...state.categories];
        newCategories[number] = { id: target.value };
        newCategories.splice(number + 1, 3 - number);
        return { categories: newCategories };
      });
    } else {
      this.setState((state) => {
        let newCategories = [...state.categories];
        if (number === 0) newCategories = [];
        if (number === 1) newCategories.splice(1, 2);
        if (number === 2) newCategories.splice(2, 1);
        return { categories: newCategories };
      });
    }
  }

  setTags(tags) {
    this.setState({ tags });
  }

  setBrand(target) {
    target
      ? this.setState({ brand: target.value })
      : this.setState({ brand: "" });
  }

  setCondition(target) {
    target
      ? this.setState({ condition: target.value })
      : this.setState({ condition: "" });
  }

  setPrice = (e) => {
    const price = getFloatVal(e.target.value)
    if(price <= 10000) {
      this.setState({
        price: formatPrice(e.target.value),
        earnings: formatPrice("$" + (price * 0.9).toFixed(2))
      })
    } else if(!price) {
      this.setState({earnings: "", price: ""})
    }
  }

  setPriceOnBlur = (e) => {
    const price = getFloatVal(e.target.value)
    this.setState({
      price: formatPrice(e.target.value, true),
      earnings: price ? formatPrice("$" + (price * 0.9).toFixed(2), true) : ""
    })
  }

  setEarnings = (e) => {
    const price = getFloatVal(e.target.value)
    if(price < 9000) {
      this.setState({
        earnings: formatPrice(e.target.value), 
        price: formatPrice("$" + (price / 0.9).toFixed(2))
      })
    } else if(!price) {
      this.setState({earnings: "", price: ""})
    }
  }

  setEarningsOnBlur = (e) => {
    const price = getFloatVal(e.target.value)
    this.setState({
      price: price ? formatPrice("$" + (price / 0.9).toFixed(2), true) : "",
      earnings: formatPrice(e.target.value, true)
    })
  }

  addProduct(e) {
    if (e) e.preventDefault();
    if (!checkRequiredInputs(this.state)) {
      this.props.alert.error("Please fill out the required inputs.");
      return;
    }
    document.getElementById("preloader-screen").style.display = "block";
    document.getElementById("preloader-screen").classList.remove("loaded");
    if (
      (this.state.image1.src && !this.state.image1.id) ||
      (this.state.image2.src && !this.state.image2.id) ||
      (this.state.image3.src && !this.state.image3.id) ||
      (this.state.image4.src && !this.state.image4.id) ||
      (this.state.image5.src && !this.state.image5.id) ||
      (this.state.image6.src && !this.state.image6.id)
    ) {
      setTimeout(this.addProduct, 1000);
      return;
    }
    let images = [
      { id: this.state.image1.id },
      { id: this.state.image2.id },
      { id: this.state.image3.id },
      { id: this.state.image4.id },
      { id: this.state.image5.id },
      { id: this.state.image6.id },
    ];
    let filtered = images.filter(function (el) {
      return el.id !== "";
    });
    const tags = this.state.tags.map((el) => ({
      name: el.value,
    }));
    let product = {
      add_product: true,
      images: filtered,
      name: this.state.name,
      description: this.state.description,
      categories: this.state.categories,
      tags,
      brand: this.state.brand,
      condition: this.state.condition,
      regular_price: this.state.price,
    };
    let token = this.props.login.user.token;
    var home = this;
    if (token) {
      let headers = { Authorization: `Bearer ${token}` };
      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/wp-json/wc/v3/products",
        data: product,
        headers: headers,
      })
        .then((response) => {
          document.getElementById("preloader-screen").classList.add("loaded");
          document.getElementById("preloader-screen").style.display = "none";
          response.data.likes = 0;
          home.props.login.updateUser({
            products: [response.data, ...home.props.login.user.products],
          });
          home.setState({
            product: response.data,
            redirect: {
              product: response.data,
              pathname: `/product/${response.data.id}`,
            },
          });
        })
        .catch(() => {
          document.getElementById("preloader-screen").classList.add("loaded");
          document.getElementById("preloader-screen").style.display = "none";
          this.props.alert.error("There was an error adding this product.");
        });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div className="add-product">
        <div id="preloader-screen">
          <AppLogo id="preloader-svg" />
        </div>
        <h1 style={{ textAlign: "center" }}>Add Product</h1>
        <form onSubmit={this.addProduct} autoComplete="off">
          <label>Images:</label>
          <div className="images container">
            <ul className="form-images">
              <li className="file-upload-wrap">
                {this.state.image1.src ? (
                  <>
                    <img
                      className={this.state.image1.class}
                      src={this.state.image1.src}
                      alt="image1"
                    />
                    <div id="image1" onClick={this.setImage} />
                  </>
                ) : (
                  <>
                    <label htmlFor="image1">+</label>
                    <input
                      onChange={this.setImage}
                      type="file"
                      id="image1"
                      accept=".png, .jpg, .jpeg"
                    />
                  </>
                )}
              </li>
              <li className="file-upload-wrap">
                {this.state.image2.src ? (
                  <>
                    <img
                      className={this.state.image2.class}
                      src={this.state.image2.src}
                      alt="image2"
                    />
                    <div id="image2" onClick={this.setImage} />
                  </>
                ) : (
                  <>
                    <label htmlFor="image2">+</label>
                    <input
                      onChange={this.setImage}
                      type="file"
                      id="image2"
                      accept=".png, .jpg, .jpeg"
                    />
                  </>
                )}
              </li>
              <li className="file-upload-wrap">
                {this.state.image3.src ? (
                  <>
                    <img
                      className={this.state.image3.class}
                      src={this.state.image3.src}
                      alt="image3"
                    />
                    <div id="image3" onClick={this.setImage} />
                  </>
                ) : (
                  <>
                    <label htmlFor="image3">+</label>
                    <input
                      onChange={this.setImage}
                      type="file"
                      id="image3"
                      accept=".png, .jpg, .jpeg"
                    />
                  </>
                )}
              </li>
              <li className="file-upload-wrap">
                {this.state.image4.src ? (
                  <>
                    <img
                      className={this.state.image4.class}
                      src={this.state.image4.src}
                      alt="image4"
                    />
                    <div id="image4" onClick={this.setImage} />
                  </>
                ) : (
                  <>
                    <label htmlFor="image4">+</label>
                    <input
                      onChange={this.setImage}
                      type="file"
                      id="image4"
                      accept=".png, .jpg, .jpeg"
                    />
                  </>
                )}
              </li>
              <li className="file-upload-wrap">
                {this.state.image5.src ? (
                  <>
                    <img
                      className={this.state.image5.class}
                      src={this.state.image5.src}
                      alt="image5"
                    />
                    <div id="image5" onClick={this.setImage} />
                  </>
                ) : (
                  <>
                    <label htmlFor="image5">+</label>
                    <input
                      onChange={this.setImage}
                      type="file"
                      id="image5"
                      accept=".png, .jpg, .jpeg"
                    />
                  </>
                )}
              </li>
              <li className="file-upload-wrap">
                {this.state.image6.src ? (
                  <>
                    <img
                      className={this.state.image6.class}
                      src={this.state.image6.src}
                      alt="image6"
                    />
                    <div id="image6" onClick={this.setImage} />
                  </>
                ) : (
                  <>
                    <label htmlFor="image6">+</label>
                    <input
                      onChange={this.setImage}
                      type="file"
                      id="image6"
                      accept=".png, .jpg, .jpeg"
                    />
                  </>
                )}
              </li>
            </ul>
          </div>
          <div className="name container">
            <label htmlFor="name">Name:</label>
            <input
              name="name"
              type="text"
              id="name"
              value={this.state.name}
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
            />
          </div>
          <div className="description container">
            <label htmlFor="description">Description:</label>
            <textarea
              name="description"
              id="description"
              rows="5"
              cols="5"
              value={this.state.description}
              onChange={(e) => {
                this.setState({ description: e.target.value });
              }}
            />
          </div>
          <div id="categories-container" className="left container">
            <label>Categories:</label>
            <div className="control">
              <Categories
                addProduct={true}
                setCategories={this.setCategories}
                clearable={true}
              />
            </div>
          </div>
          <div id="attributes-container" className="right container">
            <label>Attributes (optional):</label>
            <div className="control">
              <div className="container">
                <label htmlFor="brand">Tags (3 max):</label>
                <CreatableInputOnly setTags={this.setTags} />
              </div>
              <div className="container">
                <label htmlFor="brand">Brand:</label>
                <BrandSelect
                  options={Object.values(brands)}
                  onChange={this.setBrand}
                  isMulti={false}
                />
              </div>
              <div className="container">
                <label htmlFor="condition">Condition:</label>
                <Select
                  name="condition"
                  styles={customStyles}
                  onChange={this.setCondition}
                  options={Object.values(conditions)}
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
            </div>
          </div>
          <div id="price-container" className="left container">
            <label htmlFor="price">Price:</label>
            <CurrencyInput 
              onChange={this.setPrice} 
              onBlur={this.setPriceOnBlur}
              value={this.state.price} 
              id="price"
            />
          </div>
          <div id="earnings-container" className="right container">
            <label htmlFor="earnings">You Earn:</label>
            <CurrencyInput 
              onChange={this.setEarnings} 
              onBlur={this.setEarningsOnBlur}
              value={this.state.earnings} 
            />
          </div>          
          <button type="submit" className="submit-button button">
            Add Product
          </button>
        </form>
      </div>
    );
  }
}
