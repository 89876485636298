import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as RightIcon } from "../svg/chevron.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faStoreAlt,
  faSignOutAlt,
  faCog,
  faBook,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

export default function ProfileLinks(props) {
  const { pathname } = useLocation();
  const [link, select] = useState(0);
  useEffect(() => {
    const defaultLinks = [
      "/profile/likes",
      "/profile/products",
      "/add-product",
      "/profile/offers",
      "/profile/orders",
      "/profile/settings",
    ];
    if (defaultLinks.includes(pathname)) {
      scrollToLink(defaultLinks.indexOf(pathname) + 1);
      select(defaultLinks.indexOf(pathname) + 1);
    } else {
      select(0);
    }
  }, [pathname]);
  const scrollToLink = (id) => {
    const links = document.getElementById("horizontal-links");
    if (links) {
      const link = document.getElementById("link-" + id);
      const offset =
        link.offsetLeft -
        links.offsetWidth / 2 +
        link.offsetWidth / 2 -
        (window.innerWidth - links.offsetWidth) / 2 +
        4;
      links.scrollLeft = offset;
    }
  };
  const user = props.login.user;
  return (
    <div className="links">
      <div
        className="profile-links"
        id={props.horizontal ? "horizontal-links" : null}
      >
        <Link
          id={props.horizontal ? "link-1" : null}
          className={props.horizontal && link === 1 ? "selected icon-links" : "icon-links"}
          to="/profile/likes"
        >
          <div className="icon-left">
            <FontAwesomeIcon
              style={{ width: "20px", height: "20px" }}
              icon={faHeart}
            />
            <span className="count">{user.likes.length}</span>
          </div>
          Likes
          <RightIcon className="icon-right" />
        </Link>
        <Link
          id={props.horizontal ? "link-2" : null}
          className={props.horizontal && link === 2 ? "selected icon-links" : "icon-links"}
          to="/profile/products"
        >
          <div className="icon-left">
            <FontAwesomeIcon
              style={{ width: "20px", height: "20px" }}
              icon={faStoreAlt}
            />
            <span className="count">{user.products.length}</span>
          </div>
          Products
          <RightIcon className="icon-right" />
        </Link>
        <Link
          id={props.horizontal ? "link-3" : null}
          className={props.horizontal && link === 3 ? "selected icon-links" : "icon-links"}
          to="/add-product"
        >
          <div className="icon-left">
            <span style={{ fontSize: "27px" }}>+</span>
          </div>
          Add Product
          <RightIcon className="icon-right" />
        </Link>
        <Link
          id={props.horizontal ? "link-4" : null}
          className={props.horizontal && link === 4 ? "selected icon-links" : "icon-links"}
          to="/profile/offers"
        >
          <div className="icon-left">
            <span style={{ fontSize: "21px", fontWeight: "600" }}>$</span>
            <span className="count">
              {user.yourOffers.length + user.customerOffers.length}
            </span>
          </div>
          Offers
          <RightIcon className="icon-right" />
        </Link>
        <Link
          id={props.horizontal ? "link-5" : null}
          className={props.horizontal && link === 5 ? "selected icon-links" : "icon-links"}
          to="/profile/orders"
        >
          <div className="icon-left">
            <FontAwesomeIcon
              style={{ width: "20px", height: "20px" }}
              icon={faBook}
            />
            <span className="count">
              {user.yourOrders.length + user.customerOrders.length}
            </span>
          </div>
          Orders
          <RightIcon className="icon-right" />
        </Link>
        <Link
          id={props.horizontal ? "link-6" : null}
          className={props.horizontal && link === 6 ? "selected icon-links" : "icon-links"}
          to="/profile/settings"
        >
          <div className="icon-left">
            <FontAwesomeIcon
              style={{ width: "20px", height: "20px" }}
              icon={faCog}
            />
          </div>
          Settings
          <RightIcon className="icon-right" />
        </Link>
        <button
          id={props.horizontal ? "link-7" : null}
          onClick={() => props.login.logout()}
          className="icon-links"
        >
          <div className="icon-left">
            <FontAwesomeIcon
              style={{ width: "20px", height: "20px" }}
              icon={faSignOutAlt}
            />
          </div>
          Logout
          <RightIcon className="icon-right" />
        </button>
      </div>
    </div>
  );
}
