import React, { useState } from "react";

export default function Login(props) {
  const [displayErrors, setDisplayErrors] = useState("none");

  const [errorMessage, setErrorMessage] = useState("Welcome to Atlas");

  const [errorColor, setErrorColor] = useState("#e64c54");

  const openForgotPassword = () => {
    document.getElementById("login-modal").style.display = "none";
    document.getElementById("password-modal").style.display = "block";
  };

  const openRegister = () => {
    document.getElementById("login-modal").style.display = "none";
    document.getElementById("register-modal").style.display = "block";
  };

  const loginUser = (e) => {
    e.preventDefault();
    let username = document.getElementById("login-username").value;
    let password = document.getElementById("login-password").value;
    if (!username) {
      setDisplayErrors("flex");
      setErrorMessage("Enter a Username");
    }
    if (!password) {
      setDisplayErrors("flex");
      setErrorMessage("Enter a Password");
    }
    if (username && password) {
      setDisplayErrors("flex");
      setErrorMessage("Logging you in...");
      const body = JSON.stringify({ username, password });
      const headers = { "Content-Type": "application/json" };
      fetch(process.env.REACT_APP_API_URL + "/wp-json/jwt-auth/v1/token", {
        method: "post",
        body,
        headers,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.statusCode === 200) {
            if (result.data.loggedIn) {
              setErrorMessage("Login successful!");
              setErrorColor("#15e89f");
              setTimeout(() => {
                props.login.setUser(result.data);
              }, 300);
            } else {
              setErrorMessage("Account expired.");
              document.getElementById("activation-button").style.display =
                "block";
              setErrorColor("#e64c54");
            }
          } else {
            if (result.code === "incorrect_password") {
              setErrorMessage("Incorrect password.");
              setErrorColor("#e64c54");
            } else if (result.code === "invalid_username") {
              setErrorMessage("Invalid username.");
              setErrorColor("#e64c54");
            }
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setErrorColor("#e64c54");
        });
    }
  };

  const resendActivationEmail = () => {
    let username = document.getElementById("login-username").value;
    if (!username) {
      setDisplayErrors("flex");
      setErrorMessage("Enter a Username");
    }
    if (username) {
      setDisplayErrors("flex");
      setErrorMessage("Sending email...");
      const body = JSON.stringify({ username });
      const headers = { "Content-Type": "application/json" };
      fetch(
        process.env.REACT_APP_API_URL + "/wp-json/atlas/users/activate/email",
        { method: "post", body, headers }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result === "Email Sent") {
            setErrorMessage("Sent!");
            setErrorColor("#15e89f");
          } else {
            setErrorMessage(result);
            setErrorColor("#e64c54");
          }
        })
        .catch(() => {
          setErrorMessage("Error.");
          setErrorColor("#e64c54");
        });
    }
  };

  return (
    <div id="login-modal" className="sign-in-container login">
      <div className="filters-title">
        <h2 className="title">Login</h2>
      </div>
      <div style={{ padding: "30px 20px 22px" }}>
        <div
          style={{ display: displayErrors, background: errorColor }}
          className="sign-in-errors login"
        >
          {errorMessage}
        </div>
        <button
          onClick={resendActivationEmail}
          id="activation-button"
          style={{ display: "none", marginBottom: "20px" }}
          className="center-button"
        >
          Resend activation email
        </button>
        <form id="login-form" onSubmit={loginUser}>
          <input
            type="text"
            id="login-username"
            placeholder="Username"
            className="input"
          />
          <input
            style={{ display: "none" }}
            type="password"
            name="fakepasswordremembered"
          />
          <input
            type="password"
            id="login-password"
            placeholder="Password"
            className="input"
          />
          <button type="submit" className="submit-button button login-button">
            Login
          </button>
        </form>
        <button onClick={openForgotPassword} className="center-button">
          Forgot password?
        </button>
        <h6 style={{ marginTop: "8px" }}>
          {"Don't have an account? "}
          <button onClick={openRegister} className="open-register-modal">
            Register
          </button>
        </h6>
      </div>
    </div>
  );
}
