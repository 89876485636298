import React, { useState } from "react";

export default function Register(props) {
  const [displayErrors, setDisplayErrors] = useState("none");

  const [errorMessage, setErrorMessage] = useState("");

  const [errorColor, setErrorColor] = useState("#e64c54");

  const openLogin = () => {
    document.getElementById("register-modal").style.display = "none";
    document.getElementById("login-modal").style.display = "block";
  };

  const registerUser = (e) => {
    e.preventDefault();
    const username = document.getElementById("register-username").value;
    const email = document.getElementById("register-email").value;
    const password = document.getElementById("register-password").value;
    if (!username) {
      setDisplayErrors("flex");
      setErrorMessage("Enter a Username");
    }
    if (!email) {
      setDisplayErrors("flex");
      setErrorMessage("Enter an Email");
    }
    if (!password) {
      setDisplayErrors("flex");
      setErrorMessage("Enter a Password");
    }
    if (username && password && email) {
      setDisplayErrors("flex");
      setErrorMessage("Registering...");
      const body = JSON.stringify({ username, email, password });
      const headers = { "Content-Type": "application/json" };
      fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/users/register", {
        method: "post",
        body,
        headers,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.statusCode === 200) {
            setErrorMessage("Registered!");
            setErrorColor("#15e89f");
            setTimeout(() => {
              props.login.setUser(result.data);
            }, 300);
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    }
  };

  return (
    <div id="register-modal" className="sign-in-container register">
      <div className="filters-title">
        <h2 className="title">Register</h2>
      </div>
      <div style={{ padding: "30px 20px 22px" }}>
        <div
          style={{ display: displayErrors, background: errorColor }}
          className="sign-in-errors login"
        >
          {errorMessage}
        </div>
        <form id="register-form" onSubmit={registerUser}>
          <input
            type="text"
            id="register-username"
            placeholder="Username"
            className="input"
          />
          <input
            type="text"
            id="register-email"
            placeholder="Email"
            className="input"
          />
          <input
            autoComplete="new-password"
            type="password"
            id="register-password"
            placeholder="Password"
            className="input"
          />
          <button
            className="submit-button button register-button"
            type="submit"
          >
            Register
          </button>
        </form>
        <h6 style={{ marginTop: "23px" }}>
          {"Already have an account? "}
          <button onClick={openLogin} className="open-login-modal">
            Login
          </button>
        </h6>
      </div>
    </div>
  );
}
