import React from "react";
import { ReactComponent as Twitter } from "../svg/twitter.svg";
import { ReactComponent as Instagram } from "../svg/instagram.svg";
import { ReactComponent as Facebook } from "../svg/facebook.svg";
import { ReactComponent as Envelope } from "../svg/envelope.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-container">
        <p style={{ textAlign: "center", margin: "10px 0", lineHeight: 1 }}>
          © 2021 -{" "}
          <Link to="/privacy" className="footer-links">
            Privacy
          </Link>{" "}
          -{" "}
          <Link to="/terms" className="footer-links">
            Terms & Conditions
          </Link>{" "}
          -{" "}
          <Link to="/help" className="footer-links">
            Contact
          </Link>
        </p>
        <span>business@atlas.deals</span>
        <div className="footer-icon-container">
          <a className="icon" href="">
            <Twitter style={{ width: "22px", height: "22px" }} />
          </a>
          <a className="icon" href="">
            <Instagram style={{ width: "24px", height: "24px" }} />
          </a>
          <a className="icon" href="">
            <Facebook style={{ width: "22px", height: "22px" }} />
          </a>
          <a className="icon" href="mailto:business@atlas.deals">
            <Envelope style={{ width: "22px", height: "22px" }} />
          </a>
        </div>
      </div>
    </footer>
  );
}
