import React from "react";
import { Link, Redirect } from "react-router-dom";
import DropIn from "braintree-web-drop-in-react";
import { ReactComponent as AppLogo } from "../svg/applogo.svg";

export default class Payment extends React.Component {
  instance;

  state = {
    clientToken: this.props.login.user.clientToken
      ? this.props.login.user.clientToken
      : process.env.SANDBOX_TOKEN || "production_d5zb9hwf_gybpdqg8c2ctprxh",
  };

  componentDidUpdate(prevProps) {
    const user = this.props.login.user;
    if (user.loggedIn !== prevProps.login.user.loggedIn) {
      this.setState({ clientToken: "" });
      setTimeout(() => {
        this.setState({
          clientToken: user.clientToken
            ? user.clientToken
            : process.env.SANDBOX_TOKEN || "production_d5zb9hwf_gybpdqg8c2ctprxh",
        });
      }, 1000);
    }
  }

  async buy(e) {
    e.preventDefault();
    const alert = this.props.alert;
    const user = this.props.login.user;
    const offer = this.props.offer;
    const cart = this.props.cart ? this.props.cart.cart : null;
    const { billing, contact } = this.props;
    const { firstName, lastName } = contact;
    billing.first_name = firstName;
    billing.last_name = lastName;
    billing.email = !user.loggedIn ? contact.email : user.email;
    if ((!contact.username || !contact.password) && !user.loggedIn) {
      alert.error("Please enter a username and password.");
      return;
    }
    if (!contact.email && !user.loggedIn) {
      alert.error("Please enter your email.");
      return;
    }
    if (!firstName || !lastName) {
      alert.error("Please enter your name.");
      return;
    }
    if (
      !billing.address_1 ||
      !billing.city ||
      !billing.state ||
      !billing.postcode
    ) {
      alert.error("Please complete all billing fields.");
      return;
    }
    try {
      var { nonce, deviceData } = await this.instance.requestPaymentMethod();
    } catch (error) {
      alert.error("Please select a payment method.");
      return;
    }
    if (nonce && deviceData) {
      const body = {
        nonce,
        deviceData,
        billing,
        products: offer
          ? offer.product
          : cart.products.map((el) => {
              return el.id;
            }),
      };
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      if (user.loggedIn) {
        headers.append("Authorization", `Bearer ${user.token}`);
      } else {
        body.username = contact.username;
        body.password = contact.password;
      }
      document.getElementById("preloader-screen").style.display = "block";
      document.getElementById("preloader-screen").classList.remove("loaded");
      if (offer) {
        fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/offers/add", {
          method: "POST",
          body: JSON.stringify(body),
          headers,
        })
          .then((response) => response.json())
          .then((response) => {
            document.getElementById("preloader-screen").classList.add("loaded");
            document.getElementById("preloader-screen").style.display = "none";
            if (typeof response === "object") {
              if (user.loggedIn) {
                const yourOffers = [...user.yourOffers];
                const existingOffer = yourOffers.find((item) => {
                  return offer.product.id === item.id;
                });
                if (existingOffer) {
                  yourOffers[yourOffers.indexOf(existingOffer)] = response;
                } else {
                  yourOffers.unshift(response);
                }
                alert.success("Offer sent.");
                this.props.login.updateUser({
                  firstName,
                  lastName,
                  billing,
                  yourOffers,
                });
                this.setState({ redirect: "/product/" + offer.product.id });
              } else {
                if (response.statusCode === 200) {
                  alert.success("Offer sent.");
                  this.props.login.setUser(response.data);
                  this.setState({ redirect: "/profile/offers/" });
                }
              }
            } else {
              this.props.alert.error(response);
            }
          })
          .catch(() => {
            alert.error("There was an error sending your offer.");
          });
      } else {
        alert.error("There was an error creating this order.");
        return;
        fetch(
          process.env.REACT_APP_API_URL + "/wp-json/atlas/orders/checkout",
          { method: "POST", body: JSON.stringify(body), headers }
        )
          .then((response) => response.json())
          .then((response) => {
            document.getElementById("preloader-screen").classList.add("loaded");
            document.getElementById("preloader-screen").style.display = "none";
            if (typeof response === "object") {
              if (user.loggedIn && response[0].status === "Pre-shipment") {
                alert.success("Order placed.");
                this.props.login.updateUser({
                  firstName,
                  lastName,
                  billing,
                  yourOrders: response.concat(user.yourOrders),
                });
                this.setState({ redirect: "/profile/orders/" }, () => {
                  this.props.cart.setCart({
                    count: 0,
                    products: [],
                    subtotal: 0,
                  });
                });
              } else {
                if (response.statusCode === 200) {
                  alert.success("Order placed.");
                  this.props.login.setUser(response.data);
                  this.setState({ redirect: "/profile/orders/" }, () => {
                    this.props.cart.setCart({
                      count: 0,
                      products: [],
                      subtotal: 0,
                    });
                  });
                } else {
                  alert.error("There was an error creating this order.");
                }
              }
            } else {
              alert.error(response);
            }
          })
          .catch(() => {
            alert.error("There was an error creating this order.");
          });
      }
    } else {
      alert.error("Your payment method couldn't be verified.");
    }
  }

  render() {
    const offer = this.props.offer;
    const cart = this.props.cart;
    const total = offer ? offer.total : cart.cart.total;
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;
    return (
      <div>
        <div id="preloader-screen">
          <AppLogo id="preloader-svg" />
        </div>
        {this.state.clientToken ? (
          <DropIn
            clientToken={this.state.clientToken}
            options={{
              authorization: this.state.clientToken,
              card: {
                overrides: {
                  styles: {
                    input: {
                      "font-size": "15px",
                      "font-weight": "500",
                      "font-family": "system-ui",
                      "letter-spacing": "2px",
                    },
                  },
                },
              },
              googlePay: {
                merchantId: "BCR2DN6TY6TJPLZZ",
                transactionInfo: {
                  totalPriceStatus: "FINAL",
                  totalPrice: total.toFixed(2),
                  currencyCode: "USD",
                },
                allowedPaymentMethods: [
                  {
                    type: "CARD",
                  },
                ],
              },
              dataCollector: true
            }}
            onInstance={(instance) => (this.instance = instance)}
          />
        ) : null}
        <button
          style={{ marginTop: "30px", marginBottom: "20px" }}
          className="submit-button button"
          onClick={this.buy.bind(this)}
        >
          {offer ? "Send Offer" : "Place Order"}
        </button>
        <p style={{ fontSize: "14px", width: "100%", textAlign: "center" }}>
          100% money back guarantee. <Link to="/terms">Refund policy</Link>.
        </p>
        <p
          style={{
            fontSize: "14px",
            width: "100%",
            textAlign: "center",
            lineHeight: "0.6",
          }}
        >
          By purchasing, you agree to our <Link to="/terms">terms</Link>.
        </p>
      </div>
    );
  }
}
