import React from "react";
import { Link } from "react-router-dom";

export default function OfferButtons(props) {
  const { price, status } = props.offer;
  const isOwner = props.isOwner;
  let buttonTitle = "";
  if ((status === "" || status === "expired") && !isOwner)
    buttonTitle = "Send Offer";
  if (status === "" && isOwner) buttonTitle = "No Offers";
  if (status === "sent") buttonTitle = "Cancel Offer";
  let acceptOffer = Boolean(status === "received");
  const inputDisabled = Boolean((status !== "expired" && status) || isOwner);
  const buttonsDisabled = Boolean(isOwner && status !== "received");
  if(isOwner && !status) {
    return (
      <div>
        <p className="empty-cart">No offers found.</p>
      </div>
    )
  } else {
    return (
      <div style={{ padding: "20px 15px", display: "flex", flexDirection: "column" }}>
        <p
          style={{
            fontSize: "14px",
            width: "100%",
            textAlign: "center",
            marginTop: "0",
            marginBottom: "20px"
          }}
        >
          Offers are binding, but you'll only be charged if the seller accepts
          within 24 hours.
        </p>
        <input
          pattern="[0-9]*"
          type="text"
          disabled={inputDisabled}
          value={price}
          onChange={props.setOfferPrice}
          onBlur={props.setOfferPriceOnBlur}
          id="offerPrice"
          placeholder="$0.00"
        />
        {acceptOffer ? (
          <>
            <button
              disabled={buttonsDisabled}
              style={{width: "48%", float: "left", marginBottom: "0"}}
              name="accept"
              className="submit-button button"
              onClick={props.offerFunction}
            >
              Accept
            </button>
            <button
              disabled={buttonsDisabled}
              style={{width: "48%", float: "right", marginBottom: "0"}}
              name="decline"
              className="submit-button button"
              onClick={props.offerFunction}
            >
              Decline
            </button>
          </>
        ) : (
          <button
            disabled={buttonsDisabled}
            className="submit-button button"
            onClick={props.offerFunction}
          >
            {buttonTitle}
          </button>
        )}
      </div>
    )
  }
}
