import React, { useEffect, useState } from "react";
import { showModal } from "./togglemodals";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export default function Password() {
  const [displayErrors, setDisplayErrors] = useState("none");

  const [errorMessage, setErrorMessage] = useState("Welcome to Atlas");

  const [errorColor, setErrorColor] = useState("#e64c54");

  const { search } = useLocation();
  const params = search ? queryString.parse(search) : "";
  if (params) {
    var isReset = params.resetpassword;
    var key = params.key;
    var user = params.user;
  }

  useEffect(() => {
    if (isReset) {
      showModal(document.getElementById("password-modal"));
    } else {
      setDisplayErrors("none");
      setErrorColor("#e64c54");
    }
  }, [isReset]);

  const resetPassword = (e) => {
    e.preventDefault();
    const newPassword = document.getElementById("new-password").value;
    const newPasswordConfirmed = document.getElementById(
      "new-password-confirmed"
    ).value;
    if (newPassword !== newPasswordConfirmed) {
      setDisplayErrors("flex");
      setErrorMessage("Passwords don't match");
    } else {
      setDisplayErrors("flex");
      setErrorMessage("Resetting password...");
      const body = JSON.stringify({
        password: newPassword,
        key,
        user_id: user,
      });
      const headers = { "Content-Type": "application/json" };
      fetch(
        process.env.REACT_APP_API_URL + "/wp-json/atlas/users/password/reset",
        { method: "post", body, headers }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result === "Reset") {
            setErrorMessage("Reset!");
            setErrorColor("#15e89f");
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setErrorColor("#e64c54");
        });
    }
  };

  const requestNewPassword = (e) => {
    e.preventDefault();
    let usernameEmail = document.getElementById("username-email").value;
    if (!usernameEmail) {
      setDisplayErrors("flex");
      setErrorMessage("Enter a Username or Email");
    }
    if (usernameEmail) {
      setDisplayErrors("flex");
      setErrorMessage("Sending email...");
      const body = JSON.stringify({ usernameEmail });
      const headers = { "Content-Type": "application/json" };
      fetch(
        process.env.REACT_APP_API_URL + "/wp-json/atlas/users/password/forgot",
        { method: "post", body, headers }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result === "Email Sent") {
            setErrorMessage("Sent!");
            setErrorColor("#15e89f");
          } else {
            setErrorMessage(result);
            setErrorColor("#e64c54");
          }
        })
        .catch((error) => {
          setErrorMessage(error);
          setErrorColor("#e64c54");
        });
    }
  };

  if (isReset) {
    return (
      <div id="password-modal" className="sign-in-container login">
        <div className="filters-title">
          <h2 className="title">Reset Password</h2>
        </div>
        <div style={{ padding: "15px 20px 20px" }}>
          <h6 style={{ marginBottom: "20px" }}>Enter a new password below.</h6>
          <div
            style={{ display: displayErrors, background: errorColor }}
            className="sign-in-errors login"
          >
            {errorMessage}
          </div>
          <form id="password-form" onSubmit={resetPassword}>
            <input
              type="text"
              id="new-password"
              placeholder="New Password"
              className="input"
            />
            <input
              type="text"
              id="new-password-confirmed"
              placeholder="Confirm Password"
              className="input"
            />
            <button type="submit" className="submit-button button login-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div id="password-modal" className="sign-in-container login">
        <div className="filters-title">
          <h2 className="title">Forgot Password</h2>
        </div>
        <div style={{ padding: "15px 20px 20px" }}>
          <h6 style={{ marginBottom: "20px" }}>
            Enter your email or username below and we'll send you an email to
            reset your password.
          </h6>
          <div
            style={{ display: displayErrors, background: errorColor }}
            className="sign-in-errors login"
          >
            {errorMessage}
          </div>
          <form id="password-form" onSubmit={requestNewPassword}>
            <input
              type="text"
              id="username-email"
              placeholder="Username or email"
              className="input"
            />
            <button type="submit" className="submit-button button login-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}
