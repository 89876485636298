import React, { useState } from "react";
import Rating from "./rating";

export default function RateUser(props) {
  const [rating, setRating] = useState(0);

  const rateUser = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${props.token}`,
    };
    const url = props.isOwner ? "seller" : "customer";
    fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/orders/rate/" + url, {
      method: "POST",
      body: JSON.stringify({ order_id: props.order_id, rating }),
      headers,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === "Complete") {
          props.submitRating(rating);
          close();
        }
      });
  };

  const close = () => {
    document.getElementById("order-buttons").style.display = "flex";
    document.getElementById("rate-user").style.display = "none";
  };

  return (
    <div id="rate-user" className="buttons-container">
      <div className="rating">
        <Rating
          disabled={props.rating}
          rating={props.rating ? props.rating : rating}
          setRating={setRating}
        />
      </div>
      <div>
        <button onClick={close} className="bottom-left button">
          Close
        </button>
        <button
          onClick={rateUser}
          disabled={props.rating}
          className="bottom-right button"
        >
          {props.rating ? "Submitted" : "Submit"}
        </button>
      </div>
    </div>
  );
}
