import React from "react";
import Products from "../../components/products";

export default function ProfileLikes(props) {
  const inStock = props.results.filter((item) => {
    return item.stock > 0;
  });
  const sold = props.results.filter((item) => {
    return item.stock === 0;
  });

  const selectInStock = () => {
    document.getElementById("select-two").classList.remove("right");
    document.getElementById("sold-container").style.display = "none";
    document.getElementById("in-stock-container").style.display = "block";
  };

  const selectSold = () => {
    document.getElementById("select-two").classList.add("right");
    document.getElementById("sold-container").style.display = "block";
    document.getElementById("in-stock-container").style.display = "none";
  };

  return (
    <>
      <div id="select-two">
        <div onClick={selectInStock}>In Stock</div>
        <div onClick={selectSold}>Sold</div>
      </div>
      <div id="in-stock-container">
        <Products
          profile={1}
          emptyMessage={"No likes."}
          refresh={true}
          empty={inStock.length === 0}
          results={inStock}
        />
      </div>
      <div id="sold-container" style={{ display: "none" }}>
        <Products
          profile={1}
          emptyMessage={"No likes."}
          refresh={true}
          empty={sold.length === 0}
          results={sold}
        />
      </div>
    </>
  );
}
