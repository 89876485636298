export const shippingRates = {
  11: { ups: "8.77", usps: "3.78" },
  12: { ups: "8.77", usps: "3.78" },
  13: { ups: "8.77", usps: "3.78" },
  14: { ups: "8.77", usps: "3.78" },
  21: { ups: "8.77", usps: "4.59" },
  22: { ups: "8.77", usps: "4.59" },
  23: { ups: "8.77", usps: "4.59" },
  24: { ups: "8.77", usps: "4.59" },
  31: { ups: "8.77", usps: "8.67" },
  32: { ups: "8.77", usps: "8.67" },
  33: { ups: "8.77", usps: "8.67" },
  34: { ups: "8.77", usps: "8.67" },
  41: { ups: "11.18", usps: "8.67" },
  42: { ups: "11.18", usps: "9.85" },
  43: { ups: "11.18", usps: "11.52" },
  44: { ups: "11.18", usps: "11.52" },
  51: { ups: "12.46", usps: "8.67" },
  52: { ups: "12.46", usps: "9.85" },
  53: { ups: "12.46", usps: "16.21" },
  54: { ups: "12.46", usps: "16.21" },
  61: { ups: "13.36", usps: "8.67" },
  62: { ups: "13.36", usps: "9.85" },
  63: { ups: "13.36", usps: "16.50" },
  64: { ups: "13.36", usps: "18.68" },
  71: { ups: "14.10", usps: "8.67" },
  72: { ups: "14.10", usps: "9.85" },
  73: { ups: "14.10", usps: "16.50" },
  74: { ups: "14.10", usps: "19.81" },
  81: { ups: "14.36", usps: "8.67" },
  82: { ups: "14.36", usps: "9.85" },
  83: { ups: "14.36", usps: "16.50" },
  84: { ups: "14.36", usps: "19.81" },
  91: { ups: "15.06", usps: "8.67" },
  92: { ups: "15.06", usps: "9.85" },
  93: { ups: "15.06", usps: "16.50" },
  94: { ups: "15.06", usps: "19.81" },
  101: { ups: "15.56", usps: "8.67" },
  102: { ups: "15.56", usps: "9.85" },
  103: { ups: "15.56", usps: "16.50" },
  104: { ups: "15.56", usps: "19.81" },
};

export const packagingSizes = [
  { value: 1, label: "Small: parcel" },
  { value: 2, label: 'Medium: 9"x6"x3" box' },
  { value: 3, label: 'Large: 12"x9"x6" box' },
  { value: 4, label: 'X-Large: 14"x10"x6" box' },
];

export const weights = [
  { value: 1, label: "8 oz" },
  { value: 2, label: "12 oz" },
  { value: 3, label: "1 lb" },
  { value: 4, label: "2 lbs" },
  { value: 5, label: "3 lbs" },
  { value: 6, label: "4 lbs" },
  { value: 7, label: "5 lbs" },
  { value: 8, label: "6 lbs" },
  { value: 9, label: "7 lbs" },
  { value: 10, label: "8 lbs" },
];

export const brands = {
  1: { value: 1, label: "Nike" },
  2: { value: 2, label: "Converse" },
  3: { value: 3, label: "H&M" },
  4: { value: 4, label: "adidas" },
  5: { value: 5, label: "Gucci" },
  6: { value: 6, label: "Victoria's Secret" },
  7: { value: 7, label: "American Eagle" },
  8: { value: 8, label: "ZARA" },
  9: { value: 9, label: "Levi's" },
  10: { value: 10, label: "Louis Vuitton" },
  11: { value: 11, label: "CHANEL" },
  12: { value: 12, label: "Puma" },
  13: { value: 13, label: "Vans" },
  14: { value: 14, label: "Eddie Bauer" },
  15: { value: 15, label: "Burberry" },
  16: { value: 16, label: "Michael Kors" },
  17: { value: 17, label: "Palace" },
  18: { value: 18, label: "Prada" },
  19: { value: 19, label: "Dior" },
  20: { value: 20, label: "DC" },
  21: { value: 21, label: "commes des garcons" },
  22: { value: 22, label: "Lacoste" },
  23: { value: 23, label: "Ray-Ban" },
  24: { value: 24, label: "Forever 21" },
  25: { value: 25, label: "All Saints" },
  26: { value: 26, label: "Pandora" },
  27: { value: 27, label: "Hollister Co." },
  28: { value: 28, label: "Calvin Klein" },
  29: { value: 29, label: "Tommy Hilfiger" },
  30: { value: 30, label: "J. Crew" },
  31: { value: 31, label: "Dolce & Gabbana" },
  32: { value: 32, label: "Aeropostale" },
  33: { value: 33, label: "Tommy Bahama" },
  34: { value: 34, label: "Pacsun" },
  35: { value: 35, label: "Bershka" },
  36: { value: 36, label: "Old Navy" },
  37: { value: 37, label: "Tiffany & Co." },
  39: { value: 39, label: "Lancôme" },
  41: { value: 41, label: "Abercrombie & Fitch" },
  42: { value: 42, label: "Ralph Lauren" },
  43: { value: 43, label: "Under Armour" },
  44: { value: 44, label: "Gap" },
  45: { value: 45, label: "Kappa" },
  46: { value: 46, label: "Moncler" },
  47: { value: 47, label: "ARMANI" },
  48: { value: 48, label: "Hugo Boss" },
  49: { value: 49, label: "Timberland" },
  50: { value: 50, label: "HUF" },
  51: { value: 51, label: "Desigual" },
  52: { value: 52, label: "GUESS" },
  53: { value: 53, label: "Coach" },
  54: { value: 54, label: "Allbirds" },
  55: { value: 55, label: "PULL & BEAR" },
  56: { value: 56, label: "UNIQLO" },
  57: { value: 57, label: "Benneton" },
  58: { value: 58, label: "Nautica" },
  59: { value: 59, label: "Glamour" },
  60: { value: 60, label: "Kith" },
  61: { value: 61, label: "The North Face" },
  62: { value: 62, label: "Oakley" },
  63: { value: 63, label: "ASOS" },
  64: { value: 64, label: "Swatch" },
  65: { value: 65, label: "Fashion Nova" },
  66: { value: 66, label: "Versace" },
  67: { value: 67, label: "Crocs" },
  68: { value: 68, label: "Diesel" },
  69: { value: 69, label: "Air Jordan" },
  70: { value: 70, label: "Express" },
  71: { value: 71, label: "Hurley" },
  72: { value: 72, label: "Billabong" },
  73: { value: 73, label: "Topshop" },
  74: { value: 74, label: "ALDO Shoes" },
  75: { value: 75, label: "Bulgari" },
  76: { value: 76, label: "Quiksilver" },
  77: { value: 77, label: "Totto" },
  78: { value: 78, label: "Skechers" },
  79: { value: 79, label: "Volcom" },
  80: { value: 80, label: "Roxy" },
  81: { value: 81, label: "Fabletics" },
  82: { value: 82, label: "TOMS" },
  83: { value: 83, label: "bebe" },
  84: { value: 84, label: "Zappos" },
  85: { value: 85, label: "Billionaire Boy's Club" },
  86: { value: 86, label: "Swarovski" },
  87: { value: 87, label: "Carter's" },
  88: { value: 88, label: "Jimmy Choo" },
  89: { value: 89, label: "Keds" },
  90: { value: 90, label: "New Look" },
  91: { value: 91, label: "New Yorker" },
  92: { value: 92, label: "Kate Spade New York" },
  93: { value: 93, label: "Christian Louboutin" },
  94: { value: 94, label: "Alpinestars" },
  95: { value: 95, label: "Victoria Beckham" },
  96: { value: 96, label: "ASICS" },
  97: { value: 97, label: "Surfer Girl" },
  98: { value: 98, label: "TAG Heuer" },
  99: { value: 99, label: "UGG" },
  100: { value: 100, label: "Supra Footwear" },
  101: { value: 101, label: "Hanes" },
  102: { value: 102, label: "Element" },
  103: { value: 103, label: "Fossil" },
  104: { value: 104, label: "Life is Good" },
  105: { value: 105, label: "Carhartt" },
  106: { value: 106, label: "Yves Saint Laurent" },
  107: { value: 107, label: "Valentino" },
  108: { value: 108, label: "Givenchy" },
  109: { value: 109, label: "Carolina Herrera" },
  110: { value: 110, label: "Off-White" },
  111: { value: 111, label: "Wrangler" },
  112: { value: 112, label: "RVCA" },
  113: { value: 113, label: "Fendi" },
  114: { value: 114, label: "Pepe Jeans" },
  115: { value: 115, label: "Juicy Couture" },
  116: { value: 116, label: "Reserved" },
  117: { value: 117, label: "Roberto Cavalli" },
  118: { value: 118, label: "G-Star Raw" },
  119: { value: 119, label: "Banana Republic" },
  120: { value: 120, label: "Anti Social Social Club" },
  121: { value: 121, label: "Penguin" },
  122: { value: 122, label: "Clarks" },
  123: { value: 123, label: "Sperry Top-Sider" },
  124: { value: 124, label: "Urban Outfitters" },
  125: { value: 125, label: "Wet Seal" },
  126: { value: 126, label: "The Children's Place" },
  127: { value: 127, label: "Baby Phat" },
  128: { value: 128, label: "Osiris" },
  129: { value: 129, label: "Rip Curl" },
  131: { value: 131, label: "Metal Mulisha" },
  132: { value: 132, label: "Supreme" },
  133: { value: 133, label: "Schutz" },
  134: { value: 134, label: "Charlotte Russe" },
  135: { value: 135, label: "Marc Jacobs Intl" },
  136: { value: 136, label: "Tory Burch" },
  137: { value: 137, label: "Alexander McQueen" },
  138: { value: 138, label: "Andrew Christian" },
  139: { value: 139, label: "Next" },
  140: { value: 140, label: "lululemon athletica" },
  141: { value: 141, label: "Elie Saab" },
  142: { value: 142, label: "maurices" },
  143: { value: 143, label: "Petite Jolie" },
  144: { value: 144, label: "A|X Armani Exchange" },
  145: { value: 145, label: "Merrell" },
  146: { value: 146, label: "DKNY" },
  147: { value: 147, label: "ECCO Shoes" },
  148: { value: 148, label: "Mossimo" },
  149: { value: 149, label: "LOFT" },
  150: { value: 150, label: "Kolosh" },
  151: { value: 151, label: "Candie's" },
  152: { value: 152, label: "Cropp" },
  153: { value: 153, label: "Dr. Martens" },
  154: { value: 154, label: "Vera Bradley" },
  155: { value: 155, label: "New Balance" },
  156: { value: 156, label: "Fruit of the Loom" },
  157: { value: 157, label: "Reef" },
  158: { value: 158, label: "Salvatore Ferragamo" },
  159: { value: 159, label: "American Apparel" },
  160: { value: 160, label: "Gildan" },
  161: { value: 161, label: "GRLFRND" },
  162: { value: 162, label: "Esprit" },
  163: { value: 163, label: "GANT" },
  164: { value: 164, label: "Mulberry" },
  165: { value: 165, label: "Lee Jeans" },
  166: { value: 166, label: "Mavi" },
  167: { value: 167, label: "acacia swimwear" },
  168: { value: 168, label: "etnies" },
  169: { value: 169, label: "Free People" },
  170: { value: 170, label: "JanSport" },
  171: { value: 171, label: "Famous Footwear" },
  172: { value: 172, label: "Vince Camuto" },
  173: { value: 173, label: "7 for All Mankind" },
  174: { value: 174, label: "COOGI" },
  175: { value: 175, label: "OBEY Clothing" },
  176: { value: 176, label: "Fred Perry" },
  177: { value: 177, label: "DGK" },
  178: { value: 178, label: "Miss Me" },
  179: { value: 179, label: "REI" },
  180: { value: 180, label: "rue21" },
  181: { value: 181, label: "Colcci Oficial" },
  182: { value: 182, label: "Betsey Johnson" },
  183: { value: 183, label: "Paul Frank" },
  184: { value: 184, label: "TOBI" },
  185: { value: 185, label: "Nine West" },
  186: { value: 186, label: "Columbia Sportswear" },
  187: { value: 187, label: "Basics Life" },
  188: { value: 188, label: "Geox" },
  189: { value: 189, label: "True Religion" },
  190: { value: 190, label: "Dockers" },
  191: { value: 191, label: "Bombas" },
  192: { value: 192, label: "Eloquii" },
  193: { value: 193, label: "Zumiez" },
  194: { value: 194, label: "Torrid" },
  195: { value: 195, label: "Lands' End" },
  196: { value: 196, label: "Sherri Hill" },
  197: { value: 197, label: "Anthropologie" },
  198: { value: 198, label: "BHLDN" },
  199: { value: 199, label: "BAPE" },
  200: { value: 200, label: "Perry Ellis" },
  201: { value: 201, label: "Payless" },
  202: { value: 202, label: "BALENCIAGA" },
  203: { value: 203, label: "Lilly Pulitzer" },
  204: { value: 204, label: "Dickies" },
  205: { value: 205, label: "Miu Miu" },
  206: { value: 206, label: "Young & Reckless" },
  207: { value: 207, label: "Steve Madden" },
  208: { value: 208, label: "Ritani" },
  209: { value: 209, label: "Ann Taylor" },
  210: { value: 210, label: "Eastpak" },
  211: { value: 211, label: "La Senza" },
  212: { value: 212, label: "AND1 BASKETBALL" },
  213: { value: 213, label: "Birkenstock" },
  214: { value: 214, label: "Tod's" },
  215: { value: 215, label: "O'Neill" },
  216: { value: 216, label: "Topman" },
  217: { value: 217, label: "Vera Wang" },
  218: { value: 218, label: "Brooks Brothers" },
  219: { value: 219, label: "Santoni" },
  220: { value: 220, label: "Vivienne Westwood" },
  221: { value: 221, label: "Santa Cruz" },
  222: { value: 222, label: "Patagonia" },
  223: { value: 223, label: "Eileen Fisher" },
  224: { value: 224, label: "Saks Fifth Avenue" },
  225: { value: 225, label: "White House Black Market" },
  226: { value: 226, label: "Neiman Marcus" },
  227: { value: 227, label: "Pendleton" },
  228: { value: 228, label: "Ellesse" },
  229: { value: 229, label: "New York & Company" },
  230: { value: 230, label: "Mother" },
  231: { value: 231, label: "Superdry." },
  232: { value: 232, label: "DVF" },
  233: { value: 233, label: "Sezane" },
  234: { value: 234, label: "Chico" },
  235: { value: 235, label: "Bonds" },
  236: { value: 236, label: "MOSCHINO" },
  237: { value: 237, label: "Threadless" },
  238: { value: 238, label: "Stella McCartney" },
  239: { value: 239, label: "Champion" },
  240: { value: 240, label: "Vineyard Vines" },
  241: { value: 241, label: "Gymshark" },
  242: { value: 242, label: "L.L. Bean" },
  243: { value: 243, label: "KENZO" },
  244: { value: 244, label: "Replay" },
  245: { value: 245, label: "Costa Del Mar" },
  246: { value: 246, label: "Stussy" },
  247: { value: 247, label: "Paul Smith" },
  248: { value: 248, label: "Ecko Unltd." },
  249: { value: 249, label: "FURLA" },
  250: { value: 250, label: "Marciano" },
  251: { value: 251, label: "Jack Wills" },
  252: { value: 252, label: "James Perse" },
  253: { value: 253, label: "ALEXANDER WANG" },
  254: { value: 254, label: "SPANX" },
  255: { value: 255, label: "Blundstone" },
  256: { value: 256, label: "Sole Society" },
  257: { value: 257, label: "Cotton On" },
  258: { value: 258, label: "Kenneth Cole" },
  259: { value: 259, label: "Marmot" },
  260: { value: 260, label: "Massimo Dutti" },
  261: { value: 261, label: "Justin Boots" },
  262: { value: 262, label: "Generation" },
  263: { value: 263, label: "rag & bone" },
  264: { value: 264, label: "Bensimon" },
  265: { value: 265, label: "Athleta" },
  266: { value: 266, label: "Kendra Scott" },
  267: { value: 267, label: "Nasty Gal" },
  268: { value: 268, label: "DSQUARED2" },
  269: { value: 269, label: "Justice" },
  270: { value: 270, label: "Rockport" },
  271: { value: 271, label: "Canada Goose" },
  272: { value: 272, label: "French Connection" },
  273: { value: 273, label: "Escada" },
  274: { value: 274, label: "Lanvin" },
  275: { value: 275, label: "Disney" },
  276: { value: 276, label: "Tibi" },
  277: { value: 277, label: "FUBU" },
  278: { value: 278, label: "Ariat" },
  279: { value: 279, label: "Vetements" },
  280: { value: 280, label: "Madewell" },
  281: { value: 281, label: "XOXO" },
  282: { value: 282, label: "Onitsuka Tiger" },
  283: { value: 283, label: "Saucony" },
  284: { value: 284, label: "Izod" },
  285: { value: 285, label: "Cavender's" },
  286: { value: 286, label: "Creative Recreation" },
  287: { value: 287, label: "Hot Topic" },
  288: { value: 288, label: "Arizona Jean Co." },
  289: { value: 289, label: "CamelBak" },
  290: { value: 290, label: "Reebok" },
  291: { value: 291, label: "Soma" },
  292: { value: 292, label: "Tilly's" },
  293: { value: 293, label: "Dooney & Bourke" },
  294: { value: 294, label: "Acne Studios" },
  295: { value: 295, label: "J Brand" },
  296: { value: 296, label: "Undercover" },
  297: { value: 297, label: "Van Heusen" },
  298: { value: 298, label: "ZOO YORK" },
  299: { value: 299, label: "T.J. Maxx" },
  300: { value: 300, label: "Goyard" },
  301: { value: 301, label: "For Love & Lemons" },
  302: { value: 302, label: "Fjallraven" },
  303: { value: 303, label: "Johnny Was" },
  304: { value: 304, label: "Mark Jacobs" },
  305: { value: 305, label: "Rothy's" },
  306: { value: 306, label: "Reformation" },
  307: { value: 307, label: "Golden Goose" },
  308: { value: 308, label: "Tieks" },
};

export const conditions = {
  1: { value: 1, label: "New" },
  2: { value: 2, label: "Like New" },
  3: { value: 3, label: "Good" },
  4: { value: 4, label: "Used" },
  5: { value: 5, label: "Poor" },
};

export const sizes = {
  1: { value: 1, label: "XXS" },
  2: { value: 2, label: "XS" },
  3: { value: 3, label: "S" },
  4: { value: 4, label: "M" },
  5: { value: 5, label: "L" },
  6: { value: 6, label: "XL" },
  7: { value: 7, label: "2XL" },
  8: { value: 8, label: "3XL" },
  9: { value: 9, label: "00" },
  10: { value: 10, label: "0" },
  11: { value: 11, label: "2" },
  12: { value: 12, label: "4" },
  13: { value: 13, label: "4.5" },
  14: { value: 14, label: "5" },
  15: { value: 15, label: "5.5" },
  16: { value: 16, label: "6" },
  17: { value: 17, label: "6.5" },
  18: { value: 18, label: "7" },
  19: { value: 19, label: "7.5" },
  20: { value: 20, label: "8" },
  21: { value: 21, label: "8.5" },
  22: { value: 22, label: "9" },
  23: { value: 23, label: "9.5" },
  24: { value: 24, label: "10" },
  25: { value: 25, label: "10.5" },
  26: { value: 26, label: "11" },
  27: { value: 27, label: "11.5" },
  28: { value: 28, label: "12" },
  29: { value: 29, label: "12.5" },
  30: { value: 30, label: "13" },
  31: { value: 31, label: "13.5" },
  32: { value: 32, label: "14" },
  33: { value: 33, label: "14.5" },
  34: { value: 34, label: "15" },
  35: { value: 35, label: "16" },
  36: { value: 36, label: "18" },
  37: { value: 37, label: "20" },
  38: { value: 38, label: "21" },
  39: { value: 39, label: "22" },
  40: { value: 40, label: "23" },
  41: { value: 41, label: "24" },
  42: { value: 42, label: "25" },
  43: { value: 43, label: "26" },
  44: { value: 44, label: "27" },
  45: { value: 45, label: "28" },
  46: { value: 46, label: "29" },
  47: { value: 47, label: "30" },
  48: { value: 48, label: "31" },
  49: { value: 49, label: "32" },
  50: { value: 50, label: "33" },
  51: { value: 51, label: "34" },
  52: { value: 52, label: "35" },
  53: { value: 53, label: "36" },
  54: { value: 54, label: "37" },
  55: { value: 55, label: "38" },
  56: { value: 56, label: "39" },
  57: { value: 57, label: "40" },
  58: { value: 58, label: "41" },
  59: { value: 59, label: "42" },
  60: { value: 60, label: "43" },
  61: { value: 61, label: "44" },
  62: { value: 62, label: "46" },
  63: { value: 63, label: "34S" },
  64: { value: 64, label: "34R" },
  65: { value: 65, label: "34L" },
  66: { value: 66, label: "35S" },
  67: { value: 67, label: "35R" },
  68: { value: 68, label: "35L" },
  69: { value: 69, label: "36S" },
  70: { value: 70, label: "36R" },
  71: { value: 71, label: "36L" },
  72: { value: 72, label: "37S" },
  73: { value: 73, label: "37R" },
  74: { value: 74, label: "37L" },
  75: { value: 75, label: "38S" },
  76: { value: 76, label: "38R" },
  77: { value: 77, label: "38L" },
  78: { value: 78, label: "39S" },
  79: { value: 79, label: "39R" },
  80: { value: 80, label: "39L" },
  81: { value: 81, label: "40S" },
  82: { value: 82, label: "40R" },
  83: { value: 83, label: "40L" },
  84: { value: 84, label: "41S" },
  85: { value: 85, label: "41R" },
  86: { value: 86, label: "41L" },
  87: { value: 87, label: "42S" },
  88: { value: 88, label: "42R" },
  89: { value: 89, label: "42L" },
  90: { value: 90, label: "43S" },
  91: { value: 91, label: "43R" },
  92: { value: 92, label: "43L" },
  93: { value: 93, label: "44S" },
  94: { value: 94, label: "44R" },
  95: { value: 95, label: "44L" },
  96: { value: 96, label: "45S" },
  97: { value: 97, label: "45R" },
  98: { value: 98, label: "45L" },
  99: { value: 99, label: "46S" },
  100: { value: 100, label: "46R" },
  101: { value: 101, label: "46L" },
  102: { value: 102, label: "47S" },
  103: { value: 103, label: "47R" },
  104: { value: 104, label: "47L" },
  105: { value: 105, label: "48S" },
  106: { value: 106, label: "48R" },
  107: { value: 107, label: "48L" },
  108: { value: 108, label: "49S" },
  109: { value: 109, label: "49R" },
  110: { value: 110, label: "49L" },
  111: { value: 111, label: "50S" },
  112: { value: 112, label: "50R" },
  113: { value: 113, label: "50L" },
  114: { value: 114, label: "51S" },
  115: { value: 115, label: "51R" },
  116: { value: 116, label: "51L" },
  117: { value: 117, label: "52S" },
  118: { value: 118, label: "52R" },
  119: { value: 119, label: "52L" },
  120: { value: 120, label: "53S" },
  121: { value: 121, label: "53R" },
  122: { value: 122, label: "53L" },
  123: { value: 123, label: "54S" },
  124: { value: 124, label: "54R" },
  125: { value: 125, label: "54L" },
  126: { value: 126, label: "55S" },
  127: { value: 127, label: "55R" },
  128: { value: 128, label: "55L" },
};

export const states = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District Of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const customStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),

  clearIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),

  control: (provided, state) => ({
    ...provided,
    ":hover": {
      borderColor: "#2b87df",
    },
    cursor: "text",
    borderColor: state.isFocused ? "#2b87df" : "#e1e1e1",
  }),

  menuList: (provided) => ({
    ...provided,
    padding: "0"
  }),

  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "white",
    border: "1px solid",
    borderRadius: "4px",
    opacity: 0.6,
    padding: "2px 5px",
    paddingRight: 0,
    cursor: "pointer",
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    color: "black",
    padding: 0,
    paddingLeft: 0,
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    ":hover": {
      background: "none",
      color: "black",
    },
    paddingLeft: 0,
    paddingRight: "2px",
  }),
};

export const orderByOptions = [
  { value: "1", label: "Price: Low to High" },
  { value: "2", label: "Price: High to Low" },
  { value: "3", label: "Most Liked" },
];

export const menscategories = [
  86, 93, 214, 94, 97, 90, 89, 92, 201, 95, 96, 88, 131, 135, 136, 213, 134,
  137, 130, 132, 133, 216, 143, 138, 139, 140, 141, 142, 115, 118, 116, 114,
  121, 117, 122, 111, 109, 110, 112, 113, 108, 128, 124, 126, 127, 125, 146,
  145, 144, 103, 102, 107, 99, 119, 105, 101, 106, 98, 100, 104,
];

export const womenscategories = [
  147, 158, 148, 155, 154, 156, 151, 150, 153, 198, 157, 159, 149, 152, 160,
  194, 192, 191, 193, 190, 164, 166, 161, 165, 162, 167, 163, 168, 203, 204,
  202, 206, 207, 209, 208, 211, 210, 212, 205, 188, 189, 187, 185, 186, 183,
  179, 181, 184, 180, 182, 176, 174, 173, 177, 178, 170, 175, 171, 172, 199,
  200, 196, 195, 197, 169,
];

export const categories = {
  15: { select: { value: 15, label: "Other" }, children: !1 },
  86: {
    select: { value: 86, label: "Men's" },
    children: [88, 90, 92, 89, 93, 94, 214, 97, 201, 95, 96],
  },
  88: {
    children: [103, 102, 107, 99, 119, 105, 101, 106, 98, 100, 104],
    select: { value: 88, label: "Tops" },
  },
  89: {
    children: [111, 109, 110, 112, 113, 108],
    select: { value: 89, label: "Shoes" },
  },
  90: {
    children: [115, 118, 116, 114, 121, 117, 122],
    select: { value: 90, label: "Pants" },
  },
  92: {
    children: [128, 124, 126, 127, 125],
    select: { value: 92, label: "Shorts" },
  },
  93: {
    select: { value: 93, label: "Accessories" },
    children: [131, 135, 136, 213, 134, 137, 130, 132, 133],
  },
  94: {
    children: [143, 138, 139, 140, 141, 142],
    select: { value: 94, label: "Coats & Jackets" },
  },
  95: { select: { value: 95, label: "Suits" }, children: !1 },
  96: { children: [146, 145, 144], select: { value: 96, label: "Swimwear" } },
  97: { select: { value: 97, label: "Pajamas" }, children: !1 },
  98: { select: { value: 98, label: "T-Shirts" }, children: !1 },
  99: { select: { value: 99, label: "Henley" }, children: !1 },
  100: { select: { value: 100, label: "Tanks" }, children: !1 },
  101: { select: { value: 101, label: "Polos" }, children: !1 },
  102: { select: { value: 102, label: "Dress Shirts" }, children: !1 },
  103: { select: { value: 103, label: "Button Downs" }, children: !1 },
  104: { select: { value: 104, label: "Turtlenecks" }, children: !1 },
  105: { select: { value: 105, label: "Jerseys" }, children: !1 },
  106: { select: { value: 106, label: "Sweaters" }, children: !1 },
  107: { select: { value: 107, label: "Flannels" }, children: !1 },
  108: { select: { value: 108, label: "Sneakers" }, children: !1 },
  109: { select: { value: 109, label: "Boots" }, children: !1 },
  110: { select: { value: 110, label: "Dress Shoes" }, children: !1 },
  111: { select: { value: 111, label: "Boat Shoes" }, children: !1 },
  112: { select: { value: 112, label: "Sandals" }, children: !1 },
  113: { select: { value: 113, label: "Slippers" }, children: !1 },
  114: { select: { value: 114, label: "Jeans" }, children: !1 },
  115: { select: { value: 115, label: "Cargo Pants" }, children: !1 },
  116: { select: { value: 116, label: "Dress Pants" }, children: !1 },
  117: { select: { value: 117, label: "Khakis" }, children: !1 },
  118: { select: { value: 118, label: "Corduroys" }, children: !1 },
  119: { select: { value: 119, label: "Hoodies" }, children: !1 },
  121: { select: { value: 121, label: "Joggers" }, children: !1 },
  122: { select: { value: 122, label: "Sweatpants" }, children: !1 },
  124: { select: { value: 124, label: "Cargo Shorts" }, children: !1 },
  125: { select: { value: 125, label: "Surf Shorts" }, children: !1 },
  126: { select: { value: 126, label: "Dress Shorts" }, children: !1 },
  127: { select: { value: 127, label: "Khaki Shorts" }, children: !1 },
  128: { select: { value: 128, label: "Athletic Shorts" }, children: !1 },
  130: { select: { value: 130, label: "Sunglasses" }, children: !1 },
  131: { select: { value: 131, label: "Belts" }, children: !1 },
  132: { select: { value: 132, label: "Wallets" }, children: !1 },
  133: { select: { value: 133, label: "Watches" }, children: !1 },
  134: { select: { value: 134, label: "Rings" }, children: !1 },
  135: { select: { value: 135, label: "Earrings" }, children: !1 },
  136: { select: { value: 136, label: "Hats" }, children: !1 },
  137: { select: { value: 137, label: "Scarves" }, children: !1 },
  138: { select: { value: 138, label: "Fleece" }, children: !1 },
  139: { select: { value: 139, label: "Parka" }, children: !1 },
  140: { select: { value: 140, label: "Poncho" }, children: !1 },
  141: { select: { value: 141, label: "Trench" }, children: !1 },
  142: { select: { value: 142, label: "Windbreakers" }, children: !1 },
  143: { select: { value: 143, label: "Blazers" }, children: !1 },
  144: { select: { value: 144, label: "Swim Tops" }, children: !1 },
  145: { select: { value: 145, label: "Swim Shorts" }, children: !1 },
  146: { select: { value: 146, label: "Speedos" }, children: !1 },
  147: {
    children: [148, 149, 151, 153, 150, 158, 155, 154, 156, 198, 157, 159, 160],
    select: { value: 147, label: "Women's" },
  },
  148: {
    children: [164, 166, 161, 165, 162, 167, 163, 168],
    select: { value: 148, label: "Tops" },
  },
  149: { select: { value: 149, label: "Dresses" }, children: [169] },
  150: {
    children: [176, 174, 173, 177, 178, 170, 175, 171, 172],
    select: { value: 150, label: "Shoes" },
  },
  151: {
    children: [183, 179, 181, 184, 180, 182],
    select: { value: 151, label: "Pants" },
  },
  152: { select: { value: 152, label: "Jeans" }, children: !1 },
  153: { select: { value: 153, label: "Shorts & Skirts" }, children: !1 },
  154: {
    select: { value: 154, label: "Athletic Apparel" },
    children: [188, 189, 187, 185, 186],
  },
  155: {
    children: [203, 204, 202, 206, 207, 209, 208, 211, 210, 212, 205],
    select: { value: 155, label: "Accessories" },
  },
  156: { select: { value: 156, label: "Coats & Jackets" }, children: !1 },
  157: { select: { value: 157, label: "Suits & Blazers" }, children: !1 },
  158: {
    select: { value: 158, label: "Maternity" },
    children: [194, 192, 191, 193, 190],
  },
  159: { children: [196, 195, 197], select: { value: 159, label: "Swimwear" } },
  160: { select: { value: 160, label: "Pajamas" }, children: !1 },
  161: { select: { value: 161, label: "Blouses" }, children: !1 },
  162: { select: { value: 162, label: "Polos" }, children: !1 },
  163: { select: { value: 163, label: "T-Shirts" }, children: !1 },
  164: { select: { value: 164, label: "Tanks & Camis" }, children: !1 },
  165: { select: { value: 165, label: "Button Downs" }, children: !1 },
  166: { select: { value: 166, label: "Turtlenecks" }, children: !1 },
  167: { select: { value: 167, label: "Sweaters" }, children: !1 },
  168: { select: { value: 168, label: "Flannels" }, children: !1 },
  169: { select: { value: 169, label: "Jumpsuits & Rompers" }, children: !1 },
  170: { select: { value: 170, label: "Sneakers" }, children: !1 },
  171: { select: { value: 171, label: "Flats" }, children: !1 },
  172: { select: { value: 172, label: "Heels" }, children: !1 },
  173: { select: { value: 173, label: "Pumps" }, children: !1 },
  174: { select: { value: 174, label: "Boots" }, children: !1 },
  175: { select: { value: 175, label: "Dress Shoes" }, children: !1 },
  176: { select: { value: 176, label: "Boat Shoes" }, children: !1 },
  177: { select: { value: 177, label: "Sandals" }, children: !1 },
  178: { select: { value: 178, label: "Slippers" }, children: !1 },
  179: { select: { value: 179, label: "Corduroys" }, children: !1 },
  180: { select: { value: 180, label: "Khakis" }, children: !1 },
  181: { select: { value: 181, label: "Dress Pants" }, children: !1 },
  182: { select: { value: 182, label: "Linen Pants" }, children: !1 },
  183: { select: { value: 183, label: "Cargo Pants" }, children: !1 },
  184: { select: { value: 184, label: "Jeans" }, children: !1 },
  185: { select: { value: 185, label: "Sweatshirts" }, children: !1 },
  186: { select: { value: 186, label: "Leggings" }, children: !1 },
  187: { select: { value: 187, label: "Sweatpants" }, children: !1 },
  188: { select: { value: 188, label: "Athletic Shorts" }, children: !1 },
  189: { select: { value: 189, label: "Sportsbras" }, children: !1 },
  190: { select: { value: 190, label: "Maternity Tops" }, children: !1 },
  191: { select: { value: 191, label: "Maternity Pants" }, children: !1 },
  192: { select: { value: 192, label: "Maternity Dresses" }, children: !1 },
  193: {
    select: { value: 193, label: "Maternity Shorts & Skirts" },
    children: !1,
  },
  194: {
    select: { value: 194, label: "Maternity Athletic Apparel" },
    children: !1,
  },
  195: { select: { value: 195, label: "Swim Tops" }, children: !1 },
  196: { select: { value: 196, label: "Two-Piece" }, children: !1 },
  197: { select: { value: 197, label: "One-Piece" }, children: !1 },
  198: {
    children: [199, 200],
    select: { value: 198, label: "Socks & Underwear" },
  },
  199: { select: { value: 199, label: "Bras" }, children: !1 },
  200: { select: { value: 200, label: "Shapewear" }, children: !1 },
  201: { select: { value: 201, label: "Socks & Underwear" }, children: !1 },
  202: { select: { value: 202, label: "Belts" }, children: !1 },
  203: { select: { value: 203, label: "Wallets" }, children: !1 },
  204: { select: { value: 204, label: "Watches" }, children: !1 },
  205: { select: { value: 205, label: "Necklaces" }, children: !1 },
  206: { select: { value: 206, label: "Bracelets" }, children: !1 },
  207: { select: { value: 207, label: "Rings" }, children: !1 },
  208: { select: { value: 208, label: "Earrings" }, children: !1 },
  209: { select: { value: 209, label: "Sunglasses" }, children: !1 },
  210: { select: { value: 210, label: "Hats" }, children: !1 },
  211: { select: { value: 211, label: "Handbags" }, children: !1 },
  212: { select: { value: 212, label: "Masks" }, children: !1 },
  213: { select: { value: 213, label: "Masks" }, children: !1 },
  214: { select: { value: 214, label: "Athletic Apparel" }, children: [216] },
  216: { select: { value: 216, label: "Jerseys" }, children: !1 },
};

export const faqs = {
  "How do I start selling?":
    "After registering, click on the add product page in your profile.",
  "Are there any fees?":
    "For sellers, Atlas is 100% free. For buyers, there is a 2.9% + $0.55 PayPal fee because we use PayPal as our payment processor.",
  "When do I get paid?":
    "The customer has 24 hours to review the product and rate the transaction. After that, the order is marked complete and you'll be sent payment within a couple hours.",
  "How do I get paid?":
    "We pay you directly through PayPal or Venmo. You can choose your payment method and account on your profile's settings page.",
  "How do I make an offer?":
    "Simply type in the price of your offer and press 'Make Offer' on the product page. All offers are binding, so if the seller accepts, you'll be charged. Offers expire after 24 hours.",
  "How do I accept an offer?":
    "When you've received an offer, it will show up on your profile's customer offers page. Click on either the product or the offer, and press accept.",
  "How do ratings work?":
    "After each transaction, the seller is rated. This is done either by the customer or automatically after 24 hours. If the customer doesn't submit a rating, the seller will be rated 5 stars. We encourage sellers to delete products they don't want sold.",
  "How do I change my shipping address?":
    "Addresses can be changed on your profile's settings page under 'addresses'.",
  "How do I change my billing address?":
    "Addresses can be changed on your profile's settings page under 'addresses'.",
  "How do I change my name?":
    "You can change your name on your profile's settings page under 'info'.",
  "How do I change my password?":
    "You can change your password on your profile's settings page under 'info'.",
  "How does shipping work?":
    "When you create a product, you can specify its weight and dimensions. You can also choose which carrier you'd like to ship with: UPS or USPS. After the product is sold, we'll send you a prepaid shipping label that you attach to your item and drop off at the nearest carrier location. You can also find your shipping label on the order page.",
  "Can I ship multiple orders with one label?":
    "Please only use the label for the order that it's assigned to. The shipping labels are calculated according to each item's weight and dimensions.",
  "Can I cancel orders?":
    "As a seller, if the order hasn't shipped, you can cancel your order. As a buyer, you can request the seller to cancel your order, but it will only be cancelled if they approve. Refunds will be processed once the customer's transaction has settled. Settlement can take up to a day. If the order isn't shipped within 72 hours, it'll be cancelled automatically.",
  "How do I request a refund?":
    "If the product isn't as described or it was damaged during shipping, you can submit a refund request on the order page after your product has been delivered. We'll contact the seller, and if we approve your refund, we'll send you a return label and full refund for the product within 1-2 business days.",
  "When will my order be delivered?":
    "You can check the eta of your order on the order page.",
  "Why is there an error with my address?":
    "We verify all addresses before saving them to make sure they exist. Please check to make sure there aren't any unnecessary abbreviations in the address you entered. If the error persists, please contact us and we'll gladly help you out.",
  "How do I change my username?":
    "Usernames and email can't be changed for privacy reasons unless you register for a new account.",
  "How do I change my email?":
    "Emails and usernames can't be changed for privacy reasons unless you register for a new account.",
  "How do I choose my product's packaging size?":
    "Most products, like jewelry, belts, shirts, athletic shorts, or jackets, are less than 1lb and can fit into a small package. You can ship these items in parcel envelopes or small boxes. For heavier items, like shoes, coats, or jeans, please measure your item and choose a box that fits its dimensions.",
  "Which carrier is cheaper?":
    "USPS is generally cheaper for smaller items, while UPS is cheaper for larger and heavier items.",
  "How do I ship?":
    "After you receive an order, you have 72 hours to ship. Make sure you use the correct packaging. If your product is small and lightweight (under 1lb), you can use an envelope, but otherwise, use a box. For USPS Priority Mail (products heavier than lb), you can get free boxes from USPS that you can pick up or order online. These must be Priority Mail boxes, however, Express Mail boxes aren't allowed. Any regular box that matches your item's packaging size works too! After your item is packaged, securely attach the shipping label we give you to the package and drop it off at your nearest carrier location.",
  "How do I check if my product is Priority Mail or First Class?":
    "If your product is less than 1lb and shipped with USPS, it's generally First Class. First Class shipping is our cheapest option (around $3 to $4). If your product is heavier than 1lb and shipped with USPS, it's Priority Mail and can be shipped with a free USPS Priority Mail box. You can also check whether your package is First Class or not on the order page.",
  "How do I change my rating?":
    "If you believe there's an error with one of your ratings, you can contact us and we'll change the rating for you.",
};
