import React from "react";
import Payment from "../components/payment";
import { states } from "../data";
import CheckoutCart from "../components/checkoutcart";
import Addresses from "../components/addresses";
import { getFloatVal } from "../functions";

export default class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: { firstName: "", lastName: "", username: "", password: "" },
      billing: {
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        postcode: "",
      },
    };
    this.setBilling = this.setBilling.bind(this);
    this.setBillingState = this.setBillingState.bind(this);
    this.setContact = this.setContact.bind(this);
  }

  componentDidMount() {
    if (this.props.cart) {
      const cart = this.props.cart.cart;
      const products = [...cart.products];
      const ids = products.map((item) => {
        return item.id;
      });
      fetch(
        process.env.REACT_APP_API_URL +
          "/wp-content/plugins/products-api/query.php?include=" +
          ids.join(",")
      )
        .then((response) => response.json())
        .then((results) => {
          let subtotal = 0;
          results = results.filter((item) => {
            return item.stock > 0;
          });
          results.forEach((item) => {
            subtotal += getFloatVal(item.price);
          });
          let paypalFee = subtotal * 0.02987 + 0.566;
          let total = subtotal + paypalFee;
          results.sort((a, b) => {
            return ids.indexOf(a.id) - ids.indexOf(b.id);
          });
          this.props.cart.setCart({
            ...cart,
            products: results,
            count: results.length,
            subtotal,
            paypalFee,
            total,
          });
        });
    }
    if (this.props.login.user.loggedIn) this.setUserData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.login.user.loggedIn !== prevProps.login.user.loggedIn)
      this.setUserData();
  }

  setUserData = () => {
    const user = this.props.login.user;
    this.setState({
      contact: user.loggedIn
        ? { firstName: user.firstName, lastName: user.lastName }
        : { firstName: "", lastName: "", username: "", password: "" },
      billing:
        user.loggedIn && user.billing
          ? user.billing
          : { address_1: "", address_2: "", city: "", state: "", postcode: "" },
    });
  };

  setContact(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ contact: { ...this.state.contact, [name]: value } });
  }

  setBilling(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "state") {
      let stateValue = states.find((item) => {
        if (item.label === value || item.value === value) return item;
      });
      stateValue ? (stateValue = stateValue.value) : (stateValue = "");
      this.setState((state) => ({
        billing: { ...state.billing, state: stateValue },
      }));
    } else {
      this.setState((state) => ({
        billing: { ...state.billing, [name]: value },
      }));
    }
  }

  setBillingState(target) {
    target
      ? this.setState((state) => ({
          billing: { ...state.billing, state: target.value },
        }))
      : this.setState((state) => ({
          billing: { ...state.billing, state: "" },
        }));
  }

  render() {
    const title = this.props.location.offer ? "Send Offer" : "Checkout";
    const { contact, billing } = this.state;
    Object.assign(billing, {
      setBilling: this.setBilling,
      setBillingState: this.setBillingState,
    });
    return (
      <>
        <h1
          style={{ width: "100%", textAlign: "center", marginBottom: "10px" }}
        >
          {title}
        </h1>
        <form id="checkout">
          <div className="left-container">
            {!this.props.login.user.loggedIn ? (
              <>
                <label>Register:</label>
                <div id="register">
                  <div className="left-container">
                    <label>Username:</label>
                    <input
                      autoComplete="chrome-off"
                      type="text"
                      name="username"
                      value={contact.username}
                      onChange={this.setContact}
                    />
                  </div>
                  <div className="right-container">
                    <label>Password:</label>
                    <input
                      autoComplete="new-password"
                      type="password"
                      name="password"
                      value={contact.password}
                      onChange={this.setContact}
                    />
                  </div>
                  <div style={{ clear: "left" }}>
                    <label>Email:</label>
                    <input
                      type="text"
                      name="email"
                      value={contact.email}
                      onChange={this.setContact}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <label>Contact:</label>
            <div id="contact">
              <div className="left-container">
                <div>
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={contact.firstName}
                    onChange={this.setContact}
                  />
                </div>
              </div>
              <div className="right-container">
                <div>
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={contact.lastName}
                    onChange={this.setContact}
                  />
                </div>
              </div>
            </div>
            <Addresses billing={billing} />
          </div>
          <div className="right-container">
            <label>{this.props.location.offer ? "Offer:" : "Order:"}</label>
            <CheckoutCart
              offer={this.props.location.offer}
              cart={this.props.cart}
              checkout={true}
            />
            <label>Payment:</label>
            <Payment
              alert={this.props.alert}
              login={this.props.login}
              offer={this.props.location.offer}
              cart={this.props.cart}
              contact={this.state.contact}
              billing={this.state.billing}
            />
          </div>
        </form>
      </>
    );
  }
}
