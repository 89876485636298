import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { customStyles } from "../data";
import { useHistory } from "react-router-dom";

export default function Returns(props) {
  const issues = [
    { value: 1, label: "Item was damaged." },
    { value: 2, label: "Incorrect item." },
    { value: 3, label: "Item is missing." },
    { value: 4, label: "Item isn't as described." },
    { value: 5, label: "Item isn't authentic." },
  ];
  const [issue, setIssue] = useState(null);
  const image1 = { src: "", class: "", delete: false, id: "" };
  const image2 = { src: "", class: "", delete: false, id: "" };
  const image3 = { src: "", class: "", delete: false, id: "" };
  const image4 = { src: "", class: "", delete: false, id: "" };
  const [images, setImageState] = useState({ image1, image2, image3, image4 });
  const setImage = (event) => {
    if (!props.login.user.activated) {
      props.alert.error("Please activate your account to upload pictures.");
      return;
    }
    var thisImage = event.target.id;
    var obj = images[thisImage];
    const token = JSON.parse(localStorage.getItem("user")).token;
    const headers = { Authorization: `Bearer ${token}` };
    if (event.target.value) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        obj.id = "";
        obj.src = image.src;
        obj.delete = false;
        image.onload = function () {
          this.width > this.height
            ? (obj.class = "horizontal")
            : (obj.class = "vertical");
          setImageState({ ...images, [thisImage]: obj });
        };
      };
      reader.readAsDataURL(event.target.files[0]);
      const data = new FormData();
      data.append("action", "file_upload");
      data.append("file", event.target.files[0]);
      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/wp-json/wp/v2/media",
        data,
        headers,
      })
        .then(function (response) {
          obj.id = response.data.id;
          setImageState({ ...images, [thisImage]: obj });
          if (obj.delete) {
            axios({
              method: "delete",
              url:
                process.env.REACT_APP_API_URL +
                "/wp-json/wp/v2/media/" +
                response.data.id +
                "/?force=1",
              headers: headers,
            }).then(function () {
              obj.src = "";
              obj.class = "";
              obj.id = "";
              obj.delete = false;
              setImageState({ ...images, [thisImage]: obj });
            });
          }
        })
        .catch(() => {
          obj.src = "";
          obj.class = "";
          obj.id = "";
          obj.delete = false;
          setImageState({ ...images, [thisImage]: obj });
          props.alert.error("There was an error uploading this image.");
        });
    } else {
      if (obj.id) {
        obj.src = "";
        obj.class = "";
        setImageState({ ...images, [thisImage]: obj });
        axios({
          method: "delete",
          url:
            process.env.REACT_APP_API_URL +
            "/wp-json/wp/v2/media/" +
            obj.id +
            "/?force=1",
          headers: headers,
        })
          .then(function () {
            obj.id = "";
            obj.delete = false;
            setImageState({ ...images, [thisImage]: obj });
          })
          .catch(() => {
            obj.src = "";
            obj.class = "";
            obj.id = "";
            obj.delete = false;
            setImageState({ ...images, [thisImage]: obj });
          });
      } else {
        obj.src = "";
        obj.class = "";
        obj.id = "";
        obj.delete = true;
        setImageState({ ...images, [thisImage]: obj });
      }
    }
  };
  const history = useHistory();
  const submitRequest = () => {
    const alert = props.alert;
    if (!issue) {
      alert.error("Please select an issue.");
      return;
    }
    if (!document.getElementById("return-description").value) {
      alert.error("Please enter a description.");
      return;
    }
    const user = props.login.user;
    const orderId = parseInt(props.match.params.order);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    };
    let filteredImages = Object.values(images)
      .map((e) => {
        return e.id;
      })
      .filter((e) => {
        return e !== "";
      });
    const body = JSON.stringify({
      order_id: orderId,
      issue: issue.label,
      description: document.getElementById("return-description").value,
      images: filteredImages,
    });
    fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/orders/returns/", {
      method: "POST",
      body,
      headers,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === "Requested") {
          const yourOrders = [...user.yourOrders];
          const existingOrder = yourOrders.find((item) => {
            return item.orderId === orderId;
          });
          if (existingOrder) {
            const index = yourOrders.indexOf(existingOrder);
            existingOrder.status = "Return requested";
            existingOrder.statusDetails =
              "You requested a return for this item. We'll let you know if it's approved within two business days.";
            yourOrders[index] = existingOrder;
          }
          props.login.updateUser({ yourOrders });
          alert.success("Return requested.");
          history.push("/order/" + orderId);
        }
      });
  };
  return (
    <div id="returns">
      <h1 style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>
        Returns
      </h1>
      <span>Order:</span>
      <input
        type="text"
        disabled={true}
        value={"#" + props.match.params.order}
      />
      <span>Issue:</span>
      <Select
        styles={customStyles}
        value={issue}
        onChange={setIssue}
        options={issues}
        isClearable={true}
        isSearchable={true}
      />
      <span>Images:</span>
      <div className="images container">
        <ul className="form-images">
          <li className="file-upload-wrap">
            {images.image1.src ? (
              <>
                <img
                  className={images.image1.class}
                  src={images.image1.src}
                  alt="image1"
                />
                <div id="image1" onClick={setImage} />
              </>
            ) : (
              <>
                <label htmlFor="image1">+</label>
                <input
                  onChange={setImage}
                  type="file"
                  id="image1"
                  accept=".png, .jpg, .jpeg"
                />
              </>
            )}
          </li>
          <li className="file-upload-wrap">
            {images.image2.src ? (
              <>
                <img
                  className={images.image2.class}
                  src={images.image2.src}
                  alt="image2"
                />
                <div id="image2" onClick={setImage} />
              </>
            ) : (
              <>
                <label htmlFor="image2">+</label>
                <input
                  onChange={setImage}
                  type="file"
                  id="image2"
                  accept=".png, .jpg, .jpeg"
                />
              </>
            )}
          </li>
          <li className="file-upload-wrap">
            {images.image3.src ? (
              <>
                <img
                  className={images.image3.class}
                  src={images.image3.src}
                  alt="image3"
                />
                <div id="image3" onClick={setImage} />
              </>
            ) : (
              <>
                <label htmlFor="image3">+</label>
                <input
                  onChange={setImage}
                  type="file"
                  id="image3"
                  accept=".png, .jpg, .jpeg"
                />
              </>
            )}
          </li>
          <li className="file-upload-wrap">
            {images.image4.src ? (
              <>
                <img
                  className={images.image4.class}
                  src={images.image4.src}
                  alt="image4"
                />
                <div id="image4" onClick={setImage} />
              </>
            ) : (
              <>
                <label htmlFor="image4">+</label>
                <input
                  onChange={setImage}
                  type="file"
                  id="image4"
                  accept=".png, .jpg, .jpeg"
                />
              </>
            )}
          </li>
        </ul>
      </div>
      <span>Description:</span>
      <textarea id="return-description" />
      <button onClick={submitRequest} className="submit-button button">
        Submit
      </button>
    </div>
  );
}
