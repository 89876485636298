import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";

export default function Rating(props) {
  const rateUser = (e) => {
    if (props.disabled) return;
    if (props.setRating) {
      let target = e.target;
      if (target.nodeName === "path") target = target.parentElement;
      props.setRating(target.getAttribute("data-key"));
    }
  };
  const rating = props.rating > 5 ? 5 : props.rating;
  const stars = [];
  const emptyStars = Math.floor(rating);
  const halfStar = Math.floor(rating) < Math.round(rating) ? 1 : 0;
  const fullStars = 5 - emptyStars - halfStar;
  let counter = 0;
  for (var i = 0; i < emptyStars; i++) {
    counter++;
    stars.push(
      <FontAwesomeIcon
        onClick={rateUser}
        key={counter}
        data-key={counter}
        style={{ color: "ffca04" }}
        icon={faStar}
      />
    );
  }
  if (halfStar) {
    counter++;
    stars.push(
      <FontAwesomeIcon
        key={counter}
        data-key={counter}
        style={{ color: "ffca04" }}
        icon={faStarHalfAlt}
      />
    );
  }
  for (var i = 0; i < fullStars; i++) {
    counter++;
    stars.push(
      <FontAwesomeIcon
        onClick={rateUser}
        key={counter}
        data-key={counter}
        style={{ color: "ffca04" }}
        icon={faStarEmpty}
      />
    );
  }
  stars.push(
    <span key={6} style={{ marginLeft: "5px" }}>
      {"(" + props.ratingQty + ")"}
    </span>
  );
  return <>{stars}</>;
}
