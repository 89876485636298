import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function SearchForm() {
  const [search, setSearchState] = useState("");

  const setSearch = (e) => setSearchState(e.target.value);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search) {
      history.push(`/search/${search}`);
      setSearchState("");
    }
  };

  return (
    <div className="product-search">
      <form onSubmit={handleSubmit}>
        <div className="search-wrapper">
          <input
            name="search"
            type="text"
            className="search"
            onChange={setSearch}
            value={search}
            autoComplete="off"
            placeholder="Search for products..."
          />
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </form>
    </div>
  );
}
