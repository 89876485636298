import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showModal } from "./togglemodals";
import {
  faHome,
  faFemale,
  faMale,
  faTag,
  faFire,
  faHashtag,
  faSearch,
  faBars,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import { brands } from "../data/BrandsData";
import { categories } from "../data/CategoriesData";
import { Helmet } from "react-helmet";
import queryString from "query-string";

export default function HomeLinks() {
  const { pathname, search } = useLocation();

  const [link, select] = useState(0);

  const [extraLinks, addLink] = useState(
    localStorage.getItem("extraLinks")
      ? JSON.parse(localStorage.getItem("extraLinks"))
      : ["/search/books", "/?brand=411", "/?brand=5329", "/?brand=4544"]
  );

  useEffect(() => {
    const defaultLinks = [
      "/",
      "/?orderby=popularity",
      "/?tag=homepage",
      "/category/1",
      "/category/2",
      "/category/7",
    ];
    if (defaultLinks.includes(pathname + search)) {
      scrollToLink(defaultLinks.indexOf(pathname + search) + 1);
      select(defaultLinks.indexOf(pathname + search) + 1);
    } else if (extraLinks.includes(pathname + search)) {
      scrollToLink(extraLinks.indexOf(pathname + search) + 7);
      select(extraLinks.indexOf(pathname + search) + 7);
    } else {
      if (search) {
        const params = queryString.parse(search);
        if (params.tag || params.brand || params.orderby) {
          const newLinks = [pathname + search, ...extraLinks];
          if (newLinks.length > 13) newLinks.splice(13, 1);
          select(7);
          addLink(newLinks);
          scrollToLink(7);
          localStorage.setItem("extraLinks", JSON.stringify(newLinks));
        } else {
          select(1);
        }
      } else if (pathname.includes("category") || pathname.includes("search")) {
        const newLinks = [pathname + search, ...extraLinks];
        if (newLinks.length > 13) newLinks.splice(13, 1);
        select(7);
        addLink(newLinks);
        scrollToLink(7);
        localStorage.setItem("extraLinks", JSON.stringify(newLinks));
      }
    }
    if ((pathname + search).includes("register=true"))
      showModal(document.getElementById("register-modal"));
  }, [pathname, search]);

  const scrollToLink = (id) => {
    setTimeout(() => {
      const link = document.getElementById("link-" + id);
      const links = document.getElementById("home-links");
      const offset =
        link.offsetLeft -
        links.offsetWidth / 2 +
        link.offsetWidth / 2 -
        (window.innerWidth - links.offsetWidth) / 2 +
        4;
      document.getElementById("home-links").scrollLeft = offset;
    }, 50)
  };

  const showCategories = () => {
    showModal(document.getElementById("categories-modal"));
  };

  const extraLinksDom = extraLinks.map((e, i) => {
    let icon = faBars;
    let name = "";
    let style = { width: "18px", height: "18px" };
    if (e.startsWith("/category/")) {
      icon = faBars;
      const category = parseInt(e.replace("/category/", ""));
      if (categories[category]) name = categories[category].select.label;
      style = { width: "20px", height: "20px" };
    } else if (e.startsWith("/search/")) {
      icon = faSearch;
      name = decodeURI(e.replace("/search/", ""));
    } else if (e.startsWith("/?brand=")) {
      icon = faTag;
      name = brands[e.replace("/?brand=", "")]
        ? brands[e.replace("/?brand=", "")].label
        : "N/A";
      style = { width: "19px", height: "19px" };
    } else if (e.startsWith("/?tag=")) {
      icon = faHashtag;
      name = e.replace("/?tag=", "");
    }
    return (
      <Link
        id={"link-" + (7 + i)}
        key={7 + i}
        className={link === 7 + i ? "selected icon-links" : "icon-links"}
        to={e}
      >
        <div className="icon-left">
          <FontAwesomeIcon style={style} icon={icon} />
        </div>
        {name}
      </Link>
    );
  });

  return (
    <>
      <Helmet></Helmet>
      <div className="links">
        <div id="home-links" className="profile-links">
          <Link id="link-1" className={link === 1 ? "selected icon-links" : "icon-links"} to="/">
            <div className="icon-left">
              <FontAwesomeIcon
                style={{ width: "21px", height: "21px" }}
                icon={faHome}
              />
            </div>
            Home
          </Link>
          <button onClick={showCategories} className="icon-links">
            <div className="icon-left">
              <FontAwesomeIcon
                style={{ width: "20px", height: "20px" }}
                icon={faBars}
              />
            </div>
            Categories
          </button>
          <Link
            id="link-2"
            className={link === 2 ? "selected icon-links" : "icon-links"}
            to="/?orderby=popularity"
          >
            <div className="icon-left">
              <FontAwesomeIcon
                style={{ width: "20px", height: "20px" }}
                icon={faFire}
              />
            </div>
            Trending
          </Link>
          <Link
            id="link-3"
            className={link === 3 ? "selected icon-links" : "icon-links"}
            to="/?tag=homepage"
          >
            <div className="icon-left">
              <FontAwesomeIcon
                style={{ width: "19px", height: "19px" }}
                icon={faHashtag}
              />
            </div>
            homepage
          </Link>
          <Link
            id="link-4"
            className={link === 4 ? "selected icon-links" : "icon-links"}
            to="/category/1"
          >
            <div className="icon-left">
              <FontAwesomeIcon
                style={{ width: "18px", height: "18px" }}
                icon={faFemale}
              />
            </div>
            Women's
          </Link>
          <Link
            id="link-5"
            className={link === 5 ? "selected icon-links" : "icon-links"}
            to="/category/2"
          >
            <div className="icon-left">
              <FontAwesomeIcon
                style={{ width: "18px", height: "18px" }}
                icon={faMale}
              />
            </div>
            Men's
          </Link>
          <Link
            id="link-6"
            className={link === 6 ? "selected icon-links" : "icon-links"}
            to="/category/7"
          >
            <div className="icon-left">
              <FontAwesomeIcon
                style={{ width: "18px", height: "18px" }}
                icon={faBolt}
              />
            </div>
            Electronics
          </Link>
          {extraLinksDom}
        </div>
      </div>
    </>
  );
}
