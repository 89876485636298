import React from "react";
import Products from "../components/products";
import { Link, Redirect } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { conditions, sizes } from "../data";
import { brands } from "../data/BrandsData";
import { categories } from "../data/CategoriesData";
import { formatPrice, timeSince } from "../functions";
import "react-image-gallery/styles/css/image-gallery.css";
import Rating from "../components/rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHeart as faHeartR,
  faTrashAlt,
  faShareSquare,
  faEdit,
} from "@fortawesome/free-regular-svg-icons";
import { showModal } from "../components/togglemodals";
import OfferButtons from "../components/offerbuttons";
import { getFloatVal } from "../functions";
import { ReactComponent as CartIcon } from "../svg/cart.svg";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";

export default class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      offer: { price: "", status: "", buttonsDisabled: false },
      images: [{original: "", thumbnail: "https://admin.atlas.deals/wp-content/uploads/2021/10/grey-e1634700625941.png"}],
      categories: [],
      tags: [],
      posted: "",
      updated: "",
      related: [],
      loading: false,
      user: {
        description: "",
        profile: {},
        cover: {},
        rating: 5,
        ratingQty: 0,
      },
      redirect: false,
      offerModalOpen: false,
      loadingLikes: false,
    };
    this.getProduct = this.getProduct.bind(this);
    this.setProduct = this.setProduct.bind(this);
    this.getRelatedProducts = this.getRelatedProducts.bind(this);
    this.checkCache = this.checkCache.bind(this);
    this.checkForOffers = this.checkForOffers.bind(this);
    this.cart = this.cart.bind(this);
    this.likes = this.likes.bind(this);
    this.yourOffers = this.yourOffers.bind(this);
    this.customerOffers = this.customerOffers.bind(this);
    this.buyProduct = this.buyProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.shareProduct = this.shareProduct.bind(this);
  }

  componentDidMount() {
    this.checkCache();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.product !== prevProps.match.params.product) {
      this.checkCache();
    }
    if (this.props.login.user !== prevProps.login.user) {
      this.checkForOffers();
    }
  }

  componentWillUnmount() {
    document
      .getElementById("root")
      .removeEventListener("click", this.closeOfferModal);
  }

  checkCache() {
    const product = Object.assign({}, this.props.location.product);
    if (Object.keys(product).length > 0) this.setProduct(product);
    if (!this.props.location.product) this.getProduct();
  }

  checkForOffers() {
    const user = this.props.login.user;
    const isOwner = Boolean(
      this.state.product.user === this.props.login.user.username
    );
    const existingOffer = user.loggedIn
      ? !isOwner
        ? user.yourOffers.find((item) => {
            return item.id === this.state.product.id;
          })
        : user.customerOffers.find((item) => {
            return item.id === this.state.product.id;
          })
      : null;
    if (existingOffer)
      if (Date.now() / 1000 - parseInt(existingOffer.date) > 86400)
        existingOffer.status = "expired";
    this.setState({
      offer:
        existingOffer &&
        existingOffer.status !== "cancelled" &&
        existingOffer.status !== "declined" &&
        user.loggedIn
          ? existingOffer
          : { price: "", status: "", buttonsDisabled: false },
    });
  }

  getProduct() {
    fetch(
      process.env.REACT_APP_API_URL +
        "/wp-content/plugins/products-api/query.php?id=" +
        this.props.match.params.product
    )
      .then((response) => response.json())
      .then((results) => {
        const product = results[0];
        this.setProduct(product);
      });
  }

  setProduct(product) {
    this.setState(
      {
        product: product,
        categories: product.categories.map((id) => {
          const name = categories[id] ? categories[id].select.label : "";
          return (
            <Link key={id} to={`/category/${id}`}>
              {name}
            </Link>
          );
        }),
        tags: product.tags.map((tag) => (
          <Link key={tag} to={`/?tag=${tag}`}>{`#${tag}`}</Link>
        )),
        images: product.images.map((image) => ({
          original: image.large ? image.large : image.src,
          thumbnail: image.small,
        })),
        posted: timeSince(product.posted),
        updated: product.updated ? timeSince(product.updated) : "",
      },
      () => {
        this.fetchExtraData();
      }
    );
  }

  fetchExtraData = () => {
    this.checkForOffers();
    this.getRelatedProducts();
    this.getUser(this.state.product.user);
  };

  getRelatedProducts() {
    if (this.state.product.related_ids.length > 0) {
      this.setState({ loading: true });
      const related = this.state.product.related_ids.slice(0, 8);
      fetch(
        process.env.REACT_APP_API_URL +
          "/wp-content/plugins/products-api/query.php?include=" +
          related.join(",")
      )
        .then((response) => response.json())
        .then((results) => {
          results.sort((a, b) => {
            return related.indexOf("" + a.id) - related.indexOf("" + b.id);
          });
          this.setState({ related: results, loading: false });
        })
    } else {
      this.setState({ related: [], loading: false });
    }
  }

  getUser(username) {
    if (username) {
      fetch(
        process.env.REACT_APP_API_URL +
          "/wp-content/plugins/users-api/query.php?username=" +
          username
      )
        .then((response) => response.json())
        .then((result) => {
          this.setState({ user: result });
        });
    }
  }

  cart() {
    if (this.state.product.stock > 0) {
      if (
        this.props.cart.cart.products.find((item) => {
          if (this.state.product.id === item.id) return item;
        })
      ) {
        showModal(document.getElementById("cart-modal"));
      } else {
        this.props.cart.addToCart(this.state.product);
        showModal(document.getElementById("cart-modal"));
      }
    } else {
      this.props.alert.error("This product is sold.");
    }
  }

  likes() {
    const user = this.props.login.user;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    };
    const id = this.state.product.id;
    if (user.loggedIn) {
      const isLiked = user.likes.find((item) => {
        return this.state.product.id === item.id;
      })
        ? true
        : false;
      if (isLiked) {
        this.setState({ loadingLikes: true });
        fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/users/likes", {
          method: "post",
          body: JSON.stringify({ id }),
          headers,
        })
          .then((response) => response.json())
          .then((result) => {
            if (result === "removed") {
              this.props.login.updateUser({
                likes: [...user.likes].filter((item) => {
                  return this.state.product.id !== item.id;
                }),
              });
              this.setState({
                product: {
                  ...this.state.product,
                  likes: this.state.product.likes - 1,
                },
              });
              this.setState({ loadingLikes: false });
            }
          })
          .catch(() => {
            this.setState({ loadingLikes: false });
            this.props.alert.error("An error occurred, please try again.");
          });
      } else {
        if (this.state.product.stock > 0) {
          this.setState({ loadingLikes: true });
          fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/users/likes", {
            method: "post",
            body: JSON.stringify({ id }),
            headers,
          })
            .then((response) => response.json())
            .then((result) => {
              if (result === "added") {
                this.props.login.updateUser({
                  likes: [{
                    ...this.state.product,
                    likes: this.state.product.likes + 1,
                  }, ...user.likes],
                });
                this.setState({
                  product: {
                    ...this.state.product,
                    likes: this.state.product.likes + 1,
                  },
                });
                this.setState({ loadingLikes: false });
              }
            })
            .catch(() => {
              this.setState({ loadingLikes: false });
              this.props.alert.error("An error occurred, please try again.");
            });
        } else {
          this.props.alert.error("This product is sold.");
        }
      }
    } else {
      this.openLogin();
    }
  }

  setOfferPrice = (e) =>
    this.setState({
      offer: { ...this.state.offer, price: formatPrice(e.target.value) },
    });

  setOfferPriceOnBlur = (e) =>
    this.setState({
      offer: { ...this.state.offer, price: formatPrice(e.target.value, true) },
    });

  yourOffers() {
    const alert = this.props.alert;
    const user = this.props.login.user;
    const token = user.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const product = this.state.product;
    if (this.state.offer.status === "sent") {
      alert.confirm().then((response) => {
        if (response) {
          this.setState({ disabled: true });
          const data = { id: product.id };
          fetch(
            process.env.REACT_APP_API_URL + "/wp-json/atlas/offers/cancel",
            { method: "post", body: JSON.stringify(data), headers }
          )
            .then((response) => response.json())
            .then((result) => {
              if (result === "Cancelled") {
                alert.success("Offer cancelled.");
                const yourOffers = [...user.yourOffers];
                yourOffers.splice(yourOffers.indexOf(this.state.offer), 1);
                this.props.login.updateUser({ yourOffers });
              }
            });
        }
      });
    } else {
      if (product.stock <= 0) {
        alert.error("This product is sold");
        return;
      }
      if (!this.state.offer.price) {
        document.getElementById("offerPrice").focus();
        return;
      }
      if (getFloatVal(product.price) === 0) {
        alert.error("This product is free.");
        return;
      }
      if (getFloatVal(this.state.offer.price) <= 0) {
        alert.error("Offer must be greater than $0.");
        return;
      }
      if (getFloatVal(this.state.offer.price) >= getFloatVal(product.price)) {
        alert.error("Offer must be less than " + product.price + ".");
        return;
      }
      if (product.highestOffer) {
        if (
          getFloatVal(this.state.offer.price) <
          getFloatVal(product.highestOffer)
        ) {
          alert.error(
            "Offer must be greater than " + product.highestOffer + "."
          );
          return;
        }
      }
      const price = getFloatVal(this.state.offer.price);
      const paypalFee = price * 0.02987 + 0.566;
      const offerDraft = {
        product: { ...product, price: this.state.offer.price },
        paypalFee,
        total: price + paypalFee,
      };
      this.setState({
        redirect: { offer: offerDraft, pathname: "/make-offer" },
      });
    }
  }

  customerOffers(e) {
    if (e) e.preventDefault();
    const user = this.props.login.user;
    const alert = this.props.alert;
    const token = user.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const product = this.state.product;
    const customerOffers = [...user.customerOffers];
    if (e.target.name === "accept") {
      alert.confirm().then((response) => {
        if (response) {
          this.setState({ disabled: true });
          const data = { id: product.id, price: this.state.offer.price };
          fetch(
            process.env.REACT_APP_API_URL + "/wp-json/atlas/offers/accept",
            { method: "post", body: JSON.stringify(data), headers }
          )
            .then((response) => response.json())
            .then((result) => {
              this.setState({ disabled: true });
              if (result[0].status === "Pre-shipment") {
                alert.success("Offer accepted.");
                const products = [...user.products];
                const index = products.findIndex((el) => el.id === product.id);
                if (index > -1) {
                  products[index].price = this.state.offer.price;
                  products[index].stock = 0;
                  this.props.login.updateUser({ products });
                }
                customerOffers[customerOffers.indexOf(this.state.offer)][
                  "status"
                ] = "accepted";
                this.props.login.updateUser({
                  customerOffers,
                  customerOrders: [result[0], ...user.customerOrders],
                });
              }
            });
        }
      });
    } else {
      alert.confirm().then((response) => {
        if (response) {
          this.setState({ disabled: true });
          const data = { id: product.id };
          fetch(
            process.env.REACT_APP_API_URL + "/wp-json/atlas/offers/decline",
            { method: "post", body: JSON.stringify(data), headers }
          )
            .then((response) => response.json())
            .then((result) => {
              this.setState({ disabled: false });
              if (result === "Declined") {
                alert.success("Offer declined.");
                customerOffers.splice(customerOffers.indexOf(this.state.offer), 1)
                this.props.login.updateUser({ customerOffers });
              }
            });
        }
      });
    }
  }

  openLogin = () => {
    showModal(document.getElementById("login-modal"));
  };

  openOfferModal = () => {
    this.setState({ offerModalOpen: true });
    document
      .getElementById("root")
      .addEventListener("click", this.closeOfferModal);
  };

  closeOfferModal = (e) => {
    const modal = document.getElementById("offers-modal");
    if (!modal.contains(e.target)) {
      this.setState({ offerModalOpen: false });
      document
        .getElementById("root")
        .removeEventListener("click", this.closeOfferModal);
    }
  };

  buyProduct() {
    if (
      !this.props.cart.cart.products.find((item) => {
        if (this.state.product.id === item.id) return item;
      })
    ) {
      this.props.cart.addToCart(this.state.product);
    }
    this.setState({ redirect: "/checkout" });
  }

  deleteProduct() {
    const user = this.props.login.user;
    const alert = this.props.alert;
    alert.confirm().then((response) => {
      if (response) {
        const headers = { Authorization: `Bearer ${user.token}` };
        fetch(
          process.env.REACT_APP_API_URL +
            "/wp-json/wc/v3/products/" +
            this.state.product.id,
          { method: "delete", headers }
        ).then((response) => {
          if (response.status === 200) {
            alert.success("Product deleted.");
            this.props.login.updateUser({
              products: [...user.products].filter((item) => {
                return this.state.product.id !== item.id;
              }),
            });
            this.setState({ redirect: "/profile/products/?deleted=true" });
          }
        });
      }
    });
  }

  shareProduct() {
    if (navigator.canShare) {
      navigator.share({
        title: this.state.product.name,
        text: "Check out this product on Atlas: ",
        url: window.location.pathname,
      });
    }
  }

  render() {
    const product = this.state.product;
    const loading = this.state.categories.length == 0;
    const user = this.props.login.user;
    const brand = brands[product.brand];
    const condition = conditions[product.condition];
    const size = sizes[product.size];
    const isOwner = Boolean(product.user === this.props.login.user.username);
    const inCart = this.props.cart.cart.products.find((item) => {
      return product.id === item.id;
    });
    const isLiked = user.likes.find((item) => {
      return product.id === item.id;
    });
    const isOrder = user.loggedIn
      ? !isOwner
        ? user.yourOrders.find((item) => {
            return item.productId === product.id;
          })
        : user.customerOrders.find((item) => {
            return item.productId === product.id;
          })
      : null;
    const price =
      product.stock > 0
        ? product.price !== "$0.00"
          ? product.price
          : "FREE"
        : "SOLD";
    const offer = this.state.offer;

    if (this.state.redirect || isOrder) {
      return (
        <Redirect
          to={isOrder ? "/order/" + isOrder.orderId : this.state.redirect}
        />
      );
    } else {
      return (
        <div className="product-page">
          <div className="product-information">
            {loading ? 
              <div className="image-gallery">
                <div className="image-gallery-content">
                  <div className="image-gallery-slide-wrapper">
                    <div className="image-gallery-slides">
                      <div className="image-gallery-slide center">
                        <div className="image-gallery-image" />
                      </div>
                    </div>
                  </div>
                  <div className="image-gallery-thumbnails-wrapper">
                    <div className="image-gallery-thumbnails">
                      <div className="image-gallery-thumbnails-container">
                        <div className="image-gallery-thumbnail">
                          <span className="image-gallery-thumbnail-inner">
                            <div className="image-gallery-thumbnail-image" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            : 
              <ImageGallery
                items={this.state.images}
                showPlayButton={false}
                showNav={false}
                showFullscreenButton={false}
              />
            }
            <div className="summary">
              <div className="top-summary">
                {loading && 
                  <div style={{borderRadius: "6px", top: "0", left: "0", position: "absolute", width: "100%", height: "100%", background: "#e1e1e1", zIndex: "100"}} />
                }
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className="user-profile-pic">
                    {this.state.user.profile.small ? (
                      <img
                        className="vertical"
                        src={this.state.user.profile.small}
                        alt={product.user + "-profile"}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{
                          width: "23px",
                          height: "23px",
                          color: "white",
                        }}
                        icon={faUser}
                      />
                    )}
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <Link
                      style={{ fontSize: "17px" }}
                      to={
                        isOwner
                          ? "/profile/products"
                          : {
                              user: this.state.user,
                              pathname: `/user/${product.user}`,
                            }
                      }
                    >
                      {product.user}
                    </Link>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <Rating
                      rating={this.state.user.rating}
                      ratingQty={this.state.user.ratingQty}
                    />
                  </div>
                </div>
                <div>
                  <h1>{product.name}</h1>
                  <span className="price">{price}</span>
                </div>
                <div style={{ width: "100%" }}>
                  <div className="product-buttons">
                    {!isOwner ? (
                      <button
                        data-tooltip="Likes"
                        className="submit-button button"
                        style={{float: "left"}}
                        disabled={this.state.disabled}
                        onClick={this.likes}
                      >
                        {isLiked ? "Dislike" : "Like"}
                      </button>
                    ) : (
                      <Link
                        data-tooltip="Edit"
                        className="button submit-button"
                        style={{float: "right"}}
                        to={{
                          product: product,
                          pathname: `/edit-product/${product.id}`,
                        }}
                      >
                        Edit
                      </Link>
                    )}
                    {!isOwner ? (
                      <button
                        data-tooltip="Cart"
                        className="button submit-button"
                        style={{float: "right"}}
                        onClick={this.cart}
                      >
                        Add to Cart
                      </button>
                    ) : (
                      <button
                        data-tooltip="Delete"
                        className="button submit-button"
                        style={{float: "left"}}
                        onClick={this.deleteProduct}
                      >
                        Delete
                      </button>
                    )}
                    <button
                      data-tooltip="Offers"
                      className="button submit-button"
                      style={{float: "left"}}
                      onClick={this.openOfferModal}
                    >
                      Offers
                    </button>
                    <button
                      data-tooltip="Share"
                      className="button submit-button"
                      style={{float: "right"}}
                      onClick={this.shareProduct}
                    >
                      Share
                    </button>
                  </div>
                </div>
              </div>
              <div className="bottom-summary">
                {loading && 
                  <div style={{top: "0", left: "0", position: "absolute", width: "100%", height: "100%", background: "#e1e1e1", zIndex: "100"}} />
                }
                <h5 className="details-title">Details</h5>
                <div className="details-container">
                  <div>
                    <span>Description:</span>
                    <p>{product.description}</p>
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <span>Categories:</span>
                    <div>{this.state.categories}</div>
                  </div>
                  <div>
                    <span>Likes:</span>
                    <p>{product.likes}</p>
                  </div>
                  <div>
                    <span>Offers:</span>
                    <p>{product.offers}</p>
                  </div>
                  {this.state.tags.length > 0 ? (
                    <div style={{ marginBottom: "15px" }}>
                      <span>Tags:</span>
                      <div>{this.state.tags}</div>
                    </div>
                  ) : null}
                  {brand ? (
                    <div>
                      <span>Brand:</span>
                      <Link to={`/?brand=${brand.value}`}>{brand.label}</Link>
                    </div>
                  ) : null}
                  {condition ? (
                    <div>
                      <span>Condition:</span>
                      <p>{condition ? condition.label : ""}</p>
                    </div>
                  ) : null}
                  {size ? (
                    <div>
                      <span>Size:</span>
                      <p>{size ? size.label : ""}</p>
                    </div>
                  ) : null}
                  {this.state.updated ? (
                    <div>
                      <span>Updated:</span>
                      <p>{this.state.updated}</p>
                    </div>
                  ) : null}
                  <div>
                    <span>Posted:</span>
                    <p>{this.state.posted}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Products
            productPage={1}
            results={this.state.related}
          />
          <div className="modals">
            <div
              id="offers-modal"
              style={{ display: this.state.offerModalOpen ? "block" : "none" }}
            >
              <div className="offers-title">
                <h2 className="title">Offers</h2>
              </div>
              <OfferButtons
                isOwner={isOwner}
                offer={offer}
                offerFunction={
                  isOwner ? this.customerOffers : this.yourOffers
                }
                setOfferPriceOnBlur={this.setOfferPriceOnBlur}
                setOfferPrice={this.setOfferPrice}
              />
            </div>
          </div>
          <div
            className="overlay"
            style={{ display: this.state.offerModalOpen ? "block" : "none" }}
          />
        </div>
      );
    }
  }
}
