import React from "react";

export default function Terms() {
  return (
    <div className="terms-privacy">
      <h1>Terms and Conditions</h1>
      <div>
        <p>Last updated on 11/01/21</p>
        <h4>AGREEMENT TO TERMS</h4>
        <p>
          These Terms constitute a legally binding agreement made between you,
          whether personally or on behalf of an entity (“you”) and Atlas (“we,”
          “us” or “our”), concerning your access to and use of the atlas.store
          website as well as any other media form, media channel, or mobile
          website related, linked, or otherwise connected thereto the
          (collectively, the “Site”).{" "}
        </p>
        <p>
          You agree that by accessing the Site, you have read, understood, and
          agree to be bound by all of these Terms of Use. If you do not agree
          with all of these Terms of Use, then you are expressly prohibited from
          using the Site and you must discontinue use immediately.
        </p>
        <p>
          We will alert you about any changes by updating the “Last updated”
          date of these Terms of Use, and you waive any right to receive
          specific notice of each such change.{" "}
        </p>
        <p>
          It is your responsibility to periodically review these Terms of Use to
          stay informed of updates. You will be subject to, and will be deemed
          to have been made aware of and to have accepted, the changes in any
          revised Terms of Use by your continued use of the Site after the date
          such revised Terms of Use are posted.{" "}
        </p>
        <p>
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country.{" "}
        </p>
        <p>
          Accordingly, those persons who choose to access the Site from other
          locations do so on their own initiative and are solely responsible for
          compliance with local laws, if and to the extent local laws are
          applicable.&nbsp;
        </p>
        <p>
          The Site is intended for users who are at least 18 years of age. All
          users who are minors in the jurisdiction in which they reside must
          have the permission of, and be directly supervised by, their parent or
          guardian to use the Site. If you are a minor, you must have your
          parent or guardian read and agree to these Terms of Use prior to you
          using the Site.{" "}
        </p>

        <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
        <p>
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics on the Site
          (collectively, the “Content”) and the trademarks, service marks, and
          logos contained therein (the “Marks”) are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property rights and unfair competition laws
          of the United States, foreign jurisdictions, and international
          conventions.{" "}
        </p>
        <p>
          The Content and the Marks are provided on the Site “AS IS” for your
          information and personal use only. Except as expressly provided in
          these Terms of Use, no part of the Site and no Content or Marks may be
          copied, reproduced, aggregated, republished, uploaded, posted,
          publicly displayed, encoded, translated, transmitted, distributed,
          sold, licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
        </p>
        <p>
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print a
          copy of any portion of the Content to which you have properly gained
          access solely for your personal, non-commercial use. We reserve all
          rights not expressly granted to you in and to the Site, the Content
          and the Marks.
        </p>

        <h4>USER AGREEMENT</h4>
        <p>By using the Site, you represent and warrant that: </p>
        <ol>
          <li>
            all registration information you submit will be true, accurate,
            current, and complete;&nbsp;
          </li>
          <li>
            you will maintain the accuracy of such information and promptly
            update such registration information as necessary;&nbsp;{" "}
          </li>
          <li>
            you have the legal capacity and you agree to comply with these Terms
            of Use;&nbsp;{" "}
          </li>
          <li>you are not under the age of 18;&nbsp; </li>
          <li>
            you will not access the Site through automated or non-human means,
            whether through a bot, script or otherwise;&nbsp;{" "}
          </li>
          <li>
            you will not use the Site for any illegal or unauthorized
            purpose;&nbsp;{" "}
          </li>
          <li>
            your use of the Site will not violate any applicable law or
            regulation.{" "}
          </li>
          <li>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Site (or any portion thereof).{" "}
          </li>
        </ol>

        <h4>USER REGISTRATION</h4>
        <p>
          You may be required to register with the Site. You agree to keep your
          password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </p>

        <h4>PRODUCTS</h4>
        <p>
          All products are subject to availability, and we cannot guarantee that
          items will be in stock. We reserve the right to remove any products
          from the Site at any time for any reason. Prices for all products are
          subject to change before purchase if the seller chooses.
        </p>

        <h4>PURCHASES AND PAYMENT</h4>
        <p>
          We accept card, Google Pay, and Apple Pay as our forms of payment. You agree to provide
          current, complete, and accurate purchase and account information for
          all purchases made via the Site. You further agree to promptly update
          account and payment information, including email address, payment
          method, and payment card expiration date, so that we can complete your
          transactions and contact you as needed. All payments shall be in U.S.
          dollars.{" "}
        </p>
        <p>
          You agree to pay all charges at the prices then in effect for your
          purchases and any applicable marketplace fees, and you authorize us to
          charge your chosen payment provider for any such amounts upon placing
          your order.{" "}
        </p>
        <p>
          We reserve the right to refuse any order placed through the Site. We
          may, in our sole discretion, limit or cancel quantities purchased per
          person, or per order. These restrictions may include orders placed by
          or under the same customer account, the same payment method, and/or
          orders that use the same billing address. We reserve the
          right to limit or prohibit orders that, in our sole judgment, appear
          to be placed by dealers, resellers, or distributors.{" "}
        </p>

        <h4>RETURN/REFUNDS POLICY</h4>
        <p>
          If you have any issue with a product that you have purchased from a
          seller, you may submit a refund request for up to two days following
          the delivery of your order. Whether or not the request is fulfilled
          will be determined by us and the seller. Requests will only be
          approved if the item is damaged, missing, incorrect, inauthentic, or
          isn't as described. If the request is valid and has been approved, we
          will send you a return label and the money will be refunded to your
          account within 24 hours after the product has been returned to the
          seller. We reserve the right to deny any refund requests that are
          deemed invalid.{" "}
        </p>

        <h4>PROHIBITED ACTIVITIES</h4>
        <p>
          You may not access or use the Site for any purpose other than that for
          which we make the Site available. The Site may not be used in
          connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.{" "}
        </p>
        <p>As a user of the Site, you agree not to:</p>
        <ol>
          <li>
            systematically retrieve data or other content from the Site to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            make any unauthorized use of the Site, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </li>
          <li>
            fail or refuse to pay for products that you have purchased on the
            site, unless you have already contacted us and received approval, or
            unless you have cancelled your order.
          </li>
          <li>
            change the price or manipulate another user’s product(s) in any way.
          </li>
          <li>
            circumvent, disable, or otherwise interfere with security-related
            features of the Site, including features that prevent or restrict
            the use or copying of any Content or enforce limitations on the use
            of the Site and/or the Content contained therein.
          </li>
          <li>engage in unauthorized framing of or linking to the Site.</li>
          <li>
            register using an email that does not contain the “.edu” domain
            extension.
          </li>
          <li>
            trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li>
            fail or refuse to deliver products that have been sold by you,
            unless you have already contacted us and received approval.
          </li>
          <li>
            engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            interfere with, disrupt, or create an undue burden on the Site or
            the networks or services connected to the Site.
          </li>
          <li>
            attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>sell or otherwise transfer your profile.</li>
          <li>
            use any information obtained from the Site in order to harass,
            abuse, or harm another person.
          </li>
          <li>use the Site as part of any effort to compete with us.</li>
          <li>
            decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of the Site.
          </li>
          <li>
            attempt to bypass any measures of the Site designed to prevent or
            restrict access to the Site, or any portion of the Site.
          </li>
          <li>
            harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Site to you.
          </li>
          <li>
            delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            copy or adapt the Site’s software, including but not limited to CSS,
            PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            Site or modifies, impairs, disrupts, alters, or interferes with the
            use, features, functions, operation, or maintenance of the Site.
          </li>
          <li>
            upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as “spyware” or
            “passive collection mechanisms” or “pcms”).
          </li>
          <li>
            except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Site, or using
            or launching any unauthorized script or other software.
          </li>
          <li>
            disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site.
          </li>
          <li>
            use the Site in a manner inconsistent with any applicable laws or
            regulations.
          </li>
        </ol>

        <h4>USER GENERATED CONTRIBUTIONS</h4>
        <p>
          The Site may invite you to chat or post products and may provide you
          with the opportunity to create, submit, post, display, transmit,
          perform, publish, distribute, or broadcast content and materials to us
          or on the Site, including but not limited to text, writings, video,
          audio, photographs, graphics, comments, suggestions, or personal
          information or other material (collectively, “Contributions”).{" "}
        </p>
        <p>
          Contributions may be viewable by other users of the Site and through
          third-party websites. As such, any Contributions you transmit may be
          treated as non-confidential and non-proprietary. When you create or
          make available any Contributions, you thereby represent and warrant
          that:
        </p>
        <ol>
          <li>
            the creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </li>
          <li>
            you are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Site, and other users of the Site to use your Contributions
            in any manner contemplated by the Site and these Terms of Use.
          </li>
          <li>
            you have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Site and these Terms of Use.
          </li>
          <li>your Contributions are not false, inaccurate, or misleading.</li>
          <li>
            your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </li>
          <li>
            your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </li>
          <li>
            your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>
          <li>
            your Contributions do not advocate the violent overthrow of any
            government or incite, encourage, or threaten physical harm against
            another.
          </li>
          <li>
            your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li>
            your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li>
            your Contributions do not contain any material that solicits
            personal information from anyone under the age of 18 or exploits
            people under the age of 18 in a sexual or violent manner.
          </li>
          <li>
            your Contributions do not violate any federal or state law
            concerning child pornography, or otherwise intended to protect the
            health or well-being of minors;
          </li>
          <li>
            your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </li>
          <li>
            your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms of Use, or any
            applicable law or regulation.
          </li>
        </ol>
        <p>
          Any use of the Site in violation of the foregoing violates these Terms
          of Use and may result in, among other things, termination or
          suspension of your rights to use the Site.{" "}
        </p>

        <h4>CONTRIBUTION LICENSE</h4>
        <p>
          By posting your Contributions to any part of the Site [or making
          Contributions accessible to the Site by linking your account from the
          Site to any of your social networking accounts], you automatically
          grant, and you represent and warrant that you have the right to grant,
          to us an unrestricted, unlimited, irrevocable, perpetual,
          non-exclusive, transferable, royalty-free, fully-paid, worldwide
          right, and license to host, use, copy, reproduce, disclose, sell,
          resell, publish, broadcast, retitle, archive, store, cache, publicly
          perform, publicly display, reformat, translate, transmit, excerpt (in
          whole or in part), and distribute such Contributions (including,
          without limitation, your image and voice) for any purpose, commercial,
          advertising, or otherwise, and to prepare derivative works of, or
          incorporate into other works, such Contributions, and grant and
          authorize sublicenses of the foregoing. The use and distribution may
          occur in any media formats and through any media channels.{" "}
        </p>
        <p>
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, as applicable,
          and any of the personal and commercial images you provide. You waive
          all moral rights in your Contributions, and you warrant that moral
          rights have not otherwise been asserted in your Contributions.{" "}
        </p>
        <p>
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area on the Site.{" "}
        </p>
        <p>
          You are solely responsible for your Contributions to the Site and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your
          Contributions.&nbsp;{" "}
        </p>
        <p>
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations on the
          Site; and (3) to pre-screen or delete any Contributions at any time
          and for any reason, without notice. We have no obligation to monitor
          your Contributions.{" "}
        </p>

        <h4>SUBMISSIONS</h4>
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          (“Submissions”) provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you.{" "}
        </p>
        <p>
          You hereby waive all moral rights to any such Submissions, and you
          hereby warrant that any such Submissions are original with you or that
          you have the right to submit such Submissions. You agree there shall
          be no recourse against us for any alleged or actual infringement or
          misappropriation of any proprietary right in your Submissions.{" "}
        </p>

        <h4>THIRD-PARTY WEBSITES AND CONTENT</h4>
        <p>
          The Site may contain (or you may be sent via the Site) links to other
          websites (“Third-Party Websites”) as well as articles, photographs,
          text, graphics, pictures, designs, music, sound, video, information,
          applications, software, and other content or items belonging to or
          originating from third parties (“Third-Party Content”).{" "}
        </p>
        <p>
          Such Third-Party Websites and Third-Party Content are not
          investigated, monitored, or checked for accuracy, appropriateness, or
          completeness by us, and we are not responsible for any Third Party
          Websites accessed through the Site or any Third-Party Content posted
          on, available through, or installed from the Site, including the
          content, accuracy, offensiveness, opinions, reliability, privacy
          practices, or other policies of or contained in the Third-Party
          Websites or the Third-Party Content.{" "}
        </p>
        <p>
          Inclusion of, linking to, or permitting the use or installation of any
          Third-Party Websites or any Third-Party Content does not imply
          approval or endorsement thereof by us. If you decide to leave the Site
          and access the Third-Party Websites or to use or install any
          Third-Party Content, you do so at your own risk, and you should be
          aware these Terms of Use no longer govern.{" "}
        </p>
        <p>
          You should review the applicable terms and policies, including privacy
          and data gathering practices, of any website to which you navigate
          from the Site or relating to any applications you use or install from
          the Site. Any purchases you make through Third-Party Websites will be
          through other websites and from other companies, and we take no
          responsibility whatsoever in relation to such purchases which are
          exclusively between you and the applicable third party.{" "}
        </p>
        <p>
          You agree and acknowledge that we do not endorse the products or
          services offered on Third-Party Websites and you shall hold us
          harmless from any harm caused by your purchase of such products or
          services. Additionally, you shall hold us harmless from any losses
          sustained by you or harm caused to you relating to or resulting in any
          way from any Third-Party Content or any contact with Third-Party
          Websites.{" "}
        </p>

        <h4>SITE MANAGEMENT</h4>
        <p>We reserve the right, but not the obligation, to:&nbsp;</p>
        <p>(1) monitor the Site for violations of these Terms of Use; </p>
        <p>
          (2) take appropriate legal action against anyone who, in our sole
          discretion, violates the law or these Terms of Use, including without
          limitation, reporting such user to law enforcement authorities;{" "}
        </p>
        <p>
          (3) in our sole discretion and without limitation, refuse, restrict
          access to, limit the availability of, or disable (to the extent
          technologically feasible) any of your Contributions or any portion
          thereof;{" "}
        </p>
        <p>
          (4) in&nbsp;our sole discretion and without limitation, notice, or
          liability, to remove from the Site or otherwise disable all files and
          content that are excessive in size or are in any way burdensome to our
          systems;{" "}
        </p>
        <p>
          (5) otherwise manage the Site in a manner designed to protect our
          rights and property and to facilitate the proper functioning of the
          Site.
        </p>

        <h4>PRIVACY POLICY</h4>
        <p>
          We care about data privacy and security. Please review our Privacy
          Policy posted on the Site. By using the Site, you agree to be bound by
          our Privacy Policy, which is incorporated into these Terms of Use.
          Please be advised the Site is hosted in the United States.{" "}
        </p>
        <p>
          If you access the Site from the European Union, Asia, or any other
          region of the world with laws or other requirements governing personal
          data collection, use, or disclosure that differ from applicable laws
          in the United States, then through your continued use of the Site, you
          are transferring your data to the United States, and you expressly
          consent to have your data transferred to and processed in the United
          States.{" "}
        </p>

        <h4>COPYRIGHT INFRINGEMENTS</h4>
        <p>
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Site infringes upon any
          copyright you own or control, please immediately notify us using the
          contact form provided on the page https://localhost/contact (a
          “Notification”). A copy of your Notification will be sent to the
          person who posted or stored the material addressed in the
          Notification.{" "}
        </p>
        <p>
          Please be advised that pursuant to federal law you may be held liable
          for damages if you make material misrepresentations in a Notification.
          Thus, if you are not sure that material located on or linked to by the
          Site infringes your copyright, you should consider first contacting an
          attorney.
        </p>

        <h4>TERM AND TERMINATION</h4>
        <p>
          These Terms of Use shall remain in full force and effect while you use
          the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE,
          WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
          LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
          CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
          INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SITE OR DELETE [YOUR ACCOUNT AND] ANY CONTENT OR
          INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
          DISCRETION.{" "}
        </p>
        <p>
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party.{" "}
        </p>
        <p>
          In addition to terminating or suspending your account, we reserve the
          right to take appropriate legal action, including without limitation
          pursuing civil, criminal, and injunctive redress.
        </p>

        <h4>MODIFICATIONS AND INTERRUPTIONS </h4>
        <p>
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Site. We also reserve the right to modify or discontinue all or
          part of the Site without notice at any time.
        </p>
        <p>
          We will not be liable to you or any third party for any modification,
          price change, suspension, or discontinuance of the Site.{" "}
        </p>
        <p>
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,
          or errors. We reserve the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Site at any time or for any
          reason without notice to you.{" "}
        </p>
        <p>
          You agree that we have no liability whatsoever for any loss, damage,
          or inconvenience caused by your inability to access or use the Site
          during any downtime or discontinuance of the Site. Nothing in these
          Terms of Use will be construed to obligate us to maintain and support
          the Site or to supply any corrections, updates, or releases in
          connection therewith.
        </p>

        <h4>GOVERNING LAW</h4>
        <p>
          These Terms of Use and your use of the Site are governed by and
          construed in accordance with the laws of the State of New York
          applicable to agreements made and to be entirely performed within the
          State/Commonwealth of New York, without regard to its conflict of law
          principles.{" "}
        </p>

        <h4>CORRECTIONS</h4>
        <p>
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Site at any time, without prior notice.
        </p>

        <h4>DISCLAIMER</h4>
        <p>
          THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
          THAT YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
          OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
          WEBSITES LINKED TO THIS SITE AND WE WILL ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
          NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,
          (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
          THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
          BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
          ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS
          OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
          POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
          NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
          PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
          FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
          PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
          BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
          WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
          ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
          WHERE APPROPRIATE.
        </p>

        <h4>LIMITATIONS OF LIABILITY</h4>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES.{" "}
        </p>

        <h4>INDEMNIFICATION</h4>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) your
          Contributions; (2) use of the Site; (3) breach of these Terms of Use;
          (4) any breach of your representations and warranties set forth in
          these Terms of Use; (5) your violation of the rights of a third party,
          including but not limited to intellectual property rights; or (6) any
          overt harmful act toward any other user of the Site with whom you
          connected via the Site.{" "}
        </p>
        <p>
          Notwithstanding the foregoing, we reserve the right, at your expense,
          to assume the exclusive defense and control of any matter for which
          you are required to indemnify us, and you agree to cooperate, at your
          expense, with our defense of such claims. We will use reasonable
          efforts to notify you of any such claim, action, or proceeding which
          is subject to this indemnification upon becoming aware of it.&nbsp;
        </p>

        <h4>USER DATA</h4>
        <p>
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the performance of the Site, as well as data
          relating to your use of the Site. Although we perform regular routine
          backups of data, you are solely responsible for all data that you
          transmit or that relates to any activity you have undertaken using the
          Site.{" "}
        </p>
        <p>
          You agree that we shall have no liability to you for any loss or
          corruption of any such data, and you hereby waive any right of action
          against us arising from any such loss or corruption of such data.
        </p>

        <h4>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h4>
        <p>
          Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
          OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.{" "}
        </p>
        <p>
          You hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances, or other laws in any jurisdiction
          which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          any means other than electronic means.{" "}
        </p>

        <h4>MISCELLANEOUS</h4>
        <p>
          These Terms of Use and any policies or operating rules posted by us on
          the Site or in respect to the Site constitute the entire agreement and
          understanding between you and us. Our failure to exercise or enforce
          any right or provision of these Terms of Use shall not operate as a
          waiver of such right or provision.{" "}
        </p>
        <p>
          These Terms of Use operate to the fullest extent permissible by law.
          We may assign any or all of our rights and obligations to others at
          any time. We shall not be responsible or liable for any loss, damage,
          delay, or failure to act caused by any cause beyond our reasonable
          control.{" "}
        </p>
        <p>
          If any provision or part of a provision of these Terms of Use is
          determined to be unlawful, void, or unenforceable, that provision or
          part of the provision is deemed severable from these Terms of Use and
          does not affect the validity and enforceability of any remaining
          provisions. There is no joint venture, partnership, employment or
          agency relationship created between you and us as a result of these
          Terms of Use or use of the Site.{" "}
        </p>
        <p>
          You agree that these Terms of Use will not be construed against us by
          virtue of having drafted them. You hereby waive any and all defenses
          you may have based on the electronic form of these Terms of Use and
          the lack of signing by the parties hereto to execute these Terms of
          Use.
        </p>
      </div>
    </div>
  );
}
