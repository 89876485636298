export const formatPrice = (input, blur) => {
  const formatNumber = (n) => {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  if (!input || input === "$") {
    return "";
  }
  if (input === ".") input = "0.";
  if (input.indexOf(".") >= 0) {
    var decPos = input.indexOf(".");
    var leftSide = input.substring(0, decPos);
    var rightSide = input.substring(decPos);
    leftSide = formatNumber(leftSide);
    rightSide = formatNumber(rightSide);
    if (blur) {
      rightSide += "00";
    }
    rightSide = rightSide.substring(0, 2);
    input = "$" + leftSide + "." + rightSide;
  } else {
    input = formatNumber(input);
    if (input) input = "$" + input;
    if (blur) {
      input += ".00";
    }
  }
  return input;
};

export const formatPhone = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

export const getFloatVal = (string) => {
  return parseFloat(string.replace("$", "").replace(",", ""))
};

export const timeSince = (date) => {
  var seconds = Math.floor(Date.now() / 1000 - date);
  var interval = Math.floor(seconds / 31536000);
  if (interval === 1) {
    return interval + " year ago";
  } else if (interval > 1) {
    return interval + " years ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval === 1) {
    return interval + " month ago";
  } else if (interval > 1) {
    return interval + " months ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval === 1) {
    return interval + " day ago";
  } else if (interval > 1) {
    return interval + " days ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval === 1) {
    return interval + " hour ago";
  } else if (interval > 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval === 1) {
    return interval + " minute ago";
  } else if (interval > 1) {
    return interval + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

export const getDeviceType = () => {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1 ||
    window.matchMedia("(max-width: 750px)").matches
  );
};

export const difference = (origObj, newObj) => {
  const isObject = (x) => {
    return x === Object(x);
  };
  const equal = require("fast-deep-equal/es6/react");
  const changes = (newObj, origObj) => {
    let result = {};
    for (const [key, value] of Object.entries(newObj)) {
      if (!equal(value, origObj[key])) {
        result[key] =
          isObject(value) && isObject(origObj[key])
            ? Array.isArray(value)
              ? value
              : changes(value, origObj[key])
            : value;
      }
    }
    return result;
  };
  return changes(newObj, origObj);
};

export const checkRequiredInputs = (state) => {
  let el = null;
  if (!state.name) {
    el = document.getElementById("name");
    Object.assign(el.style, {
      border: "1px solid #e64c54",
      boxShadow: "0 0 0 1px #e64c54",
    });
  } else {
    el = document.getElementById("name");
    Object.assign(el.style, { border: "1px solid #e1e1e1", boxShadow: "none" });
  }
  if (!state.description) {
    el = document.getElementById("description");
    Object.assign(el.style, {
      border: "1px solid #e64c54",
      boxShadow: "0 0 0 1px #e64c54",
    });
  } else {
    el = document.getElementById("description");
    Object.assign(el.style, { border: "1px solid #e1e1e1", boxShadow: "none" });
  }
  if (!state.price) {
    el = document.getElementById("price");
    Object.assign(el.style, {
      border: "1px solid #e64c54",
      boxShadow: "0 0 0 1px #e64c54",
    });
  } else {
    el = document.getElementById("price");
    Object.assign(el.style, { border: "1px solid #e1e1e1", boxShadow: "none" });
  }
  if (JSON.stringify(state.categories) === "[]") {
    el = document.getElementById("categories-container").childNodes[1];
    Object.assign(el.style, {
      border: "1px solid #e64c54",
      boxShadow: "0 0 0 1px #e64c54",
    });
  } else {
    el = document.getElementById("categories-container").childNodes[1];
    Object.assign(el.style, { border: "1px solid #e1e1e1", boxShadow: "none" });
  }
  if (
    !state.image1.src &&
    !state.image2.src &&
    !state.image3.src &&
    !state.image4.src &&
    !state.image5.src &&
    !state.image6.src
  ) {
    el = document.getElementsByClassName("file-upload-wrap")[0];
    Object.assign(el.style, {
      border: "1px solid #e64c54",
      boxShadow: "0 0 0 1px #e64c54",
    });
  } else {
    el = document.getElementsByClassName("file-upload-wrap")[0];
    Object.assign(el.style, { border: "1px solid #e1e1e1", boxShadow: "none" });
  }
  if (
    !state.name ||
    !state.description ||
    !state.price ||
    JSON.stringify(state.categories) === "[]" ||
    (!state.image1.src &&
      !state.image2.src &&
      !state.image3.src &&
      !state.image4.src &&
      !state.image5.src &&
      !state.image6.src)
  ) {
    return false;
  } else {
    return true;
  }
};
