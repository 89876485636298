import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ClearIcon } from "../svg/clear.svg";

export default function ModalCart(props) {
  const removeFromCart = (product) => {
    props.cart.removeFromCart(product);
  };
  const cart = props.cart.cart;
  const cartItems = [...cart.products].map((data) => {
    return (
      <li key={data.id} className="cart-item">
        <div>
          <Link to={{pathname: `/product/${data.id}`, product: data}}>
            <img
              src={
                data.images[0].small ? data.images[0].small : data.images[0].src
              }
            />
            {data.name}
          </Link>
        </div>
        <div>
          <button
            className="remove-item"
            onClick={() => removeFromCart(data)}
            className="remove-cart-item"
          >
            <ClearIcon width="23" height="23" className="clear-icon" />
          </button>
        </div>
      </li>
    );
  });
  return (
    <div id="cart-modal">
      <div className="cart-title">
        <h2 className="title">Cart</h2>
      </div>
      {cart.count > 0 ? (
        <div>
          <ul className="cart-content">{cartItems}</ul>
          <div className="cart-subtotal">
            <span>{`Subtotal: $${cart.subtotal.toFixed(2)}`}</span>
          </div>
          <div className="cart-checkout">
            <Link to="/checkout">Checkout</Link>
          </div>
        </div>
      ) : (
        <p className="empty-cart">Your cart is empty.</p>
      )}
    </div>
  );
}
