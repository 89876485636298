import React from "react";
import ProductCard from "./productcard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

export default function Products(props) {
  var products = null;
  if (!props.loading) {
    if (!props.empty) {
      const mappedProducts = props.results.map((data, index) => {
        return (
          <ProductCard
            order={props.order}
            offer={props.offer}
            refresh={props.refresh}
            key={index}
            data={data}
            loading={false}
          />
        );
      });
      products = <ul className="products">{mappedProducts}</ul>;
    } else {
      products = (
        <div className="no-results">
          <FontAwesomeIcon icon={faTimesCircle} />
          <span>No products.</span>
        </div>
      );
    }
  } else {
    const loading = props.productPage
      ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    const mappedProducts = loading.map((number) => {
      return <ProductCard key={number} loading={true} />;
    });
    products = <ul className="products">{mappedProducts}</ul>;
  }
  return <div>{products}</div>;
}
