import React from "react";
import ProfileHeader from "../../components/profileheader";
import ProfileLinks from "../../components/profilelinks";
import ProfileLikes from "./profile-likes";
import ProfileProducts from "./profile-products";
import ProfileOffers from "./profile-offers";
import ProfileSettings from "./profile-settings";
import { Switch, Route, Redirect } from "react-router";
import ProfileOrders from "./profile-orders";

export default function Profile(props) {
  const user = props.login.user;
  return (
    <div id="profile">
      <ProfileHeader
        name={user.firstName ? user.firstName : user.username}
        personal={true}
        user={user}
      />
      <ProfileLinks login={props.login} horizontal={1} />
      <Switch>
        <Route
          path="/profile/likes"
          render={() => <ProfileLikes results={props.login.user.likes} />}
        />
        <Route
          path="/profile/products"
          render={() => <ProfileProducts results={props.login.user.products} />}
        />
        <Route
          path="/profile/offers"
          render={() => <ProfileOffers login={props.login} />}
        />
        <Route
          path="/profile/orders"
          component={() => (
            <ProfileOrders
              yourOrders={props.login.user.yourOrders}
              customerOrders={props.login.user.customerOrders}
            />
          )}
        />
        <Route
          path="/profile/settings"
          render={() => (
            <ProfileSettings
              alert={props.alert}
              updateUser={props.login.updateUser}
              user={props.login.user}
            />
          )}
        />
        <Redirect from="/profile" to="/profile/likes" />
      </Switch>
    </div>
  );
}
