import React, { Component } from "react";

import CreatableSelect from "react-select/creatable";
import { customStyles } from "../data";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label: "#" + label,
  value: label,
});

export default class CreatableInputOnly extends Component {
  constructor() {
    super();
    this.state = {
      inputValue: "",
      value: [],
    };
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.tags !== prevProps.tags)
      this.setState({ value: this.props.tags });
  };
  handleChange = (value) => {
    this.setState({ value: value || [] });
    this.props.setTags(value || []);
  };
  handleInputChange = (inputValue) => {
    if (this.state.value.length < 3) this.setState({ inputValue });
  };
  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (value.length < 3) {
          this.setState(
            {
              inputValue: "",
              value: [...value, createOption(inputValue)],
            },
            () => {
              this.props.setTags(this.state.value);
            }
          );
        }
        event.preventDefault();
    }
  };
  render() {
    const { inputValue, value } = this.state;
    return (
      <CreatableSelect
        styles={customStyles}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder="Type and press enter..."
        value={value}
      />
    );
  }
}
