import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { hideModals } from "./togglemodals";

export default function ScrollToTop(props) {
  const { pathname } = useLocation();
  const { action } = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (action !== "POP")
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);
    const details = document.getElementsByClassName("bottom-summary")[0];
    if (details) details.scrollTop = 0;
    if (props.confirm) props.alert.hide();
    hideModals();
  }, [pathname, action]);
  return null;
}
