import React, { useEffect, useState } from "react";
import Provider from "./Provider";
import Home from "./views/home";
import Product from "./views/product";
import Checkout from "./views/checkout";
import User from "./views/user";
import AddProduct from "./views/addproduct";
import EditProduct from "./views/editproduct";
import Profile from "./views/profile/profile";
import Order from "./views/order";
import { Switch, Route, Redirect } from "react-router";
import Header from "./components/header";
import Modals from "./components/modals";
import Footer from "./components/footer";
import { getDeviceType } from "./functions";
import "./App.css";
import Returns from "./views/returns";
import Terms from "./views/terms";
import Privacy from "./views/privacy";
import ScrollToTop from "./components/scrolltotop";

export default function App() {
  const [isMobile, setIsMobile] = useState(getDeviceType());

  useEffect(() => {
    window.addEventListener("resize", setDeviceType);
  }, []);

  const [alertMessage, setAlert] = useState("");

  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    if (alertMessage && !confirm) setTimeout(() => setAlert(""), 3500);
  }, [alertMessage]);

  const alert = {
    success: (message) => {
      setConfirm(false);
      setAlert(
        <div>
          <span
            style={{
              display: "block",
              width: "100%",
              textAlign: "center",
              color: "#2b87df",
              margin: "15px 0 0",
            }}
          >
            Success!
          </span>
          <span>{message}</span>
        </div>
      );
    },
    error: (message) => {
      setConfirm(false);
      setAlert(
        <div>
          <span
            style={{
              display: "block",
              width: "100%",
              textAlign: "center",
              color: "#e64c54",
              margin: "15px 0 0",
            }}
          >
            Error!
          </span>
          <span>{message}</span>
        </div>
      );
    },
    confirm: async () => {
      return new Promise((complete) => {
        setConfirm(true);
        setAlert(
          <div>
            <span
              style={{
                display: "block",
                width: "100%",
                textAlign: "center",
                margin: "15px 0 0",
              }}
            >
              Are your sure?
            </span>
            <button
              onClick={() => {
                setAlert("");
                complete(true);
              }}
            >
              Yes
            </button>
            <button
              style={{ color: "#e64c54" }}
              onClick={() => {
                setAlert("");
                complete(false);
              }}
            >
              No
            </button>
          </div>
        );
      });
    },
    hide: () => {
      setAlert("");
    },
  };

  const setDeviceType = () => {
    setIsMobile(getDeviceType());
  };

  return (
    <Provider>
      {({ cache, params, cart, login }) => (
        <div className={isMobile ? "is-mobile" : "is-desktop"}>
          <Header
            cart={cart}
            login={login}
            params={params}
            isMobile={isMobile}
          />
          <div
            style={alertMessage ? { top: "70px", visibility: "visible" } : null}
            id="alert"
          >
            {alertMessage}
          </div>
          <ScrollToTop alert={alert} confirm={confirm} />
          <Modals cart={cart} login={login} params={params} />
          <div id="primary" style={{ minHeight: "100vh" }}>
            <div id="overlay" className="overlay" />
            <div id="main">
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <Home
                      {...props}
                      updateUser={login.updateUser}
                      cache={cache}
                      params={params}
                    />
                  )}
                />
                <Route
                  path="/category/:category"
                  render={(props) => (
                    <Home {...props} cache={cache} params={params} />
                  )}
                />
                <Route
                  path="/search/:search"
                  render={(props) => (
                    <Home {...props} cache={cache} params={params} />
                  )}
                />
                <Route path="/user/:user" component={User} />
                <Route
                  path="/product/:product"
                  render={(props) => (
                    <Product
                      {...props}
                      alert={alert}
                      cart={cart}
                      login={login}
                    />
                  )}
                />
                <Route
                  path="/checkout"
                  render={(props) =>
                    cart.cart.products.length > 0 ? (
                      <Checkout
                        {...props}
                        alert={alert}
                        cart={cart}
                        login={login}
                      />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/make-offer"
                  render={(props) =>
                    props.location.offer ? (
                      <Checkout {...props} alert={alert} login={login} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/add-product/"
                  render={(props) =>
                    login.user.loggedIn ? (
                      <AddProduct {...props} alert={alert} login={login} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/edit-product/:id"
                  render={(props) =>
                    login.user.loggedIn ? (
                      <EditProduct {...props} alert={alert} login={login} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/profile"
                  render={(props) =>
                    login.user.loggedIn ? (
                      <Profile {...props} alert={alert} login={login} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/order/:order"
                  render={(props) =>
                    login.user.loggedIn ? (
                      <Order {...props} alert={alert} login={login} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/returns/:order"
                  render={(props) =>
                    login.user.loggedIn ? (
                      <Returns {...props} alert={alert} login={login} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/activate"
                  render={(props) => (
                    <Home
                      {...props}
                      alert={alert}
                      updateUser={login.updateUser}
                      cache={cache}
                      params={params}
                    />
                  )}
                />
                <Route
                  path="/help"
                  render={(props) => (
                    <Home
                      {...props}
                      updateUser={login.updateUser}
                      cache={cache}
                      params={params}
                    />
                  )}
                />
                <Route path="/terms" component={Terms} />
                <Route path="/privacy" component={Privacy} />
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </Provider>
  );
}
