import React from "react";
import { hideModals, showModal } from "./components/togglemodals";
import { getFloatVal } from "./functions";

export default class Provider extends React.Component {
  state = {
    cache: [],
    loginOpen: false,
    user: JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : {
          id: 0,
          loggedIn: false,
          likes: [],
        },
    cart: JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          count: 0,
          products: [],
          subtotal: 0,
        },
    params: JSON.parse(localStorage.getItem("params"))
      ? JSON.parse(localStorage.getItem("params"))
      : {
          brand: null,
          tag: null,
          condition: null,
          size: null,
          min_price: null,
          max_price: null,
          orderby: null,
          start: null,
        },
  };

  componentDidMount() {
    const cart = this.state.cart;
    if (cart.count > 0) {
      const products = [...cart.products];
      const ids = products.map((item) => {
        return item.id;
      });
      fetch(
        process.env.REACT_APP_API_URL +
          "/wp-content/plugins/products-api/query.php?include=" +
          ids.join(",")
      )
        .then((response) => response.json())
        .then((results) => {
          let subtotal = 0;
          results = results.filter((item) => {
            return item.stock > 0;
          });
          results.forEach((item) => {
            subtotal += getFloatVal(item.price);
          });
          let paypalFee = subtotal * 0.02987 + 0.566;
          let total = subtotal + paypalFee;
          results.sort((a, b) => {
            return ids.indexOf(a.id) - ids.indexOf(b.id);
          });
          this.setCart({
            ...cart,
            products: results,
            count: results.length,
            subtotal,
            paypalFee,
            total,
          });
        });
      const user = this.state.user;
      if (user.date) {
        if (Date.now() / 1000 - parseInt(user.date) > 172800) this.logout();
      }
    }
    const user = this.state.user;
    if (user.loggedIn) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      };
      fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/users/data", {
        method: "POST",
        headers,
      })
        .then((response) => response.json())
        .then((result) => {
          this.updateUser(result);
        });
    }
  }

  setCache = (results) => this.setState({ cache: results });

  addToCache = (results) =>
    this.setState({ cache: this.state.cache.concat(results) });

  changeParams = (newParams) => {
    const params = Object.assign(this.state.params, newParams);
    this.setState({ params });
    localStorage.setItem("params", JSON.stringify(params));
  };

  addToCart = (product) => {
    let existingCart = this.state.cart;
    existingCart.products.push(product);
    existingCart.count += 1;
    existingCart.subtotal += getFloatVal(product.price);
    existingCart.paypalFee = existingCart.subtotal * 0.02987 + 0.566;
    existingCart.total = existingCart.subtotal + existingCart.paypalFee;
    localStorage.setItem("cart", JSON.stringify(existingCart));
    this.setState({ cart: existingCart });
  };

  removeFromCart = (product) => {
    let existingCart = this.state.cart;
    existingCart.count -= 1;
    existingCart.subtotal -= getFloatVal(product.price);
    existingCart.paypalFee = existingCart.subtotal * 0.02987 + 0.566;
    existingCart.total = existingCart.subtotal + existingCart.paypalFee;
    let index = existingCart.products.indexOf(product);
    existingCart.products.splice(index, 1);
    localStorage.setItem("cart", JSON.stringify(existingCart));
    this.setState({ cart: existingCart });
  };

  setCart = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
    this.setState({ cart });
  };

  setUser = (data) => {
    let user = Object.assign({}, data);
    user.likes = [];
    user.products = [];
    if (!user.yourOffers) user.yourOffers = [];
    if (!user.customerOffers) user.customerOffers = [];
    if (!user.yourOrders) user.yourOrders = [];
    if (!user.customerOrders) user.customerOrders = [];
    localStorage.setItem("user", JSON.stringify(user));
    this.setState({ user }, () => {
      showModal(document.getElementById("profile-modal"));
      if (data.likes.length > 0) {
        fetch(
          process.env.REACT_APP_API_URL +
            "/wp-content/plugins/products-api/query.php?include=" +
            data.likes.join(",")
        )
          .then((response) => response.json())
          .then((results) => {
            results.sort((a, b) => {
              return (
                data.likes.indexOf("" + a.id) - data.likes.indexOf("" + b.id)
              );
            });
            localStorage.setItem(
              "user",
              JSON.stringify({ ...this.state.user, likes: results })
            );
            this.setState({ user: { ...this.state.user, likes: results } });
          });
      }
      fetch(
        process.env.REACT_APP_API_URL +
          "/wp-content/plugins/products-api/query.php?personal=" +
          user.username
      )
        .then((response) => response.json())
        .then((results) => {
          if (results.length > 0) {
            localStorage.setItem(
              "user",
              JSON.stringify({ ...this.state.user, products: results })
            );
            this.setState({ user: { ...this.state.user, products: results } });
          }
        });
      const headers = { Authorization: `Bearer ${data.token}` };
      fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/orders/token", {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            localStorage.setItem(
              "user",
              JSON.stringify({ ...this.state.user, clientToken: result })
            );
            this.setState({
              user: { ...this.state.user, clientToken: result },
            });
          }
        });
    });
  };

  updateUser = (data) => {
    const user = Object.assign({}, this.state.user, data);
    if (!user.yourOffers) user.yourOffers = [];
    if (!user.customerOffers) user.customerOffers = [];
    if (!user.yourOrders) user.yourOrders = [];
    if (!user.customerOrders) user.customerOrders = [];
    this.setState({ user });
    localStorage.setItem("user", JSON.stringify(user));
  };

  logout = () => {
    const user = { id: 0, loggedIn: false, likes: [] };
    this.setState({ user });
    localStorage.setItem("user", JSON.stringify(user));
    hideModals();
  };

  render() {
    return this.props.children({
      cache: {
        cache: this.state.cache,
        setCache: this.setCache,
        addToCache: this.addToCache,
      },
      params: {
        params: this.state.params,
        changeParams: this.changeParams,
      },
      cart: {
        cart: this.state.cart,
        addToCart: this.addToCart,
        removeFromCart: this.removeFromCart,
        setCart: this.setCart,
      },
      login: {
        user: this.state.user,
        setUser: this.setUser,
        updateUser: this.updateUser,
        logout: this.logout,
      },
    });
  }
}
