import React from "react";
import { ReactComponent as PaypalIcon } from "../svg/paypal.svg";

export default function CheckoutCart(props) {
  const cart = props.offer ? props.offer : props.cart.cart;
  const { paypalFee, total } = cart;
  const products = props.offer ? [cart.product] : [...cart.products];
  const cartItems = products.map((data) => {
    return (
      <li key={data.id} className="cart-item">
        <div>
          <img
            src={
              data.images[0].small ? data.images[0].small : data.images[0].src
            }
          />
          <span>{data.name}</span>
        </div>
        <span>{data.price}</span>
      </li>
    );
  });
  cartItems.push(
    <li key="paypal" className="cart-item">
      <div className="cart-paypal">
        <div>
          <PaypalIcon />
        </div>
        Paypal Fee
      </div>
      <span>{`$${paypalFee.toFixed(2)}`}</span>
    </li>
  );
  cartItems.push(
    <li key="total" className="cart-item">
      <div className="cart-total">
        <div>
          <span style={{ fontSize: "22px", fontWeight: "600" }}>$</span>
        </div>
        Total
      </div>
      <span style={{ color: "#005aff", fontSize: "19px" }}>{`$${total.toFixed(
        2
      )}`}</span>
    </li>
  );

  return (
    <div id="checkout-cart">
      <div className="cart-title" />
      <ul className="cart-content">{cartItems}</ul>
      <div style={{ padding: "15px 20px" }} className="cart-bottom" />
    </div>
  );
}
