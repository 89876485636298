import React from "react"

export default function CurrencyInput(props) {
  return(
    <input
      type="text" 
      inputMode="decimal"
      placeholder="$0.00"
      {...props}
    />
  )
}