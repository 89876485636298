import React from "react";
import Products from "../components/products";
import HomeLinks from "../components/homelinks";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from "query-string";

export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      params: JSON.parse(localStorage.getItem("params"))
        ? JSON.parse(localStorage.getItem("params"))
        : {
            brand: null,
            tag: null,
            condition: null,
            size: null,
            min_price: null,
            max_price: null,
            orderby: null,
            start: null,
          },
      loaded: false,
      empty: false,
      results: [],
      loading: false,
      hasMore: false,
    };
    this.loadMore = this.loadMore.bind(this);
    this.getResults = this.getResults.bind(this);
    this.setParams = this.setParams.bind(this);
  }

  componentDidMount() {
    if (this.props.location.pathname === "/activate/") {
      const alert = this.props.alert;
      const query = queryString.parse(this.props.location.search);
      const key = query.key;
      const user = query.user;
      if (user && key) {
        const data = { user_id: user, key };
        fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/users/activate", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result === "Activated") {
              alert.success("Account activated.");
              this.props.updateUser({ activated: true });
            }
          });
      }
    }
    this.setParams(true, false);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      this.setParams(false, false);
    } else if (
      JSON.stringify(this.props.params.params) !==
      JSON.stringify(this.state.params)
    ) {
      this.setParams(false, true);
    }
  }

  setParams(checkCache, setParams) {
    if (this.state.hasMore) this.setState({ hasMore: false });
    if (setParams)
      this.setState({ params: Object.assign({}, this.props.params.params) });
    const params = Object.assign(
      {},
      this.props.params.params,
      this.props.match.params
    );
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.tag || queryParams.brand || queryParams.orderby)
      Object.assign(params, queryParams);
    if (
      checkCache &&
      this.props.cache.cache.length > 0 &&
      this.props.history.action === "POP"
    ) {
      this.setState({ results: this.props.cache.cache, loaded: true });
      if (!parseInt(this.props.cache.cache.length % 20))
        this.setState({ hasMore: true });
    } else {
      this.getResults(params, false);
    }
  }

  loadMore() {
    const params = Object.assign(
      {},
      this.props.params.params,
      this.props.match.params
    );
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.tag || queryParams.brand || queryParams.orderby)
      Object.assign(params, queryParams);
    const results = this.state.results;
    if (params.orderby || params.search) {
      params.offset = results.length;
    } else {
      params.start = results[results.length - 1].id;
    }
    let url =
      "?" +
      Object.keys(params)
        .map((k) => (params[k] ? k + "=" + encodeURIComponent(params[k]) : ""))
        .filter(Boolean)
        .join("&");
    fetch(
      process.env.REACT_APP_API_URL +
        "/wp-content/plugins/products-api/query.php" +
        url
    )
      .then((response) => response.json())
      .then((results) => {
        this.props.cache.addToCache(results);
        this.setState({
          results: [...this.state.results].concat(results),
          empty: results.length === 0,
          hasMore: results.length === 20,
        });
      });
  }

  getResults(params) {
    this.setState({ loading: true, loaded: false });
    let url =
      "?" +
      Object.keys(params)
        .map((k) => (params[k] ? k + "=" + encodeURIComponent(params[k]) : ""))
        .filter(Boolean)
        .join("&");
    fetch(
      process.env.REACT_APP_API_URL +
        "/wp-content/plugins/products-api/query.php" +
        url
    )
      .then((response) => response.json())
      .then((results) => {
        this.props.cache.setCache(results);
        this.setState({
          results: results,
          loading: false,
          empty: results.length === 0,
          hasMore: results.length === 20,
        });
      })
  }

  render() {
    return (
      <div id="home">
        <HomeLinks />
        <InfiniteScroll
          loader={<Products loading={true} />}
          scrollThreshold="50%"
          dataLength={this.state.results.length}
          next={this.loadMore}
          hasMore={this.state.hasMore}
        >
          <Products
            loading={this.state.loading}
            loaded={this.state.loaded}
            empty={this.state.empty}
            results={this.state.results}
          />
        </InfiniteScroll>
      </div>
    );
  }
}
