import React from "react";
import axios from "axios";
import { states } from "../../data";
import { formatPhone, difference } from "../../functions";
import { ReactComponent as RightIcon } from "../../svg/chevron.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook } from "@fortawesome/free-regular-svg-icons";
import { faCamera, faInfo } from "@fortawesome/free-solid-svg-icons";
import Addresses from "../../components/addresses";

class ProfileSettings extends React.Component {
  constructor(props) {
    super();
    this.state = {
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      oldPassword: "",
      newPassword: "",
      paypal: props.user.paypal,
      venmo: formatPhone(props.user.venmo),
      paymentMethod: props.user.paymentMethod,
      phone: props.user.phone,
      description: props.user.description,
      profile: Object.assign(props.user.profile, {
        delete: false,
        class: "vertical",
        src: props.user.profile.regular,
      }),
      cover: Object.assign(props.user.cover, { delete: false }),
      billing: props.user.billing,
    };
    this.setImage = this.setImage.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.selectPaypal = this.selectPaypal.bind(this);
    this.selectVenmo = this.selectVenmo.bind(this);
    this.formatPhone = this.formatPhone.bind(this);
    this.setBilling = this.setBilling.bind(this);
    this.setBillingState = this.setBillingState.bind(this);
  }

  componentDidMount() {
    this.setState({ oldSettings: JSON.stringify(this.state) });
    if (this.props.user.paymentMethod === "venmo") this.selectVenmo();
    if (window.location.search === "?addresses=true") this.showAddresses();
    if (window.location.search === "?info=true") this.showInfo();
  }

  setImage(event) {
    if (!this.props.user.activated) {
      this.props.alert.error(
        "Please activate your account to upload pictures."
      );
      return;
    }
    var home = this;
    var thisImage = event.target.id;
    var obj = this.state[thisImage];
    const token = JSON.parse(localStorage.getItem("user")).token;
    const headers = { Authorization: `Bearer ${token}` };
    if (event.target.value) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        obj.id = "";
        obj.src = image.src;
        obj.delete = false;
        image.onload = function () {
          this.width > this.height
            ? (obj.class = "horizontal")
            : (obj.class = "vertical");
          home.setState({ [thisImage]: obj });
        };
      };
      reader.readAsDataURL(event.target.files[0]);
      var data = new FormData();
      data.append("action", "file_upload");
      data.append("file", event.target.files[0]);
      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/wp-json/wp/v2/media",
        data: data,
        headers: headers,
      })
        .then(function (response) {
          obj.id = response.data.id;
          obj.src = response.data.source_url;
          home.setState({ [thisImage]: obj });
          if (obj.delete) {
            axios({
              method: "delete",
              url:
                process.env.REACT_APP_API_URL +
                "/wp-json/wp/v2/media/" +
                response.data.id +
                "/?force=1",
              headers: headers,
            }).then(function () {
              obj.src = "";
              obj.class = "";
              obj.id = "";
              obj.delete = false;
              home.setState({ [thisImage]: obj });
            });
          }
        })
        .catch(() => {
          obj.src = "";
          obj.class = "";
          obj.id = "";
          obj.delete = false;
          home.setState({ [thisImage]: obj });
          this.props.alert.error("There was an error uploading this image.");
        });
    } else {
      if (obj.id) {
        obj.src = "";
        obj.class = "";
        this.setState({ [thisImage]: obj });
        axios({
          method: "delete",
          url:
            process.env.REACT_APP_API_URL +
            "/wp-json/wp/v2/media/" +
            obj.id +
            "/?force=1",
          headers: headers,
        })
          .then(function () {
            obj.id = "";
            obj.delete = false;
            home.setState({ [thisImage]: obj });
          })
          .catch(() => {
            obj.src = "";
            obj.class = "";
            obj.id = "";
            obj.delete = false;
            home.setState({ [thisImage]: obj });
          });
      } else {
        obj.src = "";
        obj.class = "";
        obj.id = "";
        obj.delete = true;
        this.setState({ [thisImage]: obj });
      }
    }
  }

  formatPhone({ target: { value } }) {
    this.setState((prevState) => ({
      phone: formatPhone(value, prevState.phone),
    }));
  }

  selectPaypal() {
    document.getElementById("select-two").classList.remove("right");
    document.getElementById("venmo-account").style.display = "none";
    document.getElementById("paypal-account").style.display = "block";
    this.setState({ paymentMethod: "paypal" });
  }

  selectVenmo() {
    document.getElementById("select-two").classList.add("right");
    document.getElementById("venmo-account").style.display = "block";
    document.getElementById("paypal-account").style.display = "none";
    this.setState({ paymentMethod: "venmo" });
  }

  setBilling(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "state") {
      let stateValue = states.find((item) => {
        if (item.label === value || item.value === value) return item;
      });
      stateValue ? (stateValue = stateValue.value) : (stateValue = "");
      this.setState((state) => ({
        billing: { ...state.billing, state: stateValue },
      }));
    } else {
      this.setState((state) => ({
        billing: { ...state.billing, [name]: value },
      }));
    }
  }

  setBillingState(target) {
    target
      ? this.setState((state) => ({
          billing: { ...state.billing, state: target.value },
        }))
      : this.setState((state) => ({
          billing: { ...state.billing, state: "" },
        }));
  }

  showPictures = () => {
    document.getElementById("pictures").style.display = "block";
    document.getElementById("info").style.display = "none";
    document.getElementById("addresses").style.display = "none";
  };

  showInfo = () => {
    document.getElementById("info").style.display = "block";
    document.getElementById("pictures").style.display = "none";
    document.getElementById("addresses").style.display = "none";
  };

  showAddresses = () => {
    document.getElementById("addresses").style.display = "block";
    document.getElementById("pictures").style.display = "none";
    document.getElementById("info").style.display = "none";
  };

  resendActivationEmail = () => {
    const username = this.props.user.username;
    const alert = this.props.alert;
    const body = JSON.stringify({ username });
    const headers = { "Content-Type": "application/json" };
    fetch(
      process.env.REACT_APP_API_URL + "/wp-json/atlas/users/activate/email",
      { method: "post", body, headers }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result === "Email Sent") {
          alert.success("Email sent.");
        } else {
          alert.error("Error sending email.");
        }
      });
  };

  updateUser(e) {
    if (e) e.preventDefault();
    const alert = this.props.alert;
    const user = this.props.user;
    const {
      profile,
      cover,
      firstName,
      lastName,
      oldPassword,
      newPassword,
      description,
      paypal,
      venmo,
      paymentMethod,
      billing,
    } = this.state;
    if ((profile.src && !profile.id) || (cover.src && !cover.id)) {
      setTimeout(this.updateUser, 1000);
      return;
    }
    if (newPassword) {
      const retypeNewPassword = document.getElementById(
        "retype-new-password"
      ).value;
      if (!oldPassword) {
        alert.error("Enter your old password.");
        document.getElementById("old-password").classList.add("error");
        return;
      }
      if (newPassword !== retypeNewPassword) {
        document.getElementById("new-password").classList.add("error");
        document.getElementById("retype-new-password").classList.add("error");
        alert.error("New passwords don't match.");
        return;
      }
    }
    if (paymentMethod === "paypal" && !paypal) {
      alert.error("Enter a PayPal account email.");
      return;
    }
    if (paymentMethod === "venmo" && !venmo) {
      alert.error("Enter a Venmo phone number.");
      return;
    }
    if (venmo.length > 0 && venmo.length < 14) {
      document.getElementById("venmo-account").classList.add("error");
      alert.error("Invalid phone number");
      return;
    }
    const oldSettings = JSON.parse(this.state.oldSettings);
    const newSettings = {
      profile,
      cover,
      firstName,
      lastName,
      oldPassword,
      newPassword,
      description,
      paypal,
      venmo,
      paymentMethod,
      billing,
    };
    const changes = difference(oldSettings, newSettings);
    if (changes.profile)
      if (Object.keys(changes.profile) === 0) delete changes.profile;
    if (changes.billing)
      changes.billing = Object.assign(oldSettings.billing, changes.billing);
    let body = JSON.stringify(changes);
    const token = user.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    if (body !== "{}") {
      fetch(process.env.REACT_APP_API_URL + "/wp-json/atlas/users/settings", {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((result) => {
          body = JSON.parse(body);
          if (result === "Updated") {
            alert.success("Settings updated.");
            if (body.cover && !body.cover.src) {
              body.cover.src = "";
            }
            this.props.updateUser(body);
          } else if (typeof result === "object") {
            if (result.hasOwnProperty("id")) {
              if (body.profile && result.id) {
                body.profile.id = result.id;
                body.profile.small = result.small;
                body.profile.regular = result.regular;
              } else {
                body.profile.id = "";
                body.profile.small = "";
                body.profile.regular = "";
              }
              if (body.cover && !body.cover.src) {
                body.cover.src = "";
              }
              alert.success("Settings updated.");
              this.props.updateUser(body);
            } else {
              alert.error("There was an error uploading your photo.");
            }
          } else {
            alert.error(result);
          }
        });
    }
  }

  render() {
    const billing = Object.assign({}, this.state.billing, {
      setBilling: this.setBilling,
      setBillingState: this.setBillingState,
    });
    return (
      <form
        className="settings-form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="settings-links">
          <div className="profile-links">
            <button onClick={this.showPictures} className="icon-links">
              <div className="icon-left">
                <FontAwesomeIcon
                  style={{ width: "20px", height: "20px" }}
                  icon={faCamera}
                />
              </div>
              Pictures
              <RightIcon className="icon-right" />
            </button>
            <button onClick={this.showInfo} className="icon-links">
              <div className="icon-left">
                <FontAwesomeIcon
                  style={{ width: "20px", height: "20px" }}
                  icon={faInfo}
                />
              </div>
              Info
              <RightIcon className="icon-right" />
            </button>
            <button onClick={this.showAddresses} className="icon-links">
              <div className="icon-left">
                <FontAwesomeIcon
                  style={{ width: "20px", height: "20px" }}
                  icon={faAddressBook}
                />
              </div>
              Billing
              <RightIcon className="icon-right" />
            </button>
          </div>
        </div>
        <div id="pictures">
          <div id="profile-pic">
            <label>Profile:</label>
            <li className="file-upload-wrap">
              {this.state.profile.src ? (
                <>
                  <img
                    className={this.state.profile.class}
                    src={this.state.profile.src}
                    alt="profile"
                  />
                  <div id="profile" onClick={this.setImage} />
                </>
              ) : (
                <>
                  <label htmlFor="profile">+</label>
                  <input
                    onChange={this.setImage}
                    type="file"
                    name="profile"
                    id="profile"
                    accept=".png, .jpg, .jpeg"
                  />
                </>
              )}
            </li>
          </div>
          <div id="cover-photo" style={{ marginBottom: "35px" }}>
            <label>Cover:</label>
            <li className="file-upload-wrap">
              {this.state.cover.src ? (
                <>
                  <img
                    className={this.state.cover.class}
                    src={this.state.cover.src}
                    alt="cover"
                  />
                  <div id="cover" onClick={this.setImage} />
                </>
              ) : (
                <>
                  <label htmlFor="cover">+</label>
                  <input
                    onChange={this.setImage}
                    type="file"
                    name="cover"
                    id="cover"
                    accept=".png, .jpg, .jpeg"
                  />
                </>
              )}
            </li>
          </div>
          <button
            className="submit-button button"
            type="submit"
            onClick={this.updateUser}
          >
            Save Settings
          </button>
        </div>
        <div id="info">
          {!this.props.user.activated ? (
            <div>
              <label>Activate:</label>
              <button
                onClick={this.resendActivationEmail}
                className="submit-button button"
                style={{ margin: "15px 0 20px", width: "300px" }}
              >
                Resend activation email
              </button>
            </div>
          ) : null}
          <div className="left-container">
            <label>First Name:</label>
            <input
              type="text"
              style={{ marginBottom: "15px" }}
              id="firstName"
              value={this.state.firstName}
              onChange={(e) => {
                this.setState({
                  firstName: e.target.value.replace(/[^a-zA-Z0-9\s]/, ""),
                });
              }}
            />
          </div>
          <div className="right-container">
            <label>Last Name:</label>
            <input
              type="text"
              style={{ marginBottom: "15px" }}
              id="lastName"
              value={this.state.lastName}
              onChange={(e) => {
                this.setState({
                  lastName: e.target.value.replace(/[^a-zA-Z0-9\s]/, ""),
                });
              }}
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              style={{ marginBottom: "15px" }}
              name="description"
              id="description"
              rows="5"
              cols="5"
              value={this.state.description}
              onChange={(e) => {
                this.setState({ description: e.target.value });
              }}
            />
          </div>
          <label>Payment:</label>
          <div id="select-two">
            <div onClick={this.selectPaypal}>Paypal</div>
            <div onClick={this.selectVenmo}>Venmo</div>
          </div>
          <input
            style={{ marginBottom: "15px" }}
            type="text"
            id="paypal-account"
            placeholder="PayPal Email"
            defaultValue={this.state.paypal}
            onChange={(e) => {
              this.setState({ paypal: e.target.value });
            }}
          />
          <input
            style={{ marginBottom: "15px" }}
            type="text"
            id="venmo-account"
            placeholder="Venmo Phone Number"
            value={this.state.venmo}
            onChange={(e) => {
              this.setState({ venmo: formatPhone(e.target.value) });
            }}
          />
          <div className="password-container">
            <label>Password:</label>
            <input
              autoComplete="off"
              type="password"
              placeholder="Old Password"
              value={this.state.oldPassword}
              onChange={(e) => {
                this.setState({ oldPassword: e.target.value });
              }}
            />
            <input
              type="text"
              placeholder="New Password"
              value={this.state.newPassword}
              onChange={(e) => {
                this.setState({ newPassword: e.target.value });
              }}
            />
            <input
              type="text"
              id="retype-new-password"
              placeholder="Re-type New Password"
            />
          </div>
          <button
            className="submit-button button"
            type="submit"
            onClick={this.updateUser}
          >
            Save Settings
          </button>
        </div>
        <div id="addresses">
          <Addresses billing={billing} />
          <button
            className="submit-button button"
            type="submit"
            onClick={this.updateUser}
          >
            Save Settings
          </button>
        </div>
      </form>
    );
  }
}

export default ProfileSettings;
