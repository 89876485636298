import React from "react";
import SearchForm from "./searchform";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../svg/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretSquareDown } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import HeaderRight from "./headerright";
import { hideModals } from "./togglemodals";

export default function Header(props) {
  const toggleSearch = () => {
    document.getElementsByClassName("header-middle")[0].style.display = "flex";
    document.getElementById("primary").addEventListener("click", hideModals);
  };

  const toggleHeaderRight = () => {
    const icon = document.getElementById("header-right-toggle");
    icon.style.transform !== "rotate(180deg)"
      ? (icon.style.transform = "rotate(180deg)")
      : (icon.style.transform = "rotate(0deg)");
    const headerRight = document.getElementsByClassName("header-right")[0];
    headerRight.style.top !== "70px"
      ? (headerRight.style.top = "70px")
      : (headerRight.style.top = "0px");
    if (headerRight.style.top === "70px")
      document.getElementById("primary").addEventListener("click", hideModals);
  };

  const params = props.params.params;
  let filtersCount = Object.keys(params)
    .map((k) => (params[k] && !k.includes("price") ? k : ""))
    .filter(Boolean).length;
  if (params["min_price"] || params["max_price"]) filtersCount++;

  return (
    <header id="header">
      <div className="header-wrapper">
        <div className="header-left">
          {props.isMobile ? (
            <div onClick={toggleHeaderRight} className="header-links">
              <FontAwesomeIcon
                id="header-right-toggle"
                style={{ width: "23px", height: "23px" }}
                icon={faCaretSquareDown}
              />
            </div>
          ) : null}
          <Link className="logo" to="/">
            <Logo width="180" height="70" />
          </Link>
          {props.isMobile ? (
            <div onClick={toggleSearch} className="header-links">
              <FontAwesomeIcon
                style={{ width: "20px", height: "20px" }}
                icon={faSearch}
              />
            </div>
          ) : null}
        </div>
        <div
          className="header-middle"
          style={{ display: !props.isMobile ? "flex" : "none" }}
        >
          <SearchForm />
        </div>
        <HeaderRight
          isMobile={props.isMobile}
          login={props.login}
          filtersCount={filtersCount}
          cartCount={props.cart.cart.count}
        />
      </div>
    </header>
  );
}
