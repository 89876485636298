import React from "react";
import Select from "react-select";
import { withRouter } from "react-router";
import { customStyles } from "../data";
import { categories } from "../data/CategoriesData";

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.changePage = this.changePage.bind(this);
    this.state = {
      categories: [],
      children1: [],
      children2: [],
      disabled1: true,
      disabled2: true,
      value1: null,
      value2: null,
      value3: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.cat1 !== prevProps.cat1)
      if (this.props.cat1) this.handleChange1(this.props.cat1);
    if (this.props.cat2 !== prevProps.cat2)
      if (this.props.cat2) this.handleChange2(this.props.cat2);
    if (this.props.cat3 !== prevProps.cat3)
      if (this.props.cat3) this.handleChange3(this.props.cat3);
  }

  handleChange1(target) {
    if (this.props.addProduct) this.props.setCategories(target, 0);
    let categoriesArray = [...this.state.categories];
    if (target) {
      categoriesArray[0] = target.value;
      this.setState({ categories: categoriesArray });
      const children = categories[target.value].children;
      let children1 = [];
      if (children) {
        children.forEach((el) => {
          children1.push(categories[el].select);
        });
      } else {
        children1 = null;
      }
      if (children1) {
        this.setState({
          children1,
          value1: target,
          disabled1: false,
          disabled2: true,
          value2: null,
          value3: null,
        });
      } else {
        this.setState({
          value1: target,
          disabled1: true,
          disabled2: true,
          value2: null,
          value3: null,
        });
      }
    } else {
      this.setState({ categories: [] });
      this.setState({
        value1: null,
        disabled1: true,
        disabled2: true,
        value2: null,
        value3: null,
      });
    }
  }

  handleChange2(target) {
    if (this.props.addProduct) this.props.setCategories(target, 1);
    let categoriesArray = [...this.state.categories];
    if (target) {
      categoriesArray[1] = target.value;
      this.setState({ categories: categoriesArray });
      const children = categories[target.value].children;
      let children2 = [];
      if (children) {
        children.forEach((el) => {
          children2.push(categories[el].select);
        });
      } else {
        children2 = null;
      }
      if (children2) {
        this.setState({
          children2,
          value2: target,
          disabled2: false,
          value3: null,
        });
      } else {
        this.setState({ value2: target, disabled2: true, value3: null });
      }
    } else {
      categoriesArray = [categoriesArray[0]];
      this.setState({ categories: categoriesArray });
      this.setState({ value2: null, value3: null, disabled2: true });
    }
  }

  handleChange3(target) {
    if (this.props.addProduct) this.props.setCategories(target, 2);
    this.setState({ value3: target, disabled2: false });
    let categoriesArray = [...this.state.categories];
    if (target) {
      categoriesArray[2] = target.value;
      this.setState({ categories: categoriesArray });
    } else {
      categoriesArray = [categoriesArray[0], categoriesArray[1]];
      this.setState({ categories: categoriesArray });
    }
  }

  changePage() {
    const category = this.state.categories[this.state.categories.length - 1];
    if (category) this.props.history.push(`/category/${category}`);
  }

  render() {
    return (
      <div>
        <div className="container">
          <label htmlFor="category1" className="filter-titles">
            Category 1:
          </label>
          <Select
            name="category1"
            value={this.state.value1}
            styles={customStyles}
            onChange={this.handleChange1}
            options={[
              categories[1].select,
              categories[2].select,
              categories[7].select,
              categories[1611].select,
              categories[6].select,
              categories[3].select,
              categories[4].select,
              categories[5].select,
              categories[8].select,
              categories[9].select,
              categories[10].select,
            ]}
            isSearchable={true}
            isClearable={true}
          />
        </div>
        <div className="container">
          <label htmlFor="category2" className="filter-titles">
            Category 2:
          </label>
          <Select
            name="category2"
            value={this.state.value2}
            styles={customStyles}
            onChange={this.handleChange2}
            options={this.state.children1}
            isDisabled={this.state.disabled1}
            isSearchable={true}
            isClearable={true}
          />
        </div>
        <div className="container">
          <label htmlFor="category3" className="filter-titles">
            Category 3:
          </label>
          <Select
            name="cataegory3"
            value={this.state.value3}
            styles={customStyles}
            onChange={this.handleChange3}
            options={this.state.children2}
            isDisabled={this.state.disabled2}
            isSearchable={true}
            isClearable={true}
          />
        </div>
        {!this.props.addProduct ? (
          <button className="submit-button button" onClick={this.changePage}>
            Go
          </button>
        ) : null}
      </div>
    );
  }
}

export default withRouter(Categories);
