import React from "react";
import Select from "react-select";
import { states, customStyles } from "../data";

export default function Addresses(props) {
  const { billing } = props;
  return (
    <>
      <label>Billing:</label>
      <div id="billing">
        <label>Street Address:</label>
        <input
          type="text"
          name="address_1"
          value={billing.address_1}
          onChange={billing.setBilling}
        />
        <label>Apartment, suite, unit etc. (optional):</label>
        <input
          type="text"
          name="address_2"
          value={billing.address_2}
          onChange={billing.setBilling}
        />
        <label>City:</label>
        <input
          type="text"
          name="city"
          value={billing.city}
          onChange={billing.setBilling}
        />
        <label>State:</label>
        <input
          type="text"
          name="state"
          value={billing.state}
          onChange={billing.setBilling}
          style={{
            width: "0",
            height: "0",
            position: "absolute",
            padding: "0",
          }}
        />
        <Select
          className="state"
          value={states.filter((obj) => {
            return obj.value === billing.state;
          })}
          styles={customStyles}
          options={states}
          isClearable={true}
          isSearchable={true}
          onChange={billing.setBillingState}
        />
        <label>Zip Code:</label>
        <input
          type="text"
          name="postcode"
          value={billing.postcode}
          onChange={billing.setBilling}
        />
      </div>
    </>
  );
}
