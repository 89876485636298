import React from "react";
import { ReactComponent as UserIcon } from "../svg/user.svg";
import Rating from "../components/rating";

export default function ProfileHeader(props) {
  return (
    <div
      className={props.personal ? "profile-header personal" : "profile-header"}
    >
      <div className="profile-background" />
      <div className="cover-pic-container">
        {props.user.cover.src ? (
          <img src={props.user.cover.src} alt={`user-${props.user.id}-cover`} />
        ) : (
          <div style={{ background: "black" }} />
        )}
      </div>
      <div className="profile-pic-container">
        {props.user.profile.regular ? (
          <img
            className="vertical"
            src={props.user.profile.regular}
            alt={`user-${props.user.id}-profile`}
          />
        ) : (
          <UserIcon />
        )}
      </div>
      <div className="profile-info">
        <h2>
          {props.personal
            ? "Welcome, " + props.user.username
            : props.user.username}
        </h2>
        <div className="rating">
          <Rating rating={props.user.rating} ratingQty={props.user.ratingQty} />
        </div>
        {props.user.description ? <p>{props.user.description}</p> : null}
      </div>
    </div>
  );
}
