import React, { useEffect, useState } from "react";
import Products from "../../components/products";

export default function ProfileOffers(props) {
  const [yourOffers, setYourOffers] = useState([]);
  const [customerOffers, setCustomerOffers] = useState([]);

  useEffect(() => {
    const user = Object.assign({}, props.login.user);
    if (user.yourOffers.length > 0) {
      const yourOfferIds = user.yourOffers.map((e) => {
        return e.id;
      });
      fetch(
        process.env.REACT_APP_API_URL +
          "/wp-content/plugins/products-api/query.php?include=" +
          yourOfferIds.join(",")
      )
        .then((response) => response.json())
        .then((results) => {
          results.sort((a, b) => {
            return (
              yourOfferIds.indexOf("" + a.id) - yourOfferIds.indexOf("" + b.id)
            );
          });
          setYourOffers(
            results.map((e) => {
              const offer = user.yourOffers.find((i) => {
                return e.id === i.id;
              });
              return { ...e, offerPrice: offer.price };
            })
          );
        });
    }
    if (user.customerOffers.length > 0) {
      const customerOfferIds = user.customerOffers.map((e) => {
        return e.id;
      });
      fetch(
        process.env.REACT_APP_API_URL +
          "/wp-content/plugins/products-api/query.php?include=" +
          customerOfferIds.join(",")
      )
        .then((response) => response.json())
        .then((results) => {
          results.sort((a, b) => {
            return (
              customerOfferIds.indexOf("" + a.id) -
              customerOfferIds.indexOf("" + b.id)
            );
          });
          setCustomerOffers(
            results.map((e) => {
              const offer = user.customerOffers.find((i) => {
                return e.id === i.id;
              });
              return { ...e, offerPrice: offer.price };
            })
          );
        });
    }
  }, []);

  const selectYourOffers = () => {
    document.getElementById("select-two").classList.remove("right");
    document.getElementById("customer-offers-container").style.display = "none";
    document.getElementById("your-offers-container").style.display = "block";
  };

  const selectCustomerOffers = () => {
    document.getElementById("select-two").classList.add("right");
    document.getElementById("customer-offers-container").style.display =
      "block";
    document.getElementById("your-offers-container").style.display = "none";
  };

  return (
    <>
      <div style={{ maxWidth: "370px" }} id="select-two">
        <div onClick={selectYourOffers}>Your Offers</div>
        <div onClick={selectCustomerOffers}>Customer Offers</div>
      </div>
      <div id="your-offers-container">
        <Products
          profile={1}
          offer={1}
          emptyMessage={"No offers."}
          empty={yourOffers.length === 0}
          results={yourOffers}
        />
      </div>
      <div style={{ display: "none" }} id="customer-offers-container">
        <Products
          profile={1}
          offer={1}
          addProduct={1}
          emptyMessage={"No offers."}
          empty={customerOffers.length === 0}
          results={customerOffers}
        />
      </div>
    </>
  );
}
