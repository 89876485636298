import React from "react";
import Products from "../../components/products";

export default function ProfileLikes(props) {
  const selectYourOrders = () => {
    document.getElementById("select-two").classList.remove("right");
    document.getElementById("customer-orders-container").style.display = "none";
    document.getElementById("your-orders-container").style.display = "block";
  };

  const selectCustomerOrders = () => {
    document.getElementById("select-two").classList.add("right");
    document.getElementById("customer-orders-container").style.display =
      "block";
    document.getElementById("your-orders-container").style.display = "none";
  };

  return (
    <>
      <div style={{ maxWidth: "370px" }} id="select-two">
        <div onClick={selectYourOrders}>Your Orders</div>
        <div onClick={selectCustomerOrders}>Customer Orders</div>
      </div>
      <div id="your-orders-container">
        <Products
          profile={1}
          order={1}
          emptyMessage={"No orders."}
          empty={props.yourOrders.length === 0}
          results={props.yourOrders}
        />
      </div>
      <div style={{ display: "none" }} id="customer-orders-container">
        <Products
          profile={1}
          order={1}
          addProduct={1}
          emptyMessage={"No orders."}
          empty={props.customerOrders.length === 0}
          results={props.customerOrders}
        />
      </div>
    </>
  );
}
