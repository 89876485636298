import React from "react";
import Categories from "./categories";
import Filters from "./filters";
import ModalCart from "./modalcart";
import Login from "./login";
import Register from "./register";
import ProfileLinks from "./profilelinks";
import Password from "./password";

export default function Modals(props) {
  return (
    <div id="modals" className="modals">
      <div id="categories-modal">
        <div className="categories-title">
          <h2 className="title">Categories</h2>
        </div>
        <div style={{ padding: "12.5px 15px" }}>
          <Categories />
        </div>
      </div>
      <Filters params={props.params} />
      <ModalCart cart={props.cart} />
      {!props.login.user.loggedIn ? (
        <>
          <Login login={props.login} />
          <Password />
          <Register login={props.login} />
        </>
      ) : (
        <div id="profile-modal">
          <div className="profile-title">
            <h2 className="title">Profile</h2>
          </div>
          <ProfileLinks login={props.login} />
        </div>
      )}
    </div>
  );
}
