import React from "react";
import { Link } from "react-router-dom";

export default function ProductCard(props) {
  if (!props.loading) {
    props.data.refresh = props.refresh;
    const pathname = props.order
      ? "/order/" + props.data.orderId
      : "/product/" + props.data.id;
    const price =
      props.data.offerPrice && props.offer
        ? props.data.offerPrice
        : props.data.stock > 0
        ? props.data.price !== "$0.00"
          ? props.data.price
          : "FREE"
        : "SOLD";
    return (
      <li className="product">
        <Link to={{ product: props.data, pathname }}>
          <img
            className="loading-image"
            src={
              props.data.images[0].regular
                ? props.data.images[0].regular
                : props.data.images[0].src
            }
            alt={props.data.name}
            width="229"
          />
          <div style={{ padding: "10px" }}>
            <span className="title">{props.data.name}</span>
            <span className="price">{price}</span>
          </div>
        </Link>
      </li>
    );
  } else {
    return (
      <li className="loading product">
        <div className="loading-image" />
        <div style={{ padding: "10px", background: "white" }}>
          <div className="loading-name" />
          <div className="loading-price" />
        </div>
      </li>
    );
  }
}
