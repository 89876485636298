import React from "react";
import { Link, Redirect } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { timeSince } from "../functions";
import "react-image-gallery/styles/css/image-gallery.css";
import Rating from "../components/rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import RateUser from "../components/rateuser";

export default class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: { shippingTo: [] },
      related: [],
      images: [],
      purchased: "",
      user: {
        description: "",
        profile: {},
        cover: {},
        rating: 5,
        ratingQty: 1,
      },
      redirect: false,
    };
    this.checkCache = this.checkCache.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
  }

  componentDidMount() {
    this.checkCache();
    if (window.location.search === "?cancel=true") this.cancelOrder();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.login.user.yourOrders !== prevProps.login.user.yourOrders ||
      this.props.login.user.customerOrders !==
        prevProps.login.user.customerOrders
    ) {
      this.checkCache();
    }
  }

  showImagePlaceholders = () => {
    const placeholders = document.getElementById("placeholders");
    const image = document.getElementById("placeholder-image");
    const profile = document.getElementById("placeholder-profile");
    if (image && placeholders) {
      placeholders.style.animation = "none";
      placeholders.focus();
      placeholders.style.animation = null;
      profile.style.animation = "none";
      profile.focus();
      profile.style.animation = null;
    }
  };

  hideImagePlaceholders = () => {
    document.getElementById("placeholders").classList.add("loaded");
  };

  checkCache() {
    const orderId = parseInt(this.props.match.params.order);
    let product = this.props.login.user.yourOrders.find((item) => {
      return item.orderId === orderId;
    });
    if (!product)
      product = this.props.login.user.customerOrders.find((item) => {
        return item.orderId === orderId;
      });
    if (product) {
      this.setState(
        {
          order: product,
          images: product.images.map((image) => ({
            original: window.matchMedia("(max-width: 375px)").matches
              ? image.regular
                ? image.regular
                : image.src
              : image.large
              ? image.large
              : image.src,
            thumbnail: image.small,
          })),
          purchased: timeSince(product.purchased),
        },
        () => {
          this.showImagePlaceholders();
        }
      );
      this.getUser(product.user);
    } else {
      this.setState({ redirect: "/" });
    }
  }

  getUser(username) {
    if (username) {
      fetch(
        process.env.REACT_APP_API_URL +
          "/wp-content/plugins/users-api/query.php?username=" +
          username
      )
        .then((response) => response.json())
        .then((result) => {
          this.setState({ user: result });
        });
    }
  }

  rateUser = () => {
    const status = this.state.order.status;
    const isOwner = Boolean(
      this.state.order.user === this.props.login.user.username
    );
    if (!this.state.order.rating) {
      if (isOwner && status !== "Complete") {
        this.props.alert.error("This order can't be rated yet.");
        return;
      }
      if (!isOwner && status !== "Delivered") {
        this.props.alert.error("This order can't be rated yet.");
        return;
      }
      document.getElementById("order-buttons").style.display = "none";
      document.getElementById("rate-user").style.display = "flex";
    } else {
      this.props.alert.error("This order was already rated.");
    }
  };

  submitRating = (rating) => {
    const order = this.state.order;
    const user = this.props.login.user;
    const isOwner = Boolean(order.user === user.username);
    if (!isOwner) {
      const yourOrders = [...user.yourOrders];
      const existingOrder = yourOrders.find((item) => {
        return item.orderId === this.state.order.orderId;
      });
      const index = yourOrders.indexOf(existingOrder);
      existingOrder.status = "Complete";
      existingOrder.statusDetails = "This order is complete.";
      existingOrder.rating = rating;
      yourOrders[index] = existingOrder;
      this.props.login.updateUser({ yourOrders });
      this.setState({
        user: {
          ...this.state.user,
          rating:
            (parseFloat(user.rating) * user.ratingQty + rating) /
            (user.ratingQty + 1),
          ratingQty: user.ratingQty + 1,
        },
      });
    } else {
      const customerOrders = [...user.customerOrders];
      const existingOrder = customerOrders.find((item) => {
        return item.orderId === this.state.order.orderId;
      });
      const index = customerOrders.indexOf(existingOrder);
      existingOrder.rating = rating;
      customerOrders[index] = existingOrder;
      this.props.login.updateUser({ customerOrders });
    }
    this.props.alert.success("Rating submitted.");
  };

  return = () => {
    const status = this.state.order.status;
    if (status === "Delivered") {
      this.setState({ redirect: "/returns/" + this.state.order.orderId });
    } else if (status === "Return requested") {
      this.props.alert.error("You already requested a return.");
    } else if (status === "Complete") {
      this.props.alert.error("This order is complete.");
    } else {
      this.props.alert.error("This order hasn't been delivered yet.");
    }
  };

  cancelOrder() {
    const alert = this.props.alert;
    if (this.state.order.status === "Pre-shipment") {
      alert.confirm().then((response) => {
        if (response) {
          const user = this.props.login.user;
          const isOwner = Boolean(
            this.state.order.user === this.props.login.user.username
          );
          if (this.state.order.orderId) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            if (user) headers.append("Authorization", `Bearer ${user.token}`);
            const url = isOwner ? "seller" : "customer";
            fetch(
              process.env.REACT_APP_API_URL +
                "/wp-json/atlas/orders/cancel/" +
                url,
              {
                method: "POST",
                body: JSON.stringify({ order_id: this.state.order.orderId }),
                headers,
              }
            )
              .then((response) => response.json())
              .then((result) => {
                if (result === "Cancelled") {
                  const customerOrders = [...user.customerOrders];
                  const existingOrder = customerOrders.find((item) => {
                    return item.orderId === this.state.order.orderId;
                  });
                  const index = customerOrders.indexOf(existingOrder);
                  existingOrder.shippingLabel = "";
                  existingOrder.shippingTracking = "";
                  existingOrder.status = "Cancelled";
                  existingOrder.statusDetails =
                    "This order has been cancelled.";
                  customerOrders[index] = existingOrder;
                  this.props.login.updateUser({ customerOrders });
                } else if (result === "Requested") {
                  alert.success("Cancellation request sent.");
                } else {
                  this.props.alert.error(result);
                }
              });
          }
        }
      });
    } else if (this.state.order.status === "Cancelled") {
      alert.error("This order was already cancelled.");
    } else {
      alert.error("This order has already shipped.");
    }
  }

  render() {
    const order = this.state.order;
    const isOwner = Boolean(order.user === this.props.login.user.username);
    var eta = "";
    if (order.hasOwnProperty("eta")) {
      let date = new Date(order.eta);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      eta = month + " " + day + ", " + year;
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    } else {
      return (
        <div className="product-page order">
          <div className="product-information">
            <div id="placeholders">
              <div id="placeholder-thumbnails">
                {[...this.state.images].map((e, i) => {
                  return <div className="product-thumbnail" key={i} />;
                })}
              </div>
              <div id="placeholder-image" className="product-image" />
            </div>
            <ImageGallery
              onImageLoad={this.hideImagePlaceholders}
              items={this.state.images}
              showPlayButton={false}
              showNav={false}
              showFullscreenButton={false}
              thumbnailPosition={
                window.matchMedia("(max-width: 1000px)").matches
                  ? "bottom"
                  : "left"
              }
            />
            <div className="summary">
              <div className="top-summary">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="user-profile-pic">
                    <div id="placeholder-profile" />
                    {this.state.user.profile.small ? (
                      <img
                        className="vertical"
                        src={this.state.user.profile.small}
                        alt={order.user + "-profile"}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{
                          width: "23px",
                          height: "23px",
                          color: "white",
                        }}
                        icon={faUser}
                      />
                    )}
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <Link
                      style={{ fontSize: "17px" }}
                      to={{
                        user: this.state.user,
                        pathname: `/user/${order.user}`,
                      }}
                    >
                      {order.user}
                    </Link>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <Rating
                      rating={this.state.user.rating}
                      ratingQty={this.state.user.ratingQty}
                    />
                  </div>
                </div>
                <div className="title-price">
                  <div>
                    <h1>{order.name}</h1>
                    <span>SOLD</span>
                  </div>
                </div>
                <div className="buttons-container" id="order-buttons">
                  <div>
                    <a
                      href={order.shippingTracking}
                      className="top-left button"
                    >
                      Track
                    </a>
                    <button
                      onClick={this.cancelOrder}
                      className="top-right button"
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    {isOwner ? (
                      <a
                        href={order.shippingLabel}
                        className="bottom-left button"
                      >
                        Label
                      </a>
                    ) : (
                      <button
                        onClick={this.return}
                        className="bottom-left button"
                      >
                        Return
                      </button>
                    )}
                    <button
                      onClick={this.rateUser}
                      className="bottom-right button"
                    >
                      Rate
                    </button>
                  </div>
                </div>
                <RateUser
                  token={this.props.login.user.token}
                  order_id={order.orderId}
                  isOwner={isOwner}
                  rating={order.rating}
                  submitRating={this.submitRating}
                  user={Object.assign({}, this.state.user, {
                    username: order.user,
                  })}
                />
              </div>
              <div className="bottom-summary">
                <h5 className="details-title">Order Details</h5>
                <div className="details-container">
                  <div>
                    <span>Order:</span>
                    <p>{"#" + order.orderId}</p>
                  </div>
                  {order.carrier ? (
                    <div>
                      <span>Carrier:</span>
                      <p>{order.carrier.toUpperCase()}</p>
                    </div>
                  ) : null}
                  <div>
                    <span>Status:</span>
                    <p>{order.status}</p>
                  </div>
                  <div>
                    <span>Details:</span>
                    <p>{order.statusDetails}</p>
                  </div>
                  {eta ? (
                    <div>
                      <span>Eta:</span>
                      <p>{eta}</p>
                    </div>
                  ) : null}
                  <div>
                    <span>Price:</span>
                    <p>{order.price}</p>
                  </div>
                  {!isOwner ? (
                    <>
                      <div>
                        <span>Shipping:</span>
                        <p>{order.rate}</p>
                      </div>
                      <div>
                        <span>Paypal Fee:</span>
                        <p>{order.paypalFee}</p>
                      </div>
                      <div>
                        <span>Total:</span>
                        <p>{order.total}</p>
                      </div>
                    </>
                  ) : null}
                  <div>
                    <span>To:</span>
                    <p>
                      {Object.values(order.shippingTo)
                        .filter(Boolean)
                        .join("\n")}
                    </p>
                  </div>
                  <div>
                    <span>From:</span>
                    <p>{order.shippingFrom}</p>
                  </div>
                  <div>
                    <span>Purchased:</span>
                    <p>{this.state.purchased}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
