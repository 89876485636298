import React from "react";

export default function Privacy() {
  return (
    <div className="terms-privacy">
      <h1>Privacy Policy</h1>
      <div>
        <p>Last updated on 11/01/21</p>
        <h4>INTRODUCTION</h4>
        <p>
          This privacy policy discloses the privacy practices for&nbsp;Atlas.
          This privacy notice applies solely to information collected by this
          website. It will notify you of the following:
        </p>
        <ol>
          <li>
            What personally identifiable information is collected from you
            through the website, how it is used and with whom it may be shared.
          </li>
          <li>
            What choices are available to you regarding the use of your data.
          </li>
          <li>
            The security procedures in place to protect the misuse of your
            information.
          </li>
          <li>
            How you can correct this information should there be any
            inaccuracies.
          </li>
          <li>
            We are the sole owners of the information collected on this site. We
            only have access to/collect information that you voluntarily give us
            via email, forms, or other direct contact from you. We will not sell
            or rent this information to anyone.
          </li>
        </ol>

        <h4>INFORMATION COLLECTION, USE, AND SHARING</h4>
        <p>
          We will use your information to respond to you, regarding the reason
          you contacted us. We will not share your information with any third
          party outside of our organization, other than as necessary to fulfill
          your request, or to settle a dispute with a seller.
        </p>
        <p>
          Unless you ask us not to, we may contact you via email in the future
          to tell you about specials, new products or services, or changes to
          this privacy policy.
        </p>

        <h4>YOUR ACCESS TO, AND CONTROL OVER INFORMATION</h4>
        <p>
          You may opt out of any future contacts from us at any time. You can do
          the following at any time by contacting us via the email address or
          phone number given on our website:
        </p>
        <ol>
          <li>See what data we have about you, if any.</li>
          <li>
            Change/correct any data we have about you, including your profile
            picture (if you choose to register), but excluding your
            Contributions and Submissions (as defined in the site’s Terms and
            Conditions).
          </li>
          <li>Express any concern you have about our use of your data.</li>
        </ol>

        <h4>SECURITY</h4>
        <p>
          We take precautions to protect your information. When you submit
          sensitive information via the website, your information is protected
          both online and offline. You can verify this by looking for a lock
          icon in the address bar and looking for “https” at the beginning of
          the address of the Web page.{" "}
        </p>
        <p>
          Wherever we collect sensitive information (such as credit card data),
          that information is encrypted and processed by PayPal.{" "}
        </p>
        <p>
          While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline. The servers in which
          we store personally identifiable information are kept in a secure
          environment.
        </p>

        <h4>REGISTRATION</h4>
        <p>
          In order to access certain features of this site, such as messaging
          and seller capabilities, a user must first complete the registration
          form. During registration a user is required to give certain
          information, such as name and email address. This information is used
          to contact you about the products/services on our site in which you
          have expressed interest.{" "}
        </p>

        <h4>SELLERS</h4>
        <p>
          If you choose to sell a product, you will be asked to confirm a
          payment method. The payment info that you
          provide will only ever be used to send payment and notifications
          containing or related to payment.
        </p>

        <h4>ORDERS & OFFERS</h4>
        <p>
          We request information from you on our order form. To buy from us, you
          must provide contact information (like name and billing address) and
          financial information (like credit card number, expiration date). Your
          credit card information never touches our servers, it's handled
          entirely by a third party. Your personal information is used for
          billing purposes and to fill your orders. The email that you provide
          on this form will be used to send receipts and other information
          related to your order.
        </p>

        <h4>COOKIES</h4>
        <p>
          Some of our business partners, such as Paypal, may use cookies on our
          site. However, we have no access to or control over these cookies.{" "}
        </p>

        <h4>LINKS</h4>
        <p>
          This website contains links to other sites, such as Facebook. Please
          be aware that we are not responsible for the content or privacy
          practices of such other sites. We encourage our users to be aware when
          they leave our site and to read the privacy statements of any other
          site that collects personally identifiable information.{" "}
        </p>
      </div>
    </div>
  );
}
