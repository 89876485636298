import { getDeviceType } from "../functions";

export const showModal = (e) => {
  const setElement = (e) => {
    const categories = document.getElementById("categories-modal");
    const categoriesIcon = document.getElementById("categories-icon");
    const filters = document.getElementById("filters-modal");
    const filtersIcon = document.getElementById("filters-icon");
    const cart = document.getElementById("cart-modal");
    const cartIcon = document.getElementById("cart-icon");
    const login = document.getElementById("login-modal");
    const loginIcon = document.getElementById("login-icon");
    const profile = document.getElementById("profile-modal");
    const profileIcon = document.getElementById("profile-icon");
    let element = "";
    if (categoriesIcon.contains(e)) element = categories;
    if (filtersIcon.contains(e)) element = filters;
    if (cartIcon.contains(e)) element = cart;
    if (loginIcon && loginIcon.contains(e)) element = login;
    if (profileIcon && profileIcon.contains(e)) element = profile;
    return element;
  };
  const categories = document.getElementById("categories-modal");
  const filters = document.getElementById("filters-modal");
  const cart = document.getElementById("cart-modal");
  const login = document.getElementById("login-modal");
  const password = document.getElementById("password-modal");
  const register = document.getElementById("register-modal");
  const profile = document.getElementById("profile-modal");
  const overlay = document.getElementById("overlay");
  const elements = [
    categories,
    filters,
    cart,
    login,
    password,
    register,
    profile,
  ];
  const element = e.target ? setElement(e.target) : e;
  const isMobile = getDeviceType();
  if (element) {
    elements.forEach((i) => {
      if (i && i !== element && i.style.display === "block") {
        i.style.display = "none";
      }
    });
    element.style.display = "block";
    overlay.style.display = "block";
    if (isMobile) {
      if (
        document.getElementsByClassName("header-right")[0].style.top !== "70px"
      ) {
        document.getElementsByClassName("header-right")[0].style.top = "70px";
        document.getElementById("header-right-toggle").style.transform =
          "rotate(180deg)";
      }
    }
    document.getElementById("primary").addEventListener("click", hideModals);
  }
};

export const hideModals = (e) => {
  const categories = document.getElementById("categories-modal");
  const filters = document.getElementById("filters-modal");
  const cart = document.getElementById("cart-modal");
  const login = document.getElementById("login-modal");
  const password = document.getElementById("password-modal");
  const register = document.getElementById("register-modal");
  const profile = document.getElementById("profile-modal");
  const overlay = document.getElementById("overlay");
  const elements = [
    categories,
    filters,
    cart,
    login,
    password,
    register,
    profile,
    overlay,
  ];
  if (!e || !document.getElementById("modals").contains(e.target)) {
    elements.forEach((i) => {
      if (i) {
        if (i.style.display === "block") {
          i.style.display = "none";
        }
      }
    });
    const isMobile = getDeviceType();
    if (isMobile) {
      if (
        document.getElementsByClassName("header-middle")[0].style.display ===
        "flex"
      ) {
        document.getElementsByClassName("header-middle")[0].style.display =
          "none";
      }
      if (
        document.getElementsByClassName("header-right")[0].style.top === "70px"
      ) {
        document.getElementsByClassName("header-right")[0].style.top = "0px";
        document.getElementById("header-right-toggle").style.transform =
          "rotate(0deg)";
      }
    }
    document.getElementById("primary").removeEventListener("click", hideModals);
  }
};
